import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;
const apiBase = apiUrl + '/reports/projectReport/'

/**
 * getAllProjectsData - retirive all PM allocated projects
 * @returns {Object}  Object of projects
 */
export function getAllProjectsData(params){
    const endPoint = apiBase + 'getAllProjectsData';
    return http.post(endPoint, params);
}

/**
 * searchProjectMarginRecords - retirive all project profitability records
 * @returns {Object}  Object of profitability records
 */
export function searchProjectMarginRecords(params){
    const endPoint = apiBase + 'searchProjectMarginRecords';
    return http.post(endPoint, params);
}

/**
 * getProjectMarginRecordsExcel - retirive all project profitability records
 * @returns {Object}  Object of profitability records
 */
export function getProjectMarginRecordsExcel(params){
    const endPoint = apiBase + 'getProjectMarginRecordsExcel';
    return http.post(endPoint, params);
}

/**
 * getLoggedUserProfile - retrieves the logged user profile
 * @returns {Object}  Object of user profile
 */
export function getLoggedUserProfile(){
    const endPoint = apiBase + 'getLoggedUserProfile';
    return http.get(endPoint);
}
