import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import ProjectCell from '../../ui/layout/topBar/ProjectCell';
import CMSheet from '../../ui/layout/topBar/CMSheetCell';
import { Button } from '@progress/kendo-react-buttons';
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import CMSselectDialog from '../../projectManagement/components/dialog/CMSSelectDialog'
import {getCRsOfAProject} from "../../projectManagement/changeRequest/ChangeRequestService";
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

import { MultiSelect, ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import ReactQuill from "react-quill";
import { getPermissionList } from '../../userManagement/role/RoleService';

import {
  getActivityLogDataSort,
  getProjectStatuses,
  getApprovalsData
} from '../../projectManagement/project/ProjectService';

import {
  getPaymentMethods,
  getProjectTypes
} from '../../masterData/common/CommonService';
import { getRegionsIntegrated, getBillingDiviIntegrated, getCustomerProgramsIntegrated, getcustomersIntegrated, getHolidaysIntegrated } from '../../integration/MasterDataService';
import {
  getAllUniqueIds,
  getRecentActiveProjects,
  getAllOPIDs, getCMSStatus, saveStatusChangeHistory, getActivityLogData, saveConversionStartdate
} from '../../projectManagement/project/ProjectService';

import {getCmsTypes, setWorkflowStatus} from '../../projectManagement/cmSheet/CMSheetService';

import { getAllCRUniqueIds } from '../../projectManagement/changeRequest/ChangeRequestService';

import Loader from '../../integration/components/Loader';
import $ from "jquery";
import {getPMResources, getProjectOwners, getResourceRequestSubLines, getProjectOrCrResourceRequest, deallocateRequestsFromStatusChange,
  getAllocationPercentageHistory,
  updateAllocationPercentageHistory,
  getLeavesIntegrated,
  getLegalEntityTypes
} from "../../resourceManagement/ResourceService";
import { getAllDocumentTypesByStatus } from '../../masterData/documentType/DocumentTypeService';
import moment from 'moment';
import { uploadFile } from '../../integration/fileUpload/FileUploadService';
import DeleteCell from '../../resourceManagement/DeleteCell';
import StatusFileUploadCell from '../../projectManagement/search/StatusFileUploadCell';
import ActivityLogFilesCell from '../../projectManagement/search/ActivityLogFilesCell';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import { saveAuditTrail } from '../auditTrail/AuditTrailService';
import ApproversCell from '../../projectManagement/search/ApproversCell';
import ApprovalStatusCell from '../../projectManagement/search/ApprovalStatusCell';
import {selectWorkflowStatus} from "../../projectManagement/workflow/WorkflowService";
import StatusChangeCommentCell from '../../projectManagement/search/StatusChangeCommentCell';
import AllDataCell from '../../ui/layout/topBar/AllDataCell';
import { getCostDetails, getCostofAllocatedResource } from '../../masterData/costCard/CostCardService';
import {getFormattedDate, isWeekend} from "../../integration/CommonUtils";
import { Input } from '@progress/kendo-react-inputs';

const apiUrl = require('../../config.js').get(process.env.REACT_APP_ENV).apiUrl;
const ProjStatuses = require('../../projectManagement/search/StatusChangeEnums');
const Status = require('../../projectManagement/search/StatusEnums');
const ApprovalStatusChange = require('../../projectManagement/search/ApprovalStatusChangeEnums');

const resourceAll = [];
const CMSStatusAll = [];


class SystemSearch extends Component {
  isMount = false;
  comboBoxPlaceHolder = 'Please Select';
  CROrProjectCurrentStatus = null;

  popupSet = {
      width: 'auto'
  };

  constructor(props) {
    super(props);
    this.state = {
      skip: 0,
      take: 20,
      changeRequests: [],
      showCRSelectDialog: false,
      selectedCRId: null,
      redirectToEditCR: false,
      selectedCMSId: null,

      projectId: null,
      Statuses: [],

      Customers: [],
      CustomersAll: [],
      Programs: [],
      ProgramsAll: [],
      Divisions: [],
      DivisionsAll: [],
      Regions: [],
      RegionsAll: [],
      ProjectTypes: [],
      ProjectTypesAll: [],
      PaymentMethods: [],
      PaymentMethodsAll: [],
      UniqueIds: [],
      UniqueIdsAll: [],
      opids: [],
      opidsAll: [],

      ProjectStatuses: [],
      Customer: null,
      Program: null,
      Division: null,
      Region: null,
      ProjectType: null,
      PaymentMethod: null,
      UniqueId: null,
      opidvalue: null,
      StartDate: null,

      Projects: [],
      changeRequest: [],

      showMessageDialog: false,
      showErrorMessageDialog: false,
      showSuccessImg: false,
      showErrorImg: false,
      dialogMessage: '',
      dialogTitle: '',

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      projectIdToDelete: null,
      projectNameToDelete: null,
      cmSheetIdToDelete: null,

      CRsOfAProject: [],
      showCRSelectDialogOne: false,
      selectedCMSheet: null,
      selectedCMSReference: null,
      projectIdOfSelectedCMS: null,
      cmsAction: null,
      redirectToEditViewCMS: false,
      ChangeRequestAction: '',
      redirectToCreateCMS: false,
      redirectToCreateCMSPool: false,
      redirectToEditCMS: false,
      redirectToEditCMSPool: false,
      redirectToResourceReq: false,
      loading: false,
      redirectToApproveCMS: false,
      selectedProject: '',
      IsPoolProject: false,

      // View CMS state
      projectCMS: [],
      changeRequestCMS: [],
      sort: [{ field: 'updatedAt', dir: 'desc' }],

      resourceItems: resourceAll.slice(),
      resourceValue: null,
      CMSStatus: [],
      CMSStatusAll: [],
      SelectedCMSStatus: [],
      UniqueCRIds: [],
      UniqueCRIdsAll: [],
      cmsTypes: [],
      selectedCmsType: null,
      changeStatusDialog: false,
      isACR: false,
      isAProj : false,

      // Status change validation
      invalidatePermission: true,
      statusChangeCr: null,
      statusValueLst: [],
      selectedChangeToStatus: null,
      projtypeValue: null,
      billingdiviValue: null,
      showStartDateValidationError: false,
      documentType: null,
      allDocumentTypesForStatus: [],
      documentTypesForDropdown: [],
      projectOrCr : null,
      toHomePage : false,
      startdateStatus : null,
      currentStatus: [],

      RBtnCount: true,
      isErrorMessageVisible: false,
      initStatusLst: [],

      files: [],
      selectedFile: "",
      uploadedFileId: "",
      isCannotUpload: true,
      rowCount: 0,
      fileUploadDetails: [],
      deletedFileUploadDetails: [],
      selectedDocTypes: [],
      projMandatoryDocTypes: [],
      CRMandatoryDocTypes: [],
      DocUploadErrorMessageVisible: false,
      fileUploadMessageVisible: false,

      stsChangeComment: '',
      allActivityLogList: [],
      activityLogList: [],

      showCloseSubCategory: false,
      disableFileUpBtnDiv: false,
      disableCommentBoxDiv: false,

      closeGlobalsearch: false,
      statusChangeCR: false,
      statusChangeCRToggleCount: 0,

      closeChangeStatus: false,
      statusChangeObj: {},
      showWorkflowSelectDialog: false,
      matchingWorkflowList: [],
      selectedWorkFlowId: null,
      activityLogId:null,
      isApprovalStatusPending: false,
      reqStatus: null,
      approvalsDetails: [],
      approversName: [],
      resourceReqSubLines: [],
      showStartDateErrorMessage: false,
      statusChangeGridDetails: [],
      selectedStatus: null,
      deallocatedMainLines: [],
      deallocatedSubLines: [],
      showdeallocateMessageDialog: false,
      isPONumberRequired: false,
      poNumber: null,
      showPONumberErrorMessage: false,
      disablePONumber: true,
      billingEntityType: [],
      billingEntityValue: null,
      showBillingEntityErrorMessage: false
    };

    this.toggleChangeStatusDialog = this.toggleChangeStatusDialog.bind(this);
  }


  toggleChangeStatusDialog() {
    this.setState({
      selectedChangeToStatus : null,
      documentType : null,
      changeStatusDialog: !this.state.changeStatusDialog,
      isErrorMessageVisible: false,
      fileUploadDetails: [],
      DocUploadErrorMessageVisible: false,
      fileUploadMessageVisible: false,
      allActivityLogList: [],
      activityLogList: [],
      showCloseSubCategory: false,
      disableFileUpBtnDiv: false,
      disableCommentBoxDiv: false,
      stsChangeComment: '',
      closeGlobalsearch: !this.state.closeGlobalsearch,
      currentStatus: [],
      showStartDateValidationError: false,
      isApprovalStatusPending: false,
      reqStatus: null,
      approvalsDetails: [],
      approversName: [],
      allDocumentTypesForStatus: [],
      documentTypesForDropdown: [],
      showStartDateErrorMessage: false
    });

    this.CROrProjectCurrentStatus = null;

    if(!this.state.statusChangeCR){
      //remove search item name in search input
      if(this.state.changeStatusDialog){
        this.onClickProject();
      }

      //close global search popup on close status popup
      if(this.state.changeStatusDialog === false){
        this.setState({
          closeGlobalsearch: true
        });
        this.onOpenChangeStatus();
      }
    }

    if(this.state.statusChangeCR){
      this.setState({
        statusChangeCRToggleCount: ++this.state.statusChangeCRToggleCount
      });

      if(this.state.statusChangeCRToggleCount === 1){
        this.setState({
          closeGlobalsearch: true
        });
        this.onOpenChangeStatus();
      }

      if(this.state.statusChangeCRToggleCount === 2){
        this.onClickProject();
      }
    }
  }

  handleChangeStatusPopUp = async (dataItem) => {
    this.setState({
      statusChangeCR: false
    });

    // validate the permissions
    this.statusChangePermission(dataItem, 'Project');
    const projectTypeObj = {
      Name: dataItem.Type.Name,
      id: dataItem.Type.id
    };

    const billingDiviObj = {
      Name: dataItem.Division.Name,
      id: dataItem.Division.id
    };

    dataItem.CMSheets = dataItem.CMSheets.filter(obj => obj.Type === "Project")
    this.setState({
      projtypeValue: projectTypeObj,
      billingdiviValue: billingDiviObj,
      endDateAugmentation: dataItem.EndDate ? new Date(dataItem.EndDate) : null,
      endDateBlanketWork: dataItem.EndDate ? new Date(dataItem.EndDate) : null,
      projectActivated: dataItem.StatusConvertedToActive,
      currentStatus: dataItem.ProjectStatus ? this.state.currentStatus.push(dataItem.ProjectStatus) : null,
      startdateStatus: new Date(dataItem.StartDate),
      projectOrCr: dataItem,
      isPONumberRequired: dataItem.Customer && dataItem.Customer.PONumber ? true : false,
      poNumber: dataItem.PONumber ? dataItem.PONumber : "",
      billingEntityValue: dataItem.LegalEntity ? dataItem.LegalEntity : null
    });

    //start date - status change popup
    if(dataItem.StartDate === null){
      this.setState({
        startdateStatus: null
      });
    }

    // Open the change status popup
    this.toggleChangeStatusDialog();
  };

  statusChangePermission = async (project, type) =>{

    this.setState({
      loading: true
    });

    const term = 'CHG_TO_';
    const userPermissions = this.filterPermissions(term, window.USER_PERMISSION);
    let userPermissionsLst = [];
    let permissionLst = [];
    let statusLst = [];
    let modifiedStatusLst = [];
    let currentStatus;
    let projectCMSheets = [];
    let CrCMSheets = [];
    let selectedCR;

    //get project CMS(s)
    for(let el of project.CMSheets){
      if(el.IsCRCMS === true){
        CrCMSheets.push(el);
      }
      else{
        projectCMSheets.push(el);
      }
    }

    // Get all DB permissions
    await getPermissionList().then(response => {
      permissionLst = response.data;
      userPermissions.forEach(function (value) {
        const item = response.data.filter(i => i.PermissionName === value);
        if (item) {
          userPermissionsLst.push(item[0])
        }
      });
    }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
    });


    if(type === 'Project'){
      currentStatus = (project && project.ProjectStatus) ? project.ProjectStatus : null;
      this.state.currentStatus.push(currentStatus);
      this.CROrProjectCurrentStatus = currentStatus.StatusName;
      this.CROrProjectCurrentStatusId = currentStatus.id

      await getProjectOrCrResourceRequest(type, project.id).then(res => {
        if(res.data && res.data.length > 0){
          project.ResourceRequest = res.data;
        }
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }
    else if (type === 'ChangeRequest') {
      const selectedCrId = this.state.selectedCRId;
      const selectedCr = project.ChangeRequests.filter(obj => obj.id === selectedCrId);
      this.CROrProjectCurrentStatus = selectedCr[0].Status.StatusName;
      this.CROrProjectCurrentStatusId = selectedCr[0].Status.id;
      selectedCR = selectedCr;

      selectedCr[0].Type = project.Type;
      selectedCr[0].BillingDivision = project.Division;

      const projectTypeObj = {
        Name: selectedCr[0].Type.Name,
        id: selectedCr[0].Type.id
      };

      const billingDiviObj = {
        Name: selectedCr[0].BillingDivision.Name,
        id: selectedCr[0].BillingDivision.id
      };

      await getProjectOrCrResourceRequest(type, selectedCr[0].id).then(res => {
        if(res.data && res.data.length > 0){
          selectedCr[0].ResourceRequest = res.data;
        }
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

      await this.setState({
        projtypeValue: projectTypeObj,
        billingdiviValue: billingDiviObj,
        endDateAugmentation: selectedCr[0].EndDate ? new Date(selectedCr[0].EndDate) : null,
        endDateBlanketWork: selectedCr[0].EndDate ? new Date(selectedCr[0].EndDate) : null,
        projectActivated: selectedCr[0].StatusConvertedToActive,
        startdateStatus: new Date(selectedCr[0].StartDate),
        projectOrCr: selectedCr[0],
        isPONumberRequired: selectedCr[0].Customer && selectedCr[0].Customer.PONumber ? true : false,
        poNumber: selectedCr[0].PONumber ? selectedCr[0].PONumber : "",
        billingEntityValue: selectedCr[0].LegalEntity ? selectedCr[0].LegalEntity : null
      });

      //start date
      if(selectedCr[0].StartDate === null){
        this.setState({
          startdateStatus: null
        });
      }

      currentStatus = (selectedCr && selectedCr.length > 0) ? selectedCr[0].Status : null;
      this.state.currentStatus.push(currentStatus);
    }

    // filter all permissions related to status change
    permissionLst = this.filterPermissions(term, permissionLst.map(a => a.PermissionName));

    if(currentStatus.Code === 'DEAL_ON_HOLD') {
      const cmsApprovedValidationLst = [Status['Waiting_Confirmation'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermissionsProjectStatus = [Status['Presales'].code, Status['Waiting_Confirmation'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Without_Bidding'].code, Status['Opportunity_Lost'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  projectCMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'PRESALES') {
      const cmsApprovedValidationLst = [Status['Waiting_Confirmation'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermissionsProjectStatus = [Status['Waiting_Confirmation'].code, Status['Deal_On_Hold'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Without_Bidding'].code, Status['Opportunity_Lost'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  projectCMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'WAIT_CONFIRM') {
      const cmsApprovedValidationLst = [Status['Deal_On_Hold'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermissionsProjectStatus = [Status['Deal_On_Hold'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  projectCMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'WORK_AT_RISK') {
      const cmsApprovedValidationLst = [Status['Active'].code, Status['Project_On_Hold'].code];
      const validPermissionsProjectStatus = [Status['Active'].code, Status['Project_On_Hold'].code, Status['Discontinue'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  projectCMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'PROJECT_ON_HOLD') {
      const cmsApprovedValidationLst = [Status['Active'].code];
      const validPermissionsProjectStatus = [Status['Active'].code, Status['Discontinue'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  projectCMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'BILLABLE') { // Active
      let cmsApprovedValidationLst = [Status['Project_On_Hold'].code, Status['Complete'].code];
      let validPermissionsProjectStatus = [Status['Project_On_Hold'].code, Status['Complete'].code, Status['Discontinue'].code];
      if (!this.state.projectOrCr.StatusConvertedToActive) {
        cmsApprovedValidationLst = [Status['Project_On_Hold'].code];
        validPermissionsProjectStatus = [Status['Project_On_Hold'].code];  
      }
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  projectCMSheets : selectedCR[0].CMSheets));
    
    } else if (currentStatus.Code === 'COMPLETE') {
      const cmsApprovedValidationLst = [];
      const validPermissionsProjectStatus = [Status['Closed'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));
    } 

    //copy statusLst
    modifiedStatusLst = statusLst.map((_arrayElement) => Object.assign({}, _arrayElement));
    
    for(let el of modifiedStatusLst){
      switch(el.PermissionDescription){
        case 'Change to Presale': {
          el.PermissionDescription = "Presale";
          break;
        }
        case 'Change to Waiting Confirmation': {
          el.PermissionDescription = "Waiting Confirmation";
          break;
        }
        case 'Change to Deal on-hold': {
          el.PermissionDescription = "Deal on-hold";
          break;
        }
        case 'Change to Working at Risk': {
          el.PermissionDescription = "Working at Risk";
          break;
        }
        case 'Change to Active': {
          el.PermissionDescription = "Active";
          break;
        }
        case 'Change to Project on-hold': {
          el.PermissionDescription = "Project on-hold";
          break;
        }
        case 'Change to Close without bidding': {
          el.PermissionDescription = "Close without bidding";
          break;
        }
        case 'Change to Close – Opportunity Lost': {
          el.PermissionDescription = "Close - Opportunity Lost";
          break;
        }
        case 'Change to Close - Discontinue': {
          el.PermissionDescription = "Close - Discontinue";
          break;
        }
        case 'Change to Complete': {
          el.PermissionDescription = "Complete";
          break;
        }
        case 'Change to Close': {
          el.PermissionDescription = "Close";
          break;
        }
        case 'Change to Closed': {
          el.PermissionDescription = "Closed";
          break;
        }
        default : {
        }
      }
    }

    // Set the field Enable/Disable param
    if (statusLst.length > 0) {
      if (this.isMount) {
        await this.setState({
          invalidatePermission: false,
          statusValueLst: modifiedStatusLst
        });
      }
    } else {
      if (this.isMount) {
        await this.setState({
          invalidatePermission: true
        });
      }
    }
    await this.populateActivityLogData(this.state.projectOrCr, project.id);

    this.setState({
      loading: false
    });
  };

  getValidPermissions = (userPermissionsLst, permissionLst, cmsApprovedValidationLst, CMSheets) => {
    let statusLst = [];
    let statusLstEdit = [];
    this.state.initStatusLst = [];

    //get valid status list
    for(let item of userPermissionsLst) {
      let permission = permissionLst.filter(i => i === item.PermissionName);
      if (permission && permission.length == 1) {
        if(cmsApprovedValidationLst.includes(permission[0])) { // check cMS Approved validation
          let cmSheet = CMSheets[CMSheets ? CMSheets.length - 1 : 0];
          if(cmSheet && cmSheet.Status === 'APPROVED'){
            this.state.initStatusLst.push(item);
          }
        } 
        else {
          this.state.initStatusLst.push(item);
        }
      }
    }

    //copy status list
    statusLstEdit = this.state.initStatusLst.map((_arrayElement) => Object.assign({}, _arrayElement));
    
    //change status --> close
    for(let el of statusLstEdit){
      if((el.PermissionName === Status['Without_Bidding'].code) || (el.PermissionName === Status['Opportunity_Lost'].code) || el.PermissionName === Status['Discontinue'].code){
        el.PermissionName = Status['Close'].code;
        el.PermissionDescription = "Close";
        el.IsActive = true;
        el.id = 108;
      }
    }

    //remove duplicates
    function getUniqueListBy(statusLstEdit, key) {
      return [...new Map(statusLstEdit.map(item => [item[key], item])).values()];
    }

    statusLst = getUniqueListBy(statusLstEdit, 'PermissionName');
    return statusLst;
  };

  filterPermissions = (needle, heystack) => {
    let query = needle.toLowerCase();
    return heystack.filter(item => item.toLowerCase().indexOf(query) >= 0);
  };

  handleOnChangeStatus =  async event => {
    const name = event.target.name;
    const value = event.target.value;

    await this.setState({
      selectedStatus: event,
      disablePONumber: true
    });

    const openCrMessage = "CR(s) belonging to this project are still open";
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const resorceAllocationMessage = `There are Resource allocations running beyond the completion date. Do you want to change the end dates of the overrunning allocations to the Project completion date ${todayDate}?`;
    const titleError = "Error";

    let changeToClose = Status['Close'].code;
    let changeToCloseOppLst = Status['Opportunity_Lost'].code;
    let changeToCloseWthtBid = Status['Without_Bidding'].code;
    let changeToCloseDis = Status['Discontinue'].code;
    let changeToComplete = Status['Complete'].code;

    let openCR = false;
    let projResAllocationBeyondCompDate = false;
    let crResAllocationBeyondCompDate = false;
    let count = 0;
    this.state.isErrorMessageVisible = false;
    this.state.RBtnCount = true;

    $("input:radio").prop('checked', false);
    $("input:radio").prop('disabled', true);

    if (this.isMount) {
      await this.setState({
        [name]: value,
        disableCommentBoxDiv: true
      });

      if((value !== null) && (value.PermissionDescription === "Close")){
        this.setState({
          showCloseSubCategory: true
        });
      }

      if((value !== null) && (value.PermissionDescription !== "Close")){
        this.setState({
          showCloseSubCategory: false
        });
      }

      if(this.state.projectOrCr.ProjectName && this.state.projectOrCr.ChangeRequests.length > 0){
        let elements = (this.state.projectOrCr.ChangeRequests);

        for(let el of elements){
          if(el.Status.StatusName === "Active" ||
              el.Status.StatusName === "Presales" ||
              el.Status.StatusName === "Waiting Confirmation" ||
              el.Status.StatusName === "Deal On Hold" ||
              el.Status.StatusName === "Project On Hold" ||
              el.Status.StatusName === "Working At Risk"){
            openCR = true;
            break;
          }
          else{
            openCR = false;
          }
        }
      }

      //project resource allocation
      if(this.state.projectOrCr.ProjectName){
        if(this.state.projectOrCr.ResourceRequest && this.state.projectOrCr.ResourceRequest.length > 0){
          let deallocatedMainLines = [];
          let deallocatedSubLines = [];
          for(let resourceReq of this.state.projectOrCr.ResourceRequest){
            if(resourceReq.Status === 'COMPLETE' && resourceReq.IsActive){
              let endDate = new Date(resourceReq.EndDate);
              let completionDate = new Date();

              if((resourceReq.AllocatedResourceID !== null) && (endDate.getTime() >= completionDate.getTime())){
                projResAllocationBeyondCompDate = true;
                deallocatedMainLines.push(resourceReq);
                // break;
              }
            }

            if(resourceReq.Status === 'EDIT' && resourceReq.IsActive){
              this.setState({
                loading: true
              });

              let resourceReqId = resourceReq.id;
              await getResourceRequestSubLines(resourceReqId).then(response => {
                let subLinesData = response.data;
                this.setState({
                  resourceReqSubLines: subLinesData
                });
              }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });

              for(let el of this.state.resourceReqSubLines){
                if(el.Status == 'COMPLETE' && el.IsActive){
                  let endDate = new Date(el.EndDate);
                  let completionDate = new Date();

                  if((el.ResourceId !== null) && (endDate.getTime() >= completionDate.getTime())){
                    projResAllocationBeyondCompDate = true;
                    deallocatedSubLines.push(el);
                    // break;
                  }
                }
              }

              this.setState({
                loading: false
              });
            }
          }
          this.setState({
            deallocatedMainLines: deallocatedMainLines,
            deallocatedSubLines: deallocatedSubLines
          });
        }
      }

      //CR resource allocation
      if(!this.state.projectOrCr.ProjectName){
        if(this.state.projectOrCr.ResourceRequest && this.state.projectOrCr.ResourceRequest.length > 0){
          let deallocatedMainLines = [];
          let deallocatedSubLines = [];
          for(let resourceReq of this.state.projectOrCr.ResourceRequest){
            if(resourceReq.Status === 'COMPLETE' && resourceReq.IsActive){
              let endDate = new Date(resourceReq.EndDate);
              let completionDate = new Date();

              if((resourceReq.AllocatedResourceID !== null) && (endDate.getTime() >= completionDate.getTime())){
                crResAllocationBeyondCompDate = true;
                deallocatedMainLines.push(resourceReq);
                // break;
              }
            }

            if(resourceReq.Status === 'EDIT' && resourceReq.IsActive){
              this.setState({
                loading: true
              });

              let resourceReqId = resourceReq.id;
              await getResourceRequestSubLines(resourceReqId).then(response => {
                let subLinesData = response.data;
                this.setState({
                  resourceReqSubLines: subLinesData
                });
              }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });

              for(let el of this.state.resourceReqSubLines){
                if(el.Status == 'COMPLETE' && el.IsActive){
                  let endDate = new Date(el.EndDate);
                  let completionDate = new Date();

                  if((el.ResourceId !== null) && (endDate.getTime() >= completionDate.getTime())){
                    crResAllocationBeyondCompDate = true;
                    deallocatedSubLines.push(el);
                    // break;
                  }
                }
              }

              this.setState({
                loading: false
              });
            }
          }
          this.setState({
            deallocatedMainLines: deallocatedMainLines,
            deallocatedSubLines: deallocatedSubLines
          });
        }
      }

      // Start Date - Status Changed to Active/ Working At Risk
      // Project
      if(this.state.projectOrCr.ProjectName){
        if(value !== null && (value.PermissionDescription === 'Active' || value.PermissionDescription === 'Working at Risk')){
          let activeOrWarStartDate = (this.state.projectOrCr.ActiveOrWARStartDate && this.state.projectOrCr.ActiveOrWARStartDate !== null) ? new Date(this.state.projectOrCr.ActiveOrWARStartDate) : null;
          let projOrCrStartDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? new Date(this.state.projectOrCr.StartDate) : null;

          this.setState({
            startdateStatus: (activeOrWarStartDate !== null) ? (activeOrWarStartDate) : (projOrCrStartDate)
          });
        }
        else{
          let startDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? new Date(this.state.projectOrCr.StartDate) : null;

          this.setState({
            startdateStatus: (startDate !== null) ? startDate : null
          });
        }
      }
      // CR
      if(!this.state.projectOrCr.ProjectName){
        if(value !== null && (value.PermissionDescription === 'Active' || value.PermissionDescription === 'Working At Risk')){
          let activeOrWarStartDate = (this.state.projectOrCr.ActiveOrWARStartDate && this.state.projectOrCr.ActiveOrWARStartDate !== null) ? new Date(this.state.projectOrCr.ActiveOrWARStartDate) : null;
          let projOrCrStartDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? new Date(this.state.projectOrCr.StartDate) : null;

          this.setState({
            startdateStatus: (activeOrWarStartDate !== null) ? (activeOrWarStartDate) : (projOrCrStartDate)
          });
        }
        else{
          let startDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? new Date(this.state.projectOrCr.StartDate) : null;

          this.setState({
            startdateStatus: (startDate !== null) ? startDate : null
          });
        }
      }

      if((value !== null) && (value.PermissionName === changeToCloseWthtBid || value.PermissionName === changeToCloseOppLst || value.PermissionName === changeToCloseDis)){
        value.PermissionName = changeToClose;
      }

      if((value !== null) && (value.PermissionName === changeToClose)){
        if(openCR === true){
          this.setState({
            showErrorImg: true
          });
          this.toggleErrorMessageDialog(openCrMessage, titleError);
          this.toggleChangeStatusDialog();
        }

        if(openCR === false){
          for(let el of this.state.initStatusLst){
            if(el.PermissionName === changeToCloseWthtBid){
              $("input:radio[value='wthtbid']").prop('disabled',false);
              count++;
            }
            else if(el.PermissionName === changeToCloseOppLst){
              $("input:radio[value='oppolost']").prop('disabled',false);
              count++;
            }
            else if(el.PermissionName === changeToCloseDis){
              $("input:radio[value='discontinue']").prop('disabled',false);
              count++;
            }
            this.state.RBtnCount = false;
          }

          if(count === 1){
            for(let el of this.state.initStatusLst){
              switch (el.PermissionName) {
                case changeToCloseWthtBid:
                  $("input:radio[value='wthtbid']").prop('checked',true);
                  value.PermissionName = changeToCloseWthtBid;
                  this.state.RBtnCount = true;
                  break;

                case changeToCloseOppLst:
                  $("input:radio[value='oppolost']").prop('checked',true);
                  value.PermissionName = changeToCloseOppLst;
                  this.state.RBtnCount = true;
                  break;

                case changeToCloseDis:
                  if(projResAllocationBeyondCompDate){
                    $("input:radio[value='discontinue']").prop('checked',false);
                    $("input:radio[value='discontinue']").prop('disabled',true);

                    this.setState({
                      showErrorImg: true
                    });
                    this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
                  }
                  if(crResAllocationBeyondCompDate){
                    $("input:radio[value='discontinue']").prop('checked',false);
                    $("input:radio[value='discontinue']").prop('disabled',true);

                    this.setState({
                      showErrorImg: true
                    });
                    this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
                  }
                  else{
                    $("input:radio[value='discontinue']").prop('checked',true);
                    value.PermissionName = changeToCloseDis;
                    this.state.RBtnCount = true;
                  }
                  break;

                default:
                  break;
              }
            }
          }

          else if(count > 1){
            $("input:radio[name='changestatus']").on('change', function(){
              if($("input:radio[value='wthtbid']").is(':checked')){
                value.PermissionName = changeToCloseWthtBid;
              }
              else if($("input:radio[value='oppolost']").is(':checked')){
                value.PermissionName = changeToCloseOppLst;
              }
              else if($("input:radio[value='discontinue']").is(':checked')){
                value.PermissionName = changeToCloseDis;
              }
            });
          }
        }
      }

      if((value !== null) && (value.PermissionName === changeToComplete)){
        let cmsApproved = false;

        for(let cms of this.state.projectOrCr.CMSheets){
          if(cms.Status === "APPROVED"){
            cmsApproved = true;
          }
          else{
            cmsApproved = false;
          }
        }

        if(this.state.projectOrCr.ProjectName && this.state.projectOrCr.ProjectStatus.StatusName === "Active" && cmsApproved === true){
          if(openCR === true){
            this.setState({
              showErrorImg: true
            });
            this.toggleErrorMessageDialog(openCrMessage, titleError);
            this.toggleChangeStatusDialog();
          }
          else if(projResAllocationBeyondCompDate){
            this.setState({
              showErrorImg: true
            });
            this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
          }
        }

        else if(this.state.projectOrCr.Status.StatusName === "Active" && cmsApproved === true){
          if(crResAllocationBeyondCompDate){
            this.setState({
              showErrorImg: true
            });
            this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
          }
        }

        else{
          $("Button[id='statusPopupSaveBtn']").prop('disabled',true);
        }
      }
    }

    if(value !== null){
      //get document types for the selected status

      this.setState({
        loading: true
      });

      let docTypesForProject = [];
      let docTypesForCr = [];
      let statusKey = value.PermissionName;
      let documentTypes = await getAllDocumentTypesByStatus(ProjStatuses[statusKey].value);

      //Project
      if(!(this.state.projectOrCr.ProjectId)){
        docTypesForProject = documentTypes.data.filter(el => el.IsProjectApplicable || el.IsProjectMandatory);
        await this.setState({
          allDocumentTypesForStatus : docTypesForProject
        });
      }
      //CR
      else if(this.state.projectOrCr.ProjectId){
        docTypesForCr = documentTypes.data.filter(el => el.IsCRApplicable || el.IsCRMandatory);
        await this.setState({
          allDocumentTypesForStatus : docTypesForCr
        });
      }

      //document 'isMandatory'
      for(let el of this.state.allDocumentTypesForStatus){
        if(!(this.state.projectOrCr.ProjectId)){
          if(el.IsProjectMandatory){
            el.isMandatory = "true";
          }
        }
        else if(this.state.projectOrCr.ProjectId){
          if(el.IsCRMandatory){
            el.isMandatory = "true";
          }
        }
      }

      if(value.PermissionDescription === 'Active'){
        this.setState({
          isPONumberRequired: this.state.projectOrCr.Customer && this.state.projectOrCr.Customer.PONumber ? true : false,
          poNumber: this.state.projectOrCr.PONumber ? this.state.projectOrCr.PONumber : "",
          billingEntityValue: this.state.projectOrCr.LegalEntity ? this.state.projectOrCr.LegalEntity : null,
          disablePONumber: false
        });
      }

      this.setState({
        documentTypesForDropdown: this.state.allDocumentTypesForStatus,
        loading: false
      });
    }

    if(value === null){
      this.setState({
        showCloseSubCategory: false,
        isErrorMessageVisible: false,
        DocUploadErrorMessageVisible: false,
        fileUploadMessageVisible: false,
        disableFileUpBtnDiv: false,
        disableCommentBoxDiv: false,
        fileUploadDetails: [],
        stsChangeComment: ''
      });
    }
  };

  deallocateResourceRequest = async () => {

    await this.setState({
      loading: true
    });

    let mainLinesToBeUpdated = [];
    let mainLinesToBeDeleted = [];
    let subLinesToBeUpdated = [];
    let subLinesToBeDeleted = [];

    if (this.state.deallocatedMainLines && this.state.deallocatedMainLines.length > 0) {
      for (const request of this.state.deallocatedMainLines) {
        request.ResDeallocationStartDate = moment(new Date()).format('YYYY-MM-DD');

        if(request.ResDeallocationStartDate >= request.StartDate){
          //update data
          await this.deallocateAllocationPercentageHistory(request);
          await this.getCostForDesignation(request);
          mainLinesToBeUpdated.push(request);
        } else {
          let deletedIds = [];
          deletedIds.push(request.id)
          let mainLineItem = {
            deletedIds: deletedIds,
            type: request.Type,
            id: this.state.projectOrCr.id
          };

          mainLinesToBeDeleted.push(mainLineItem);
        }
      }
    }

    if (this.state.deallocatedSubLines && this.state.deallocatedSubLines.length > 0) {
      for (const request of this.state.deallocatedSubLines) {
        request.ResDeallocationStartDate = moment(new Date()).format('YYYY-MM-DD');

        if(request.ResDeallocationStartDate >= request.StartDate){
          //update data
          await this.deallocateAllocationPercentageHistory(request);
          await this.getCostForDesignation(request);
          subLinesToBeUpdated.push(request);
        } else {
          let deletedIds = [];
          deletedIds.push(request.id)
          subLinesToBeDeleted.push(deletedIds);
        }
      }
    }

    let items = {
      projectOrCr: this.state.projectOrCr,
      mainLinesToBeUpdated: mainLinesToBeUpdated,
      mainLinesToBeDeleted: mainLinesToBeDeleted,
      subLinesToBeUpdated: subLinesToBeUpdated,
      subLinesToBeDeleted: subLinesToBeDeleted
    };
    const deallocatedLinesTot = mainLinesToBeUpdated.length + mainLinesToBeDeleted.length + subLinesToBeUpdated.length + subLinesToBeDeleted.length;
 
    await deallocateRequestsFromStatusChange(items)
      .then(async res => {
        if (res.data && res.data.length > 0) {
          this.state.projectOrCr.ResourceRequest = res.data;
          await this.setState({
            showConfirmDialog: false,
            showSuccessImage: true
          });
          await this.toggleDeallocateMessageDialog(`${deallocatedLinesTot} Resources were deallocated.`, 'Success');
          await this.handleOnChangeStatus(this.state.selectedStatus);
        }

      })
      .catch(async error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

      await this.setState({
        loading: false
      });
  }

  deallocateAllocationPercentageHistory = async (dataItem) => {

    let lineAllocationHistoryList = [];
    const resourceRequestId = dataItem && dataItem.ResourceRequestId ? 
                              dataItem.ResourceRequestId : dataItem.id;
    const resourceRequestIdSub = dataItem && dataItem.ResourceRequestId ? 
                              dataItem.id : null;
    const newEndDate = moment(dataItem.ResDeallocationStartDate).format('YYYY-MM-DD');
    const resourceDetails = {
      resourceRequestId: resourceRequestId,
      resourceRequestIdSub: resourceRequestIdSub,
    };
    await getAllocationPercentageHistory(resourceDetails)
    .then(res => {
      lineAllocationHistoryList = res.data;
    }).catch(err => {
      loggerService.writeLog(err, LOG_TYPES.ERROR);
    });

    const validAlocationHistoryList = lineAllocationHistoryList.filter(record => new Date(record.StartDate) >= new Date(newEndDate) || new Date(record.EndDate) >= new Date(newEndDate));

    for (const record of validAlocationHistoryList) {
      if (record.StartDate > newEndDate && record.EndDate > newEndDate) {
        record.IsActive = false;
      } else if (record.StartDate <= newEndDate && record.EndDate >= newEndDate) {
        record.EndDate = moment(newEndDate).format('YYYY-MM-DD')
      }
    }
    for (const item of validAlocationHistoryList) {
      await this.calculateAllocationHistoryLineEffort(item, dataItem);
    }

    await updateAllocationPercentageHistory(validAlocationHistoryList)
    .then(async res => {
      
    }).catch(err => {
      loggerService.writeLog(err, LOG_TYPES.ERROR);
    });

  }

  calculateAllocationHistoryLineEffort = async (historyLine, request) => {

    let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
    let isHoliday = false;
    let isLeave = false;
    let isHalfDay = false;

    if (request && historyLine.StartDate && historyLine.AllocationPercentage > 0) {

      let startDate = new Date(historyLine.StartDate);
      let endDate = new Date(historyLine.EndDate);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      let effort = 0;
      let resourceCountryId = null;
      if (request.OnsiteStatus === "Offshore") {
        resourceCountryId = request.dataValueResourceRegion ? request.dataValueResourceRegion.DefaultCountryId : null;
      } else if (request.OnsiteStatus === "Onsite") {
        resourceCountryId = this.state.projectOrCr.CountryId;
      }
      let holidays = []; 
      holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
        if (res && res.data) {
          return res.data;
        }
        return [];
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      if (!holidays) {
        holidays = [];
      }
      const leaveObj = {
        ResourceId: request.ResourceId, 
        StartDate: moment(historyLine.StartDate).format('YYYY-MM-DD'), 
        EndDate: moment(historyLine.EndDate).format('YYYY-MM-DD')
      }
      let resourceLeaves = await getLeavesIntegrated(leaveObj).then(res => {
        if (res && res.data) {
            return res.data;
        }
        return [];
      })
      .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
      let loop = new Date(startDate);
      while (loop <= endDate) {
        isHoliday = false;
        isLeave = false;
        isHalfDay = false;
        const dateStr = getFormattedDate(loop);
        if (holidays.indexOf(dateStr) > -1) {
          isHoliday = true;
        }

        if (resourceLeaves && resourceLeaves.length > 0) {
          let leaveDate = resourceLeaves.filter(
              leave => leave.LeaveDate === dateStr
          );
          if (leaveDate && leaveDate.length > 0) {
              for (const leave of leaveDate) {
                  if (leave.Duration === '8') {
                      isLeave = true;
                  } else {
                      isHalfDay = true;
                  }
              }
            }
        }

        if (!isHoliday && !isLeave && !isWeekend(loop)) {
          if (!isHalfDay) {
            totalWorkingDays = totalWorkingDays + 1;
          } else {
            totalWorkingDays = totalWorkingDays + 0.5;
          }
        }

        let newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }

      effort = totalWorkingDays * (historyLine.AllocationPercentage / 100);
      historyLine.EffortDays = Number((effort).toFixed(2));
    }
  };

  getCostForDesignation = async dataItem => {

    let billingRegion = 0;
    let projOrCr = null;
    let projectId = null;
    let DirectCostInUSD = 0;
    let CostPerResourceInUSD = 0;
    let totalCostUSD = 0;
    let EffortDays = 0;

    billingRegion = this.state.projectOrCr.BillingRegion;
    projOrCr = this.state.projectOrCr;
    projectId = this.state.projectOrCr.id;

    let newItem = {
      newResProjectOrCRName: this.state.projectOrCr,
      newResRegion:dataItem.dataValueResourceRegion ? dataItem.dataValueResourceRegion : null,
      newOnSiteStatus: dataItem.OnsiteStatus,
      newStartDate: moment(dataItem.StartDate).format('MM/DD/YYYY'),
      newEndDate:  moment(new Date(dataItem.ResDeallocationStartDate)).format('MM/DD/YYYY'),
      newEffortDays: dataItem.EffortDays.toFixed(2),
      newAllocation: dataItem.AllocationPercentage,
      EstimatedEndDate:moment(new Date(dataItem.ResDeallocationStartDate)).format('MM/DD/YYYY'),
      newResDesignation: dataItem.Designation == 57 ? { id: dataItem.Designation } : dataItem.AllocatedDesignationId ?  { id: dataItem.AllocatedDesignationId } : dataItem.Resource ? { id: dataItem.Resource.DesignationId } : null,
      resourceCountry : dataItem.Resource.GeneralCountry,
      projOrCrCountry : projOrCr.CountryId,
      resourceId: null,
      ResourceRequestId: dataItem.ResourceRequestId ? dataItem.ResourceRequestId : dataItem.id,
      ResourceRequestSubLineId: dataItem.ResourceRequestId ? dataItem.id : null,
      CostPerOutSourcedResource: dataItem.Designation == 57 ? (dataItem.CostPerOutSourcedResource && dataItem.CostPerOutSourcedResource > 0) ? dataItem.CostPerOutSourcedResource : dataItem.InitialCostPerResource : null
    };

    let costDate_ = new Date(dataItem.StartDate);

    const costCard = {
      billingRegionId: billingRegion,
      resourceRegionId: dataItem.Resource ? dataItem.Resource.Region : null,
      designationId: dataItem.Designation == 57 ? dataItem.Designation : dataItem.AllocatedDesignationId ? dataItem.AllocatedDesignationId : dataItem.Resource ? dataItem.Resource.DesignationId : null,
      projectId: projectId,
      costDate: moment(new Date()).format('YYYY-MM-DD')
    };

    costDate_ = moment(costDate_).format('YYYY-MM-DD');
    // const costItems = {
    //   costCard : costCard,
    //   newItem : newItem,
    //   costDate: costDate_
    // };
    const costItems = {
      resourceRequest: dataItem,
      mainLine: dataItem.ResourceRequestId ? false : true,
      projectId: projectId,
      ofTypeProject: projOrCr.ProjectId ? false : true,
    };
    await getCostofAllocatedResource(costItems)
        .then(res => {
          totalCostUSD = res.data.TotalCost;
          DirectCostInUSD = res.data.DirectCost;
          CostPerResourceInUSD = res.data.CostPerResource;
          EffortDays = res.data.EffortDays;
        })
        .catch( async err => {
          totalCostUSD = 0;
          DirectCostInUSD = 0;
          CostPerResourceInUSD = 0;
          EffortDays = 0;
          loggerService.writeLog(err, LOG_TYPES.ERROR);

          const message = 'Cost card record not found for the selected designation and resource region';
          const title = 'Error';
          await this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });

          await this.toggleMessageDialog(message, title);
        });

    dataItem.TotalCost = totalCostUSD;
    dataItem.DirectCost = DirectCostInUSD;
    dataItem.CostPerResource = CostPerResourceInUSD;
    dataItem.EffortDays = EffortDays;

    // Revenue
    if (EffortDays && dataItem.RateValue) {
      dataItem.TotalRevenue = EffortDays * dataItem.RateValue;
    } else {
      dataItem.TotalRevenue = 0;
    }

    // Cost to Revenue ratio
    if (totalCostUSD > 0 && dataItem.TotalRevenue > 0) {
      dataItem.CostToRevenueRatio = (totalCostUSD / dataItem.TotalRevenue).toFixed(2);
    } else {
      dataItem.CostToRevenueRatio = 0;
    }
  };

  handleStatusChangeRbtn = async event => {
    const value = event.target.value;
    let permissionName;

    if($("input:radio[value=value]").prop('checked',true)){ 
      this.state.isErrorMessageVisible = false;
      this.state.RBtnCount = true;

      if(value === 'wthtbid'){
        permissionName = Status['Without_Bidding'].code;
      }
      else if(value === 'oppolost'){
        permissionName = Status['Opportunity_Lost'].code;
      }
      else if(value === 'discontinue'){
        permissionName = Status['Discontinue'].code;
      }
    }

    else if($("input:radio[value=value]").prop('checked',false)){
      this.state.RBtnCount = false;
    }

    //get document types for the selected status
    let docTypesForProject = [];
    let docTypesForCr = [];
    let statusKey = permissionName;
    let documentTypes = await getAllDocumentTypesByStatus(ProjStatuses[statusKey].value);

    //Project
    if(!(this.state.projectOrCr.ProjectId)){
      docTypesForProject = documentTypes.data.filter(el => el.IsProjectApplicable || el.IsProjectMandatory);
      await this.setState({
        allDocumentTypesForStatus : docTypesForProject
      });
    }
    //CR
    else if(this.state.projectOrCr.ProjectId){
      docTypesForCr = documentTypes.data.filter(el => el.IsCRApplicable || el.IsCRMandatory);
      await this.setState({
        allDocumentTypesForStatus : docTypesForCr
      });
    }

    //document 'isMandatory'
    for(let el of this.state.allDocumentTypesForStatus){
      if(!(this.state.projectOrCr.ProjectId)){
        if(el.IsProjectMandatory){
          el.isMandatory = "true";
        }
      }
      else if(this.state.projectOrCr.ProjectId){
        if(el.IsCRMandatory){
          el.isMandatory = "true";
        }
      }
    }
    await this.setState({
      documentTypesForDropdown : this.state.allDocumentTypesForStatus
    });
  };


  handleFileUpload = async event => {
    let uploadedFiles = [];

    for(let i=0; i<event.target.files.length; i++){
      uploadedFiles.push(event.target.files[i]);
    }

    for(let i = 0; i < uploadedFiles.length; i++){
      if( uploadedFiles[i].type === "application/pdf" || 
          uploadedFiles[i].type === "application/msword" ||
          uploadedFiles[i].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          uploadedFiles[i].type === "application/vnd.ms-excel" || 
          uploadedFiles[i].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || 
          uploadedFiles[i].type === "application/vnd.ms-powerpoint" || 
          uploadedFiles[i].type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          uploadedFiles[i].type === "image/png" ||
          uploadedFiles[i].type === "image/jpeg" ||
          uploadedFiles[i].type === "application/vnd.ms-outlook" ||
          uploadedFiles[i].type === "application/msoutlook" ||
          uploadedFiles[i].name.includes('.msg') ||
          uploadedFiles[i].name.includes('.htm') ){            
        await this.setState({
          selectedFile: uploadedFiles[i],
          loaded: 0,
          isCannotUpload: false,
          fileUploadMessageVisible: false,
          DocUploadErrorMessageVisible: false,
          loading: true
        });
      }
      else{
        await this.setState({
          fileUploadMessageVisible: true,
          DocUploadErrorMessageVisible: false
        });
      }
      
      if(this.state.isCannotUpload === false){
        // UPLOAD LOGIC
        var form_data = new FormData();
        form_data.append('file', this.state.selectedFile);
        form_data.append('referenceType', 'StatusChange');
        form_data.append('referenceId', this.state.projectOrCr.id);
        form_data.append('docType', this.state.documentType.DocType);

        await uploadFile(form_data).then(res => {
          let fields = {};

          if(this.state.documentType){
            fields.DocType = this.state.documentType.DocType;
            fields.projectId = this.state.projectOrCr.id;
          }
          
          if(res){
            fields.FileUpload = res.data;
          }

          if(this.isMount){
            this.setState(prevState => {
              this.setState({ rowCount: prevState.rowCount + 1 });
              fields.id = this.state.rowCount;
            });
          }

          this.state.fileUploadDetails.push(fields);
          let documentType  = this.state.documentTypesForDropdown.filter(item => item.id !== this.state.documentType.id);      
          
          this.setState({
            //documentType: null,
            selectedFile: [],
            disableFileUpBtnDiv: false,
            loading: false,
            documentTypesForDropdown: documentType
          });
        })
        .catch(error => {
          //error
        });
      }
    }

    if(this.state.fileUploadMessageVisible === false){
      await this.setState({
        documentType: null,
        selectedFile: []
      });
    }
  };

  OnDelete = async (event, id) => {
    event.preventDefault();
    
    let fileUploadDetails = [...this.state.fileUploadDetails];
    let array = [...this.state.fileUploadDetails];
    let deletedFilesCopy = [...this.state.deletedFileUploadDetails];
    let deletedFile = fileUploadDetails.filter(item => item.id === id);
    const deletedDocType = deletedFile[0].FileUpload.DocumentType
    let addDocType  = this.state.allDocumentTypesForStatus.filter(item => item.DocType === deletedDocType);
    let documentType = this.state.documentTypesForDropdown;
    if(addDocType && addDocType.length >0) {
      documentType.push(addDocType[0]);
    }
    
    deletedFilesCopy.push(deletedFile[0]);
    array = array.filter(item => item.id !== id);

    if(this.isMount){
      await this.setState({
        fileUploadDetails: array,
        deletedFileUploadDetails: deletedFilesCopy,
        documentTypesForDropdown: documentType
      });
    }
  };

  filterStatusOnChange = event => {
    this.setState({
      projects: this.filterStatusData(event.filter)
    });
  };

  filterStatusData(filter) {
    const data = this.state.statusValueLst.slice();
    return filterBy(data, filter);
  }

  handleOnChangeDocType =  async event => {
    const name = event.target.name;
    const value = event.target.value;

    if (this.isMount) {
      await this.setState({
        [name]: value,
        DocUploadErrorMessageVisible: false,
        fileUploadMessageVisible: false,
        disableFileUpBtnDiv: true
      });
    }
  };

  filterDocTypeOnChange = event => {
    this.setState({
      projects: this.filterDocTypeData(event.filter)
    });
  };

  filterDocTypeData(filter) {
    const data = this.state.documentTypesForDropdown.slice();
    return filterBy(data, filter);
  }

  handleHighlightMandatoryDocs = (li, itemProps) => {
    if(itemProps.dataItem.isMandatory){
       const itemChildren = <span>{li.props.children} <span className="mandatory"></span></span>;
      return React.cloneElement(li, li.props, itemChildren);
    }
    else{
      const itemChildren = <span>{li.props.children}</span>;
     return React.cloneElement(li, li.props, itemChildren);
    }
  }

  handlePONumberChange = event => {
    let value = event.target.value;
    this.setState({
      poNumber: value,
    });
  }

  handleStartdateChange = event => {
    let startDate = event.target.value;

    this.setState({
      startdateStatus: startDate,
      showStartDateErrorMessage: false
    });
  
    if (startDate) {
      this.setState({
        showStartDateValidationError: false
      });
    }
  };

  onChangeStsChgComment = statusComment => {
    this.setState({
      stsChangeComment: statusComment
    });
  };
  
  handleBillingEntityChange = event => {
    const value = event.target.value;
    this.setState({
      billingEntityValue: value,
      isFormDirty: true
    });
  };

  filterBillingEntities = event => {
    this.setState({
      billingEntityType: this.filterBillingEntityData(event.filter)
    });
  };

  filterBillingEntityData(filter) {
    const data = this.allBillingEntities.slice();
    return filterBy(data, filter);
  }

  setSelectedWorkflowId = async workflowId => {
    await this.setState({
      selectedWorkFlowId: workflowId
    });
  };

  onSaveStatusChange = async() => {

    this.setState({
      loading: true
    });

    let proOrcmsId = null;
    let startDateErrorMessage = false;
    let closeStatusErrorMessage = false;
    let pastStartDateErrorMessage = false;

    if (this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk' || this.state.selectedChangeToStatus.PermissionDescription === 'Waiting Confirmation')) {
      if (this.state.startdateStatus == null) {
        startDateErrorMessage = true;
      }
    }

    if ((this.state.currentStatus[0].StatusName === 'Presales') &&
        (this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk')) &&
        ($("DatePicker[name='startdateStatus']").prop('disabled', false)) && (!this.state.projectActivated || this.state.projectActivated == null)) {
          let startDate = moment(this.state.startdateStatus).format('YYYY-MM-DD');
          let currentDate = moment(new Date()).format('YYYY-MM-DD');

          if(startDate < currentDate){
            pastStartDateErrorMessage = true;
          }
    }


    // Project/CR Start Date - Status Changed to Active/Working At Risk
    let activeOrWarStartDate = null;

    if(this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk')){
      activeOrWarStartDate = (this.state.startdateStatus && this.state.startdateStatus !== null) ? (moment(this.state.startdateStatus).format('YYYY-MM-DD')) : null;
    }
    
    if(!(this.state.RBtnCount)){
      closeStatusErrorMessage = true;
    }

    if((!this.state.projectActivated) &&
      (this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk'))){
      let conversionStartDate = moment(this.state.startdateStatus).format('YYYY-MM-DD');
      let projOrCrId = this.state.projectOrCr.id;
      let isProject = this.state.projectOrCr.ProjectId ? false : true;

      let item = {
        conversionStartDate: conversionStartDate,
        projOrCrId: projOrCrId,
        isProject: isProject,
        PONumber: this.state.poNumber ? this.state.poNumber.trim() : null, // update PO number
        BillingEntityId: this.state.billingEntityValue ? this.state.billingEntityValue.id : null
      };

      await saveConversionStartdate(item);
    }

    let errorMessage = false;
    let trueCount = 0;
    let falseCount = 0;
    let currentUploadedFiles = [];

    //get mandatory doc types for status
    for(let el of this.state.allDocumentTypesForStatus){
      if(el.IsProjectMandatory){
        this.state.projMandatoryDocTypes.push(el.DocType);
      }
      if(el.IsCRMandatory){
        this.state.CRMandatoryDocTypes.push(el.DocType);
      }
    }

    //remove duplicate files
    function getUniqueListBy(fileUploadDetails, key) {
      return [...new Map(fileUploadDetails.map(item => [item[key], item])).values()];
    }
    currentUploadedFiles = getUniqueListBy(this.state.fileUploadDetails, 'DocType');

    //check for mandatory doc types
    if(currentUploadedFiles.length > 0){
      if(!(this.state.projectOrCr.ProjectId)){
        for(let docType of this.state.projMandatoryDocTypes){
          for(let upFile of currentUploadedFiles){
            if(docType === upFile.DocType){
              trueCount = trueCount+1;
            }
            else{
              falseCount = falseCount+1;
            }
          }
        }
      }
      else if(this.state.projectOrCr.ProjectId){
        for(let docType of this.state.CRMandatoryDocTypes){
          for(let upFile of currentUploadedFiles){
            if(docType === upFile.DocType){
              trueCount = trueCount+1;
            }
            else{
              falseCount = falseCount+1;
            }
          }
        }
      }
    }
    //save without mandatory document upload
    else if(currentUploadedFiles.length === 0){
      if((!this.state.projectOrCr.ProjectId) && (this.state.projMandatoryDocTypes.length > 0)){
        errorMessage = true;
      }
      else if((this.state.projectOrCr.ProjectId) && (this.state.CRMandatoryDocTypes.length > 0)){
        errorMessage = true;
      }
    }

    if(currentUploadedFiles.length > 0){
      if((!this.state.projectOrCr.ProjectId) && (trueCount === this.state.projMandatoryDocTypes.length)){
        errorMessage = false;
      }
      else if((this.state.projectOrCr.ProjectId) && (trueCount === this.state.CRMandatoryDocTypes.length)){
        errorMessage = false;
      }
      else{
        errorMessage = true;
      }
    }

    let poNumberError = false;
    if (this.state.selectedChangeToStatus && this.state.selectedChangeToStatus.PermissionDescription === 'Active' && 
        this.state.isPONumberRequired && (!this.state.poNumber || this.state.poNumber === '')) {
      poNumberError = true;
    }

    let billingEntityError = false;
    if (this.state.selectedChangeToStatus && this.state.selectedChangeToStatus.PermissionDescription === 'Active' && 
        !this.state.billingEntityValue) {
          billingEntityError = true;
    }

    if (this.state.selectedChangeToStatus && this.state.selectedChangeToStatus.PermissionDescription === 'Active') {
      const billingMethod = this.state.projectOrCr.ProjectId ? this.state.projectOrCr.PaymentMethodId : this.state.projectOrCr.PaymentMethod;
      if (billingMethod && billingMethod !== 2 && this.state.projectOrCr.Milestone && this.state.projectOrCr.Milestone.length <= 0) {
        this.setState({
          showErrorImg: true,
          loading: false
        });
        const msg = "Please add a milestone at Project Creation and try again."
        this.toggleErrorMessageDialog(msg, 'Error');
        return;
      }
    }

    if(startDateErrorMessage || closeStatusErrorMessage || errorMessage || pastStartDateErrorMessage || poNumberError || billingEntityError){
      if(closeStatusErrorMessage){
        this.setState({
          isErrorMessageVisible: true,
          loading: false
        });
      }
      if(startDateErrorMessage){
        this.setState({
          showStartDateValidationError: true,
          loading: false
        });
      }
      if(errorMessage){
        this.setState({
          DocUploadErrorMessageVisible: true,
          fileUploadMessageVisible: false,
          projMandatoryDocTypes: [],
          CRMandatoryDocTypes: [],
          loading: false
        });
      }
      if(pastStartDateErrorMessage){
        this.setState({
          showStartDateErrorMessage: true,
          loading: false
        });
      }
      if(poNumberError){
        this.setState({
          showPONumberErrorMessage: true,
          loading: false
        });
      }
      if(billingEntityError){
        this.setState({
          showBillingEntityErrorMessage: true,
          loading: false
        });
      }
      return;
    }

    let projectOrCr = this.state.projectOrCr;
    //update project/cr
    let status = ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value;
    let startDate = this.state.startdateStatus ? (moment(this.state.startdateStatus).format('YYYY-MM-DD')) : null;
    let fileUploadIds = [];
    let uploadedFiles = this.state.fileUploadDetails;
    let fileUpLength = this.state.fileUploadDetails.length;
    let statusComment = this.state.stsChangeComment.replace(/(<([^>]+)>)/ig, '');
    let changedDate = moment(new Date()).format('YYYY-MM-DD');
    let statusName = ProjStatuses[this.state.selectedChangeToStatus.PermissionName].name;

    for(let el of uploadedFiles){
      fileUploadIds.push(el.FileUpload.id);
    }

    const statusChangeObj = {
      //projectId : projectOrCr.id,
      projectId: projectOrCr.ProjectId ? projectOrCr.ProjectId : projectOrCr.id,
      crId: projectOrCr.ProjectId ? projectOrCr.id : null,
      projStartDate : startDate,
      projStatus : status,
      fileIds: fileUploadIds,
      comment: statusComment,
      changedDate: changedDate,
      IsWorkflowExist: false,
      isCr : projectOrCr.ProjectId ? true : false,
      wfId: null,
      createdBy: window.LOGGED_USER.displayName,
      activeOrWarStartDate: activeOrWarStartDate
    };

    await this.populateSortedActivityLogData(statusChangeObj.projectId,statusChangeObj.crId,false);

    if(this.state.sortedActivity.length === 1){
      this.setState(
          {
            prevState: null,
          })
    }
    else if(this.state.sortedActivity.length === 2){
      this.setState(
          {
            prevState: this.state.sortedActivity[1],
          })
    }

    let startDateWF = this.state.startdateStatus ? (moment(this.state.startdateStatus).format('YYYY-MM-DD')) : (moment(new Date()).format('YYYY-MM-DD'));

    //pick WF for status change
    selectWorkflowStatus(projectOrCr.CMSheets[0] ? projectOrCr.CMSheets[0].id:0,{ EstimatedStartDate: startDateWF, SelectedCurrency: projectOrCr.Currency, PrevStatus: this.state.prevState ? parseInt(this.state.prevState.Status) : 0, CurrentStatus: this.CROrProjectCurrentStatusId,EndStatus: statusChangeObj.projStatus,projectOrCr:projectOrCr}).then(res => {
      if (res.data.length > 1) {

        statusChangeObj.IsWorkflowExist = true;
        statusChangeObj.ApprovalStatus = ApprovalStatusChange.PENDING.value;

        this.setState(
            {
              matchingWorkflowList: res.data,
              cmsId : projectOrCr.CMSheets[0] ? projectOrCr.CMSheets[0].id : null,
              statusChangeObj:statusChangeObj,
              isMoreWorkflow:true
            },
            () => {
              this.toggleWorkflowSelectStatusDialog();
            }
        );
      } else if (res.data.length === 1) {
        this.setState({
          statusChangeObj: statusChangeObj
        })
        statusChangeObj.IsWorkflowExist = true;
        statusChangeObj.ApprovalStatus = ApprovalStatusChange.PENDING.value;
        statusChangeObj.IsOneWorkflow = true;
        statusChangeObj.wfId = res.data[0].id;

        this.saveActivityLogWithStatus(statusChangeObj,projectOrCr,res.data[0].id);
      }
      else {
        this.saveActivityLogWithStatus(statusChangeObj,projectOrCr,null);
      }
    });

    //save on audit trail
    this.onSaveAuditTrail(statusChangeObj, statusName, projectOrCr.UniqueId);
  };

  toggleWorkflowSelectStatusDialog = () => {
    if (this.isMount) {
      this.setState({
        selectedCRId: null,
        IsPoolProject: false,
        loading:false,
        showWorkflowSelectDialog: !this.state.showWorkflowSelectDialog,
      });
    }
  };

  saveActivityLogWithStatus = async (statusChangeObj,projectOrCr,selectedWorkFlowId) => {
    this.setState({
      loading: true
    });

    await saveStatusChangeHistory(statusChangeObj).then(response => {
      this.setState({
            showSuccessImg: true,
            activityLogId:response.data.id
          },
          () => {
            if(statusChangeObj.IsWorkflowExist === false){
              this.setState({
                loading: false
              });
              this.toggleMessageDialog(`Status changed successfully`, 'Success');
            }

            if(statusChangeObj.IsOneWorkflow === true){
              this.setState(
                  {
                    selectedWorkFlowId: selectedWorkFlowId
                  },
                  () => {
                    this.setWorkflowToStatus(projectOrCr.CMSheets[0] ? projectOrCr.CMSheets[0].id : null,statusChangeObj);
                  }
              );
            }
          }
      );
    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
      this.toggleMessageDialog(error.response.data, 'Error');
    });
  }

  setWorkflowToStatus = async (cmSheetId,statusChangeObj) => {
    this.setState({
      loading: true
    });

    if(this.state.selectedWorkFlowId !== null){
      var statusChangeObj = this.state.statusChangeObj;
      statusChangeObj.wfId = this.state.selectedWorkFlowId;

      if(this.state.isMoreWorkflow === true){
        await this.saveActivityLogWithStatus(statusChangeObj,this.state.projectOrCr,null);
      }
      await setWorkflowStatus({ cmSheetId: cmSheetId, workflowId: this.state.selectedWorkFlowId ,statusChangeObj:statusChangeObj,activityLogId : this.state.activityLogId})
          .then(res => {
            if(this.state.isMoreWorkflow === true){
              this.toggleWorkflowSelectStatusDialog();
            }
            this.toggleMessageDialog(`Status Change submitted for Approval`, 'Success');

            this.setState({ selectedWorkFlowId: null });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            if(this.state.isMoreWorkflow === true){
              this.toggleWorkflowSelectStatusDialog();
            }
            const message = error.response.data;
            const title = 'Error';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
            this.toggleMessageDialog(message, title);
          });

      this.setState({
        loading: false
      });
    }
  };

  populateSortedActivityLogData = async (projectId,crId,isPending) => {
    this.setState({
      loading: true
    });

    let ids = [];
    ids  = {
      projectId : projectId,
      crId: crId,
      isPending:isPending,
      forPickWK:true
    }

    await getActivityLogDataSort(ids).then(response => {
      this.setState({
        sortedActivity: response.data
      });
    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  onSaveAuditTrail = (statusChangeObj, statusName, uniqueId) => {
    this.setState({
      loading: true
    });

    let userName = window.LOGGED_USER.displayName;

    //project
    if(statusChangeObj.isCr === false){
      const auditTrailObj = {
        Action: 'Project status changed to ' + statusName,
        RefType: 'Project' ,
        RefId: statusChangeObj.projectId,
        RefName: uniqueId
      }
      saveAuditTrail(auditTrailObj);
    }
    //cr
    else{
      const auditTrailObj = {
        Action: 'CR status changed to ' + statusName,
        RefType: 'Change Request' ,
        RefId: statusChangeObj.crId,
        RefName: uniqueId
      }
      saveAuditTrail(auditTrailObj);
    }
  };


  populateActivityLogData = async (projectOrCr, resourceId) => {
    await getActivityLogData(resourceId).then(response => {
      let ProjActivityLogLst = [];
      let CrActivityLogLst = [];
      let changedDate = '';
      let selectedCrActivityLogLst = [];

      this.setState({
        allActivityLogList: response.data
      });

      //activityLog status name
      for(let activityLogItem of this.state.allActivityLogList){
        for(let status in ProjStatuses){
          if(ProjStatuses[status].value == activityLogItem.Status){
            activityLogItem.Status = ProjStatuses[status].name;
          }
        }
      }

      for(let el of this.state.allActivityLogList){
        changedDate = moment(el.changedDate).format('YYYY-MM-DD') ;
        el.changedDate = changedDate;
      }

      for(let el of this.state.allActivityLogList){
        if(el.CrId === null){
          ProjActivityLogLst.push(el);
        }
        else{
          CrActivityLogLst.push(el);
        }
      }

      if(projectOrCr.ProjectId){
        for(let el of CrActivityLogLst){
          if(el.CrId === projectOrCr.id){
            selectedCrActivityLogLst.push(el);
          }
        }
        this.setState({
          activityLogList: selectedCrActivityLogLst
        });
      }
      else{
        this.setState({
          activityLogList: ProjActivityLogLst
        });
      }


      let maxId = this.state.activityLogList.length > 0 ? (Math.max(...this.state.activityLogList.map(el => el.id))) : 0;
      let lastRecord = this.state.activityLogList.filter(el => el.id == maxId);

      if(lastRecord && lastRecord.length > 0){
        if(lastRecord[0].ApprovalStatus && lastRecord[0].ApprovalStatus === 'PENDING'){
          this.setState({
            isApprovalStatusPending: true,
            reqStatus: lastRecord[0].Status
          });

          let ProjOrCrId = lastRecord[0].CrId !== null ? lastRecord[0].CrId : lastRecord[0].ProjectId;
          let workflowId = lastRecord[0].WorkflowId;
          let type = lastRecord[0].CrId !== null ? 'ChangeRequest' : 'Project';

          const approvalObj = {
            ProjectId: ProjOrCrId,
            WorkflowId: workflowId,
            Type: type
          };

          this.populateApprovalData(approvalObj);
        }
      }

      let statusChangeDetails = [];
      let activityLogList = this.state.activityLogList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      for (const item of activityLogList) {
        if (item.ApprovalStatus === 'PENDING') {
          let activityLogObj = {
            CrId: item.CrId,
            ProjectId: item.ProjectId,
            FileUpload: item.FileUpload,
            Status: item.Status,
            DateTime: moment(item.createdAt).format("MM-DD-YYYY hh:mm:ss A"),
            changedBy: item.changedBy,
            Approver: item.changedBy,
            ApprovalStatus: '',
            Comment:item.Comment,
          }
          statusChangeDetails.push(activityLogObj);
          if (item.Approvals && item.Approvals.length > 0) {
            let Approvals = item.Approvals.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            for (const approval of Approvals) {
              let approvalObj = {
                CrId: item.CrId,
                ProjectId: item.ProjectId,
                FileUpload: [],
                Status: item.Status,
                DateTime: approval.Date ? moment(approval.Date).format("MM-DD-YYYY hh:mm:ss A") : "",
                changedBy: item.changedBy,
                Approver: approval.Resource.Name,
                ApprovalStatus: approval.Status,
                Comment:approval.Comment,
              }
              statusChangeDetails.push(approvalObj);
            }
          }      
        } else if (item.WorkflowId == null){
          let activityLogObj = {
            CrId: item.CrId,
            ProjectId: item.ProjectId,
            FileUpload: item.FileUpload,
            Status: item.Status,
            DateTime: moment(item.createdAt).format("MM-DD-YYYY hh:mm:ss A"),
            changedBy: item.changedBy,
            Approver: item.changedBy,
            ApprovalStatus: '',
            Comment:item.Comment,
          }
          statusChangeDetails.push(activityLogObj);
        }
      }

      this.setState({  
        statusChangeGridDetails: statusChangeDetails,
      });


    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };


  populateApprovalData = async (approvalObj) => {
    await getApprovalsData(approvalObj).then(response => {
      this.setState({
        approvalsDetails: response.data
      });

      if(this.state.approvalsDetails && this.state.approvalsDetails.length > 0){
        let approvalsDetails = this.state.approvalsDetails;
        let approvers = [];
        
        approvalsDetails.forEach(el => {approvers.push(el.Resource.Name);})

        if(approvers.length > 1){
          var approversSet = "";
          var i = 1;

          for(let approver of approvers){
            if(i >= approvers.length){
              approversSet += approver + '';
            }
            else{
              approversSet += approver + ', ';
            }
            i++;
          }

          this.setState({
            approversName: approversSet
          });
        }
        else{
          this.setState({
            approversName: approvers
          });
        }
      }

    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };


  handleStatusPopUpCR = async (event, Cr, action) => {
    await getCRsOfAProject(Cr.id).then(async res => {

      if (res.data.length > 0) {
        if (this.isMount) {
          await this.setState({
            changeRequests: res.data,
            ChangeRequestAction: action,
            statusChangeCr: Cr
          },
          async () => {
              if (res.data.length === 1) {
                await this.setState({
                  selectedCRId: res.data[0].id
                });
                this.onStatusPopUpCRClick();
              } else {
                this.setState({
                  statusChangeCR: true
                });
                this.toggleCRSelectDialog();
              }
            });
        }
      } else {
        this.toggleMessageDialog('No CRs found', 'Error');
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  onStatusPopUpCRClick = async() => {
    if (this.state.ChangeRequestAction === 'HandleCrPopUp') {
      this.setState({
        statusChangeCR: true,
        statusChangeCRToggleCount: 0
      });

      // Close the CR selection popup
      if (this.isMount && this.state.showCRSelectDialog === true) {
        this.setState({
          showCRSelectDialog: !this.state.showCRSelectDialog
        });
      }

      // do the permission validation to open the dialog
      this.statusChangePermission(this.state.statusChangeCr, 'ChangeRequest');
      this.toggleChangeStatusDialog();

      return;
    }
  };


  reDirectToHome = event => {
    this.setState({
      toHomePage: true,
      closeChangeStatus: false
    });
  };


  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validateCommentProperty = value => {
    if (value && value.length > 0 && value != "<p><br></p>" ) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  subscribe = async(id) =>{
    if(this.state.keyId !== 10)
    {
      await this.setState({
        keyId:id
      });
      if(this.state.keyId === 10)
      {
          //this.retrieveRecentActiveProjects(10);
          this.populateStatuses();
          this.populateCustomers();
          this.populateDivisions();
          this.populateRegions();
          this.populateProjectTypes();
          this.populatePaymentMethods();
          this.populateProjectIds();
          this.populateOPIDs();
          this.populatePMResources();
          this.populateCMSStatus();
          this.populateCRIds();
          this.populateCMSTypes();
          this.populateLegalEntityTypess();
      }        
    }
}


  componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    //this.setHeaderTitle();
    this.subscribe(10);
    

    $(document).ready(function () {
      $("div.title-remove > span > span > span:last-child").removeAttr("title");
    });
  }

  componentWillUnmount() {
    this.isMount = false;
    this.unsubscribe(10);
  }

  
  unsubscribe = (id) =>{
    this.setState({
      keyId:id
      });
  }

  retrieveRecentActiveProjects = count => {
    getRecentActiveProjects(count)
      .then(res => {
        if (this.isMount) {
          this.setState({
            Projects: res.data.rows
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateStatuses = () => {
    getProjectStatuses()
      .then(res => {
        if (this.isMount) {
          this.setState({
            Statuses: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCustomers = () => {
    getcustomersIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            Customers: res.data,
            CustomersAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateDivisions = () => {
    getBillingDiviIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            Divisions: res.data,
            DivisionsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateRegions = () => {
    getRegionsIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            Regions: res.data,
            RegionsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateProjectTypes = () => {
    getProjectTypes()
      .then(res => {
        if (this.isMount) {
          this.setState({
            ProjectTypes: res.data,
            ProjectTypesAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populatePaymentMethods = () => {
    getPaymentMethods()
      .then(res => {
        if (this.isMount) {
          this.setState({
            PaymentMethods: res.data,
            PaymentMethodsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateProjectIds = () => {
    getAllUniqueIds()
      .then(res => {
        if (this.isMount) {
          this.setState({
            UniqueIds: res.data,
            UniqueIdsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateOPIDs = () => {
    getAllOPIDs()
      .then(res => {
        if (this.isMount) {
          this.setState({
            opids: res.data,
            opidsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populatePMResources = async () => {
    let resources = [];

    await getPMResources().then(res => {

      this.setState({
        resourceItemsPro: res.data
      });

      for(const obj of res.data){
        resources.push(obj)
      }

    })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

    await getProjectOwners().then(response => {
      let resourceAll = [];

      this.resourceAllPool = response.data;
      this.setState({
        resourceItemsPool: response.data
      });

      for(const obj of response.data){
        let resource = {
          id:obj.id,
          Name:obj.displayName
        }
        resourceAll = resources.filter(obj => obj.id  === resource.id);
        if(resourceAll && resourceAll.length === 0){
          resources.push(resource)
        }
      }
    })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

    this.resourceAll = resources

    await this.setState({
      resourceItems: resources
    });
  };

  populateCMSStatus = () => {
    getCMSStatus()
      .then(res => {
        if (this.isMount) {
          this.CMSStatusAll = res.data;
          this.setState({
            CMSStatus: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCRIds = () => {
    getAllCRUniqueIds()
      .then(res => {
        if (this.isMount) {
          this.setState({
            UniqueCRIds: res.data,
            UniqueCRIdsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCMSTypes = () => {
    getCmsTypes().then(res => {
      this.setState({
        cmsTypes: res.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateLegalEntityTypess = () => {
    getLegalEntityTypes().then(response => {
      this.allBillingEntities = response.data;
      this.setState({
        billingEntityType: response.data
      });
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  toggleErrorMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showErrorMessageDialog: !this.state.showErrorMessageDialog,
        dialogMessage: message,
        dialogTitle: title,
        closeChangeStatus: !this.state.closeChangeStatus
      }); 

      if(this.state.showErrorMessageDialog === true && this.state.changeStatusDialog === false){
        this.props.handelOnClick();
        this.setState({
          closeGlobalsearch: false
        });
      }
    }
  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title,
        closeChangeStatus: !this.state.closeChangeStatus
      });
    }
  };

  toggleConfirmDialog = async(message, action) => {
    if (this.isMount) {
      await this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
      if (this.state.showConfirmDialog == false) {
        await this.setState({
          selectedChangeToStatus: null,
        });
      }
    }
  };

  toggleDeallocateMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showdeallocateMessageDialog: !this.state.showdeallocateMessageDialog,
        dialogMessage: message,
        dialogTitle: title,
      });
    }
  };

  editCR = async (even, project, isProjectManager, hasEditCRPermission) =>{
      await getCRsOfAProject(project.id).then(res => {
          const projectManager = isProjectManager;
          const editCRPermission = hasEditCRPermission;

          let presaleCRs = null;
          let changeReqWithoutPendingCMSApprovals  = [];
          let changeReqWithoutPendingStatusApproval = [];

          this.setState({
            statusChangeCR: false
          });

          if(projectManager === true || (editCRPermission && editCRPermission.length>0) ){

            let crs = res.data;

            if(crs && crs.length > 0){
              for(let cr of crs){
                if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
                  cr.ActivityLogs.reverse(); // get the descending order
                  if(cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING'){
                    continue;
                  }
                  else{
                    changeReqWithoutPendingStatusApproval.push(cr);
                  }
                }
              }
            }

            presaleCRs = changeReqWithoutPendingStatusApproval.filter(obj => (obj.Status.Code === 'PRESALES' || obj.Status.Code === 'WAIT_CONFIRM' ||
                obj.Status.Code === 'BILLABLE' || obj.Status.Code === 'ON_HOLD' || obj.Status.Code === 'WORK_AT_RISK')  && obj.IsActive === true);

            presaleCRs.forEach(function (changeReq) {
                if(changeReq && changeReq.CMSheets && changeReq.CMSheets.length >0 && changeReq.CMSheets[0].Status !== 'PENDING_APPROVALS'){
                    changeReqWithoutPendingCMSApprovals.push(changeReq);
                }
                else if(changeReq && changeReq.CMSheets && changeReq.CMSheets.length ===0){
                    changeReqWithoutPendingCMSApprovals.push(changeReq);
                }
            })

          }
          else {
              let changeRequests = []
              if (project.ChangeRequests && project.ChangeRequests.length>0) {
                  for (const cr of project.ChangeRequests) {
                      // if (cr && cr.ResourceRequestStatus && cr.ResourceRequestStatus === 'PM_ALLOCATED') {
                      //     if (cr.AllocatedPM && cr.AllocatedPM.length>0) {
                      //         for(const allocatedPM of cr.AllocatedPM){
                      //             if (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
                      //                 changeRequests.push(cr)
                      //             }
                      //         }
                      //     }
                      // } else {
                      //     if (window.LOGGED_USER.userId.toUpperCase() === cr.PresalesResource.Email.toUpperCase() && cr.IsActive === true) {
                      //         changeRequests.push(cr)
                      //     }

                      // }
                      if (cr.AllocatedPM && cr.AllocatedPM.length > 0) {
                        //valid managers
                        const crProjectManagers = cr.AllocatedPM;
                  
                        //find valid pms from end date
                        let validManagers = [];
                        validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())));
                  
                        if (validManagers.length === 0 && crProjectManagers.length > 0) {
                          crProjectManagers.sort(function (a, b) {
                            return new Date(b.EndDate) - new Date(a.EndDate)
                          })
                          const lastAssignedManager = crProjectManagers[0];
                          validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate));
                  
                        }
                  
                        validManagers.forEach(value => {
                          if (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()) {
                            changeRequests.push(cr)
                          }
                        });
                      }
                  }
              }


              if(changeRequests && changeRequests.length > 0){
                for(let cr of changeRequests){
                  if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
                    if(cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING'){
                      continue;
                    }
                    else{
                      changeReqWithoutPendingStatusApproval.push(cr);
                    }
                  }
                }
              }

              presaleCRs = changeReqWithoutPendingStatusApproval.filter(obj => (obj.Status.Code === 'PRESALES' || obj.Status.Code === 'WAIT_CONFIRM' ||
                  obj.Status.Code === 'BILLABLE' || obj.Status.Code === 'ON_HOLD' || obj.Status.Code === 'WORK_AT_RISK') &&
                  // window.LOGGED_USER.userId.toUpperCase() === obj.PresalesResource.Email.toUpperCase() &&
                  obj.IsActive === true);

              presaleCRs.forEach(function (changeReq) {
                  if(changeReq && changeReq.CMSheets && changeReq.CMSheets.length>0 && changeReq.CMSheets[0].Status !== 'PENDING_APPROVALS'){
                      changeReqWithoutPendingCMSApprovals.push(changeReq);
                  }
                  else if (changeReq && changeReq.CMSheets && changeReq.CMSheets.length ===0) {
                      changeReqWithoutPendingCMSApprovals.push(changeReq);
                  }
              })
          }

          if(res.data.length > 0){
              this.setState({
                      changeRequests: changeReqWithoutPendingCMSApprovals
                  },
                  () => {
                  if (changeReqWithoutPendingCMSApprovals.length === 1) {
                      this.setState({
                          selectedCRId: changeReqWithoutPendingCMSApprovals[0].id,
                          redirectToEditCR: true
                      });
                  } else if (changeReqWithoutPendingCMSApprovals.length > 1) {
                      this.toggleCRSelectDialog();
                  }
              })
          }
      })
      .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  onClickProject = async (event) =>{
      this.props.handelOnClick();
  };

  onOpenChangeStatus = async (event) => {
    this.props.handleOpenChangeStatus();
  }

  setSelectedCRId = crId => {
        this.setState({
            selectedCRId: crId
        });
    };

  onEditCRSelect = () =>{
        this.setState({
            redirectToEditCR: true
        });

    }

  toggleCRSelectDialog = () => {
        this.setState({
            showCRSelectDialog: !this.state.showCRSelectDialog,
            selectedCRId: null
        });
    };

    toggleViewCMSSelectDialog = () =>{
        this.setState({
            CMSSelecctDialog: !this.state.CMSSelecctDialog,
            selectedCMSId: null
        });
    }

    setSelectedCMSId = (cmsId, cmSheet ,reference) =>{
        this.setState({
            selectedCMSId: cmsId,
            selectedCMSReference: reference,
            selectedCMSheet: cmSheet.CMSheets[0],
            selectedCRId: cmSheet.id

        });
    }

    selectCMS = () =>{
        if(this.state.cmsAction === 'revise'){
            this.setState({
                redirectToReviseCMS : true
            });
        }
        else if(this.state.cmsAction === 'edit'){
            this.setState({
                redirectToEditCMS: true
            })
        }
        else if(this.state.cmsAction === 'view'){
            this.setState({
                redirectToViewCMS: true
            });
        }

    }

    onSelectCMS = (project, action, projectCMS_, crCMS) =>{
        this.setState({
                projectIdOfSelectedCMS: project.id,
                projectCMS: projectCMS_,
                changeRequestCMS: crCMS,
                cmsAction: action
            },
            () => {
                if (projectCMS_.length === 1 && crCMS.length === 0) {
                    this.setSelectedCMSId(projectCMS_[0].data.id, projectCMS_[0].data, 'projectCMS');
                    this.selectCMS();
                } else if (projectCMS_.length === 0 && crCMS.length === 1) {
                    this.setSelectedCMSId(crCMS[0].data.id, crCMS[0].data, 'crCMS');
                    this.selectCMS();
                } else {
                    this.toggleViewCMSSelectDialog();
                }
            });
    }


  render() {
      if (this.state.redirectToEditCR === true) {
          this.props.handelOnClick();
          return (
              <Redirect
                  to={{
                      pathname: '/changeRequest/edit',
                      changeReqId: this.state.selectedCRId,
                      action: 'edit'
                  }}
              />
          );
      }

      if (this.state.redirectToViewCMS === true) {
          this.props.handelOnClick();
          return (
              <Redirect
                  to={{
                      changeReqId: this.state.selectedCRId,
                      projectId: this.state.projectIdOfSelectedCMS,
                      cmSheet: this.state.selectedCMSheet,
                      reference: this.state.selectedCMSReference,
                      pathname: '/projects/cmsheet/cmsheet',
                      action: 'view'
                  }}
              />
          );
      }

      if (this.state.redirectToEditCMS === true) {
          this.props.handelOnClick();
          return (
              <Redirect
                  to={{
                      changeReqId: this.state.selectedCRId,
                      projectId: this.state.projectIdOfSelectedCMS,
                      cmSheet: this.state.selectedCMSheet,
                      reference: this.state.selectedCMSReference,
                      pathname: '/projects/cmsheet/cmsheet',
                      action: 'edit'
                  }}
              />
          );
      }


    if (this.state.toHomePage === true) {
      this.props.handelOnClick();
      return (
        <Redirect to="/" />
      );
    }
  
    //show/hide file upload button overlay
    var fileUploadDivStyle = {
        display: ((!this.state.disableFileUpBtnDiv) || (this.state.selectedChangeToStatus == null) || (this.state.invalidatePermission)) ? 'block' : 'none'
    };

    //hide global search popup
    var globalSearchDiv = {
      display: (this.state.closeGlobalsearch) ? 'none' : 'block'
    }

    //hide status change
    var ChangeStatusDivStyle = {
      opacity: (this.state.closeChangeStatus) ? '0' : '1'
    }


    return (
      <div ref={this.myRef}>
        <div className="global-search-content" style={globalSearchDiv}>
          <div className="close-btn">
            <Button onClick={this.props.handelOnClick}>X</Button>
          </div>
          <Grid className="no-records"
            data={this.props.searchResult.rows}
            skip={this.props.skip}
            take={this.props.take}
            total={this.props.searchResult.count}
            pageable={true}
            onPageChange={this.props.pageChange}
            resizable
          >
            <Column
              field=""
              title="PROJECT"
              width="650px"
              cell={props => <AllDataCell {...props} onEditCR={this.editCR} onClickProject={this.onClickProject} handleChangeStatusPopUp={this.handleChangeStatusPopUp} handleStatusPopUpCR={this.handleStatusPopUpCR} onSelectCMS={this.onSelectCMS} resultLength={this.props.searchResult.rows.length}/>}
            />
            {/* <Column
              field=""
              title="PROJECT"
              width="250px"
              cell={props => <ProjectCell {...props} onEditCR={this.editCR} onClickProject={this.onClickProject} handleChangeStatusPopUp={this.handleChangeStatusPopUp} handleStatusPopUpCR={this.handleStatusPopUpCR}/>}
            />
            <Column
              field=""
              title="COST MANAGEMENT SHEET"
              width="250px"
              cell={props => <CMSheet {...props} onSelectCMS={this.onSelectCMS} />}
            /> */}
          </Grid>

            {this.state.showCRSelectDialog === true && (
                <Dialog title="Select the relevant CR to edit" onClose={this.toggleCRSelectDialog} width="400px">
                    <div className="row">
                        <div className="col-md-12 ml-4">
                            <div className="row">
                            </div>
                            <div className="row">
                                    <div className="k-form-field">
                                        {this.state.changeRequests.map((option, i) => {
                                            return (
                                                <div className="ml-3">
                                                    <input
                                                        type="radio"
                                                        id={option.id}
                                                        name={'' + option.id}
                                                        value={option.id}
                                                        checked={this.state.selectedCRId === option.id}
                                                        className="k-radio"
                                                        key={'op'+option.id}
                                                        onChange={this.setSelectedCRId.bind(this, option.id)}
                                                    />
                                                    <label
                                                        key={'opq'+option.id}
                                                        htmlFor={option.id}
                                                        className="k-radio-label case-insensitive"
                                                    >
                                                        {option.CRName}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                            </div>
                        </div>
                    </div>

                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleCRSelectDialog}>
                            No
                        </button>
                        <button
                            className="k-button modal-primary"
                            onClick={this.state.statusChangeCR ? this.onStatusPopUpCRClick : this.onEditCRSelect}
                        >
                            Yes
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}

            {this.state.CMSSelecctDialog === true && (
                <CMSselectDialog
                toggleViewCMSSelectDialog={this.toggleViewCMSSelectDialog}
                projectCMS={this.state.projectCMS}
                selectedCMSId={this.state.selectedCMSId}
                setSelectedCMSId={this.setSelectedCMSId}
                changeRequestCMS={this.state.changeRequestCMS}
                selectCMS={this.selectCMS}
                cmsAction={this.state.cmsAction}

                />
            )}

        </div>

        <div className="">
          {/* CHANGE STATUS POPUP */}
          {this.state.changeStatusDialog && <Dialog title={"Change Status - " + (this.state.projectOrCr && this.state.projectOrCr.ProjectName? this.state.projectOrCr.ProjectName + " - " + this.state.projectOrCr.UniqueId  : (this.state.projectOrCr ? this.state.projectOrCr.CRName + " - " + this.state.projectOrCr.UniqueId  : ""))} onClose={this.toggleChangeStatusDialog} width={700} style={ChangeStatusDivStyle}>
            <div className="row">
              <div className="col-md-4">
                <div className="d-block">
                  <label className="change-status-labelname">Status:</label>
                </div>
                <div className="d-block">
                  <div className="" >
                    <ComboBox
                      name="selectedChangeToStatus"
                      textField="PermissionDescription"
                      dataItemKey="PermissionName"
                      value={this.state.selectedChangeToStatus}
                      data={this.state.statusValueLst}
                      placeholder={this.CROrProjectCurrentStatus}
                      disabled={this.state.invalidatePermission || this.state.isApprovalStatusPending}
                      onChange={this.handleOnChangeStatus}
                      filterable={true}
                      onFilterChange={this.filterStatusOnChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-8 my-auto">
                <div className="d-block">
                  <label></label>
                </div>

                <div className="d-block">
                  {this.state.showCloseSubCategory && (
                    <div className="d-block">
                      <input
                        type="radio"
                        name="changestatus"
                        value="discontinue"
                        className="k-radio"
                        id="discontinue"
                        disabled="true"
                        onChange={this.handleStatusChangeRbtn}
                      />
                      <label className="k-radio-label mr-2 change-status-labelname" htmlFor="discontinue">
                        Discontinue
                      </label>

                      <input
                        type="radio"
                        name="changestatus"
                        value="wthtbid"
                        className="k-radio"
                        id="wthtbid"
                        disabled="true"
                        onChange={this.handleStatusChangeRbtn}
                      />
                      <label className="k-radio-label mr-2 change-status-labelname" htmlFor="wthtbid">
                        Without Bidding
                      </label>

                      <input
                        type="radio"
                        name="changestatus"
                        value="oppolost"
                        className="k-radio"
                        id="oppolost"
                        disabled="true"
                        onChange={this.handleStatusChangeRbtn}
                      />
                      <label className="k-radio-label change-status-labelname" htmlFor="oppolost">
                        Opportunity Lost
                      </label>

                      <div>
                        {this.state.isErrorMessageVisible === true ? (
                          <span className={this.validateProperty(this.state.projectname)}>
                            Please select a sub-category
                          </span>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </div>

            <div className="row col-md-auto">
              {this.state.isApprovalStatusPending === true ? (
                <span className={this.validateProperty(this.state.projectname)}>
                  {this.state.reqStatus + ' workflow is pending with ' + this.state.approversName}
                </span>
              ) : null}
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="d-block">
                  <label className="change-status-labelname">Start Date:</label>
                </div>
                <div className="d-block">
                  <div className="" >
                    {((((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) && (this.state.endDateAugmentation !== null)) || (((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order")) && (this.state.endDateBlanketWork !== null))) ?
                      (
                        <DatePicker
                          width="100"
                          name="startdateStatus"
                          onChange={this.handleStartdateChange}
                          value={this.state.startdateStatus}
                          min={new Date()}
                          disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null || this.state.projectActivated || (this.state.currentStatus[0].Code == 'PROJECT_ON_HOLD' && this.state.selectedChangeToStatus.PermissionDescription === 'Active')}
                          format="MM/dd/yyyy"
                          formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                          required={(((this.state.projtypeValue && this.state.billingdiviValue) && this.state.projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order"))) ? true : false}
                        />
                      ) : (
                        <DatePicker
                          width="100"
                          name="startdateStatus"
                          onChange={this.handleStartdateChange}
                          value={this.state.startdateStatus}
                          min={new Date()}
                          disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null || this.state.projectActivated || (this.state.currentStatus[0].Code == 'PROJECT_ON_HOLD' && this.state.selectedChangeToStatus.PermissionDescription === 'Active')}
                          format="MM/dd/yyyy"
                          formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                          required={(((this.state.projtypeValue && this.state.billingdiviValue) && this.state.projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order"))) ? true : false}
                        />
                      )}
                  </div>

                  <div>
                    {(this.state.showStartDateValidationError) ? 
                      ( <span className={this.validateProperty(this.state.startdateStatus)}>
                          Please enter a start date
                        </span>
                      ) : null}
                  </div>

                  <div>
                    {(this.state.showStartDateErrorMessage) ? 
                      ( <span className={this.validateProperty()}>
                          Start date cannot be a past date
                        </span>
                      ) : null}
                  </div>

                </div>

                <div className="d-block">
                  <div className="d-block mandatory">
                    <label>Billing Entity:</label>
                  </div>
                  <div className="d-block">
                    <div className="" >
                    <ComboBox
                      data={this.state.billingEntityType}
                      textField="Name"
                      dataItemKey="id"
                      placeholder="Please Select"
                      value={this.state.billingEntityValue}
                      onChange={this.handleBillingEntityChange}
                      disabled={this.state.disablePONumber || this.state.selectedChangeToStatus == null}
                      filterable={true}
                      onFilterChange={this.filterBillingEntities}
                    />
                    </div>
                    <div>
                      {this.state.showBillingEntityErrorMessage === true ? (
                          <span className={this.validateProperty(this.state.billingEntityValue)}>
                            Please select a Billing Entity
                          </span>
                        ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="d-block">
                  <label className="change-status-labelname">Document Upload:</label>
                </div>
                <div className="d-block">
                  <div className="" >

                    <div className="d-flex flex-col justify-content-between">
                      <ComboBox
                        name="documentType"
                        textField="DocType"
                        dataItemKey="id"
                        value={this.state.documentType}
                        data={this.state.documentTypesForDropdown}
                        placeholder={'Please Select'}
                        disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null}
                        onChange={this.handleOnChangeDocType}
                        filterable={true}
                        onFilterChange={this.filterDocTypeOnChange}
                        itemRender={this.handleHighlightMandatoryDocs}
                      />

                      {/*file upload button*/}
                      <div className="d-flex flex-col">
                        <div className="rounded-link ml-3 align-self-center">
                          <span className="k-icon k-i-upload"/>
                        </div>
                        <input 
                          type="file" 
                          name="file" 
                          title="Upload File"
                          className="custom-statusFile-input"
                          disabled={this.state.invalidatePermission || this.state.documentType == null}
                          onChange={this.handleFileUpload}
                          value={this.state.uploadedFileId}
                          multiple={true}
                        />
                      </div>

                      <div id="overlay" style={fileUploadDivStyle}></div>
                    </div>
                    
                    <div>
                      {this.state.DocUploadErrorMessageVisible === true ? (
                        <span className={this.validateProperty(this.state.projectname)}>
                          Please upload mandatory document(s)
                        </span>
                      ) : null}
                    </div>

                    <div>
                      {this.state.fileUploadMessageVisible === true ? (
                        <span className={this.validateProperty(this.state.projectname)}>
                        Only allow (doc, docx, pdf, xls, xlsx, ppt, pptx, jpg, jpeg, png, msg, htm) files
                        </span>
                      ) : null}
                    </div>

                    <pre/>

                    {/*uploaded document section*/}
                    <div className="row">
                      <div className="col-md-12">
                        <Grid 
                        data={this.state.fileUploadDetails}
                        resizable={true}
                        >
                          <Column field="DocType" title="Document Type" width="120px" />
                          <Column
                            field=""
                            title="Document Name"
                            cell={props => (<StatusFileUploadCell ApiUrl={apiUrl} {...props} />)}
                          />
                          <Column 
                            field="" 
                            title="Action"
                            cell={props => (<DeleteCell {...props} OnDelete={this.OnDelete}/>)} 
                            width="60px"
                          />
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {this.state.isPONumberRequired && (
                <div className="col-md-4">
                  <div className="d-block mandatory">
                    <label>PO Number:</label>
                  </div>
                  <div className="d-block">
                    <div className="" id="poNumberToolTip">
                      <Input
                        autoComplete="off"
                        type="text"
                        name="poNumber"
                        value={this.state.poNumber}
                        onChange={this.handlePONumberChange}
                        disabled={this.state.disablePONumber || this.state.selectedChangeToStatus == null}
                      />
                      {this.state.showPONumberErrorMessage === true ? (
                        <span className={this.validateProperty(this.state.poNumber)}>
                          Please provide a PO number
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              
              <div className={this.state.isPONumberRequired ? "col-md-8" : "col-md-12"}>
                <div className="d-block">
                  <label className="change-status-labelname">Comment:</label>
                </div>
                <div className="d-block">
                  <div className="" >
                    <ReactQuill
                      name="statusChangeComment"
                      onChange={this.onChangeStsChgComment}
                      value={this.state.stsChangeComment}
                      maxLength="100"
                      readOnly={this.state.invalidatePermission || this.state.selectedChangeToStatus === null}
                    />
                  </div>
                  <div>
                  {(this.state.selectedChangeToStatus != null && (ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 14 || ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 13)) ? 
                    ( <span className={this.validateCommentProperty(this.state.stsChangeComment)}>
                        Please enter a comment
                      </span>
                    ) : null}
                </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Grid
                  style={{ maxHeight: '125px' }}
                  data={orderBy(this.state.statusChangeGridDetails, this.state.sort)}
                  resizable
                  scrollable
                >
                  <Column field="Status" title="Status"/>                
                  <Column field="Approver" title="Approver" width="140px" />
                  <Column field="" title="Approval Status"
                          cell={props => (
                            <ApprovalStatusCell {...props} />
                          )}
                  />
                   <Column field="DateTime" title="Date and Time"/>
                  <Column field="" title="Comment" cell={props => (<StatusChangeCommentCell {...props}/>)} />
                  <Column field="" title="Document Uploaded" cell={props => (<ActivityLogFilesCell ApiUrl={apiUrl} name="DocType" {...props} />)}/>
                </Grid>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleChangeStatusDialog}>Cancel</button>
              <button 
                id="statusPopupSaveBtn" 
                className="k-button modal-primary" 
                disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null || 
                  ((ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 14 || ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 13) && (this.state.stsChangeComment == null || this.state.stsChangeComment == "" || this.state.stsChangeComment == "<p><br></p>" ))} 
                onClick={this.onSaveStatusChange}
                >
                Save
              </button>
            </DialogActionsBar>
          </Dialog>}
          


          {this.state.showMessageDialog === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="300px">
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
              <DialogActionsBar>
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                    </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.showConfirmDialog === true && (
            <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="300px">
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
              <DialogActionsBar>
              <button className="k-button " onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.showErrorMessageDialog === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleErrorMessageDialog} onClose={this.reDirectToHome} width="400px">
              {this.state.showErrorImg === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image" />
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
              <DialogActionsBar>
                <button className="k-button modal-primary" onClick={this.toggleErrorMessageDialog} onClick={this.reDirectToHome}>
                  OK
                    </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.showMessageDialog === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} onClose={this.reDirectToHome} width="400px">
              {this.state.showSuccessImg === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success Image" />
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
              <DialogActionsBar>
                <button className="k-button modal-primary"
                        onClick={this.toggleMessageDialog}
                        onClick={
                          (this.state.dialogMessage === 'Status changed successfully' || this.state.dialogMessage === 'Status Change submitted for Approval') ? this.reDirectToHome : this.toggleMessageDialog
                        }
                >
                  OK
                    </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.showdeallocateMessageDialog === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleDeallocateMessageDialog} width="300px">
              {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success"/>
              </div>
              )}
              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image"/>
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
              <DialogActionsBar>
                <button className="k-button modal-primary" onClick={this.toggleDeallocateMessageDialog}>
                  OK
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.showWorkflowSelectDialog === true && (
              <Dialog title="Select Workflow" onClose={this.toggleWorkflowSelectStatusDialog} width="300px">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <p className="subtitle-workflow" style={{ textAlign: 'center' }}>Please select a workflow.</p>
                    </div>
                    <div className="row">
                      <form className="k-form modified">
                        <div className="k-form-field">
                          {this.state.matchingWorkflowList.map((option, i) => {
                            return (
                                <div className="field-mod">
                                  <input
                                      type="radio"
                                      id={'' + option.id}
                                      name={'' + option.id}
                                      value={option.id}
                                      checked={this.state.selectedWorkFlowId === option.id}
                                      className="k-radio"
                                      key={option.id}
                                      onChange={this.setSelectedWorkflowId.bind(this, option.id)}
                                  />
                                  <label
                                      key={option.id}
                                      htmlFor={'' + option.id}
                                      className="k-radio-label"
                                  >
                                    {option.name}
                                  </label>
                                </div>
                            );
                          })}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <DialogActionsBar>
                  <button className="k-button" onClick={this.toggleWorkflowSelectStatusDialog}>
                    No
                  </button>
                  <button
                      className="k-button modal-primary"
                      onClick={this.setWorkflowToStatus.bind(this, this.state.cmsId,this.state.statusChangeObj)}
                      disabled={!this.state.selectedWorkFlowId}
                  >
                    Yes
                  </button>
                </DialogActionsBar>

              </Dialog>
          )}

          <Loader loading={this.state.loading} />
        </div>

      </div>
    );
  }
}

export default SystemSearch;
