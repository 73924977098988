import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import { Button } from '@progress/kendo-react-buttons';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ProjectProfitabilityExcel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: null,
            nullData: [{ columns: [], data: [] }],
        };
    }

    componentDidMount() {
        if (this.props.updateData === true) {
            this.populateProjectData();
        }
    }

    componentDidUpdate = async() => {
        if (this.props.updateData === true) {
            this.props.dataUpdated();
            await this.populateProjectData();
            document.getElementById("profitabilityExcel").click();
        }
    }

    populateProjectData = async() => {
        let projectData = [];
        const borderStyle = {
            border: {
                top: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" }
            }
        };

        if (this.props.projects && this.props.projects.length > 0) {            
            for (const project of this.props.projects) {
                projectData.push([
                    {
                        value: project.UniqueId ? project.UniqueId : '',
                        style: borderStyle
                    },
                    {
                        value: project.Customer ? project.Customer : '',
                        style: borderStyle
                    },
                    {
                        value: project.ProjectName ? project.ProjectName : '',
                        style: borderStyle
                    },
                    {
                        value: project.Department ? project.Department : '',
                        style: borderStyle
                    },
                    {
                        value: project.Practice ? project.Practice : '',
                        style: borderStyle
                    },
                    {
                        value: project.BillingRegion ? project.BillingRegion : '',
                        style: borderStyle
                    },
                    {
                        value: project.StartDate ? project.StartDate : '',
                        style: borderStyle
                    },
                    {
                        value: project.EndDate ? project.EndDate : '',
                        style: borderStyle
                    },
                    {
                        value: project.ProjectManager ? project.ProjectManager : '',
                        style: borderStyle
                    },
                    {
                        value: project.ProjectStatus ? project.ProjectStatus : '',
                        style: borderStyle
                    },
                    {
                        value: project.CrCount ? project.CrCount : '',
                        style: borderStyle
                    },
                    {
                        value: project.TotalRevenueV1 ? project.TotalRevenueV1 : '',
                        style: borderStyle
                    },
                    {
                        value: project.TotalCostV1 ? project.TotalCostV1 : '',
                        style: borderStyle
                    },
                    {
                        value: project.NetMarginV1 ? project.NetMarginV1 : '',
                        style: borderStyle
                    },
                    {
                        value: project.NetMarginPctV1 ? project.NetMarginPctV1 : '',
                        style: borderStyle
                    },
                    {
                        value: project.GrossMarginV1 ? project.GrossMarginV1 : '',
                        style: borderStyle
                    },
                    {
                        value: project.GrossMarginPctV1 ? project.GrossMarginPctV1 : '',
                        style: borderStyle
                    },
                    {
                        value: project.ResourceCostV1 ? project.ResourceCostV1 : '',
                        style: borderStyle
                    },
                    {
                        value: project.OtherExpensesV1 ? project.OtherExpensesV1 : '',
                        style: borderStyle
                    },
                    {
                        value: project.SalesCommissionV1 ? project.SalesCommissionV1 : '',
                        style: borderStyle
                    },
                    {
                        value: project.TotalRevenueV2 ? project.TotalRevenueV2 : '',
                        style: borderStyle
                    },
                    {
                        value: project.TotalCostV2 ? project.TotalCostV2 : '',
                        style: borderStyle
                    },
                    {
                        value: project.NetMarginV2 ? project.NetMarginV2 : '',
                        style: borderStyle
                    },
                    {
                        value: project.NetMarginPctV2 ? project.NetMarginPctV2 : '',
                        style: borderStyle
                    },
                    {
                        value: project.GrossMarginV2 ? project.GrossMarginV2 : '',
                        style: borderStyle
                    },
                    {
                        value: project.GrossMarginPctV2 ? project.GrossMarginPctV2 : '',
                        style: borderStyle
                    },
                    {
                        value: project.ResourceCostV2 ? project.ResourceCostV2 : '',
                        style: borderStyle
                    },
                    {
                        value: project.OtherExpensesV2 ? project.OtherExpensesV2 : '',
                        style: borderStyle
                    },
                    {
                        value: project.SalesCommissionV2 ? project.SalesCommissionV2 : '',
                        style: borderStyle
                    },
                    {
                        value: project.TotalRevenueV3 ? project.TotalRevenueV3 : '',
                        style: borderStyle
                    },
                    {
                        value: project.TotalCostV3 ? project.TotalCostV3 : '',
                        style: borderStyle
                    },
                    {
                        value: project.NetMarginV3 ? project.NetMarginV3 : '',
                        style: borderStyle
                    },
                    {
                        value: project.NetMarginPctV3 ? project.NetMarginPctV3 : '',
                        style: borderStyle
                    },
                    {
                        value: project.GrossMarginV3 ? project.GrossMarginV3 : '',
                        style: borderStyle
                    },
                    {
                        value: project.GrossMarginPctV3 ? project.GrossMarginPctV3 : '',
                        style: borderStyle
                    },
                    {
                        value: project.ResourceCostV3 ? project.ResourceCostV3 : '',
                        style: borderStyle
                    },
                    {
                        value: project.OtherExpensesV3 ? project.OtherExpensesV3 : '',
                        style: borderStyle
                    },
                    {
                        value: project.SalesCommissionV3 ? project.SalesCommissionV3 : '',
                        style: borderStyle
                    },

                    {
                        value: project.TotalRevenueV4 ? project.TotalRevenueV4 : '',
                        style: borderStyle
                    },
                    {
                        value: project.TotalCostV4 ? project.TotalCostV4 : '',
                        style: borderStyle
                    },
                    {
                        value: project.NetMarginV4 ? project.NetMarginV4 : '',
                        style: borderStyle
                    },
                    {
                        value: project.NetMarginPctV4 ? project.NetMarginPctV4 : '',
                        style: borderStyle
                    },
                    {
                        value: project.GrossMarginV4 ? project.GrossMarginV4 : '',
                        style: borderStyle
                    },
                    {
                        value: project.GrossMarginPctV4 ? project.GrossMarginPctV4 : '',
                        style: borderStyle
                    },
                    {
                        value: project.ResourceCostV4 ? project.ResourceCostV4 : '',
                        style: borderStyle
                    },
                    {
                        value: project.OtherExpensesV4 ? project.OtherExpensesV4 : '',
                        style: borderStyle
                    },
                    {
                        value: project.SalesCommissionV4 ? project.SalesCommissionV4 : '',
                        style: borderStyle
                    }
                ])
            }

            const borderStyleMerged = {
                borderLeft: {
                    top: { style: "thin", color: "000000" },
                    left: { style: "thin", color: "000000" }
                },
                borderRight: {
                    top: { style: "thin", color: "000000" },
                    right: { style: "thin", color: "000000" },
                },
                borderTop: {
                    top: { style: "thin", color: "000000" },
                }
            };

            let mergedHeaderRow = [
                { value: ''},
                { value: ''},
                { value: ''},
                { value: ''},
                { value: ''},
                { value: ''},
                { value: ''},
                { value: ''},
                { value: ''},
                { value: ''},
                { value: ''},
                // view 1
                { value: 'View 1: Approved CMS Values',  style: {font: { bold: true }, border: borderStyleMerged.borderLeft}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderRight}},
                //view 2
                { value: 'View 2: Resource Allocation Based Cost and Revenue',  style: {font: { bold: true }, border: borderStyleMerged.borderLeft}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderRight}},
                // view 3
                { value: 'View 3: Allocated Cost and Actual + Forecast Revenue',  style: {font: { bold: true }, border: borderStyleMerged.borderLeft}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderRight}},
                // view 4
                { value: 'View 4: Actual + Forecasted Cost and Revenue',  style: {font: { bold: true }, border: borderStyleMerged.borderLeft}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderTop}},
                { value: '',  style: {font: { bold: true }, border: borderStyleMerged.borderRight}},
            ];

            let projectColumns = [
                { value: 'Project ID',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Customer',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Project Name',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Department',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Practice',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Billing Region',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Start Date',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'End Date',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Project Manager',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Project Status',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Active CRs',  style: {font: { bold: true }, border: borderStyle.border}},
                // view 1
                { value: 'Total Revenue',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Total Cost',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Net Margin',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Net Margin %',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Gross Margin',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Gross Margin %',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Total Resource Cost',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Non-Billable Expenses',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Sales Commission',  style: {font: { bold: true }, border: borderStyle.border}},
                //view 2
                { value: 'Total Revenue',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Total Cost',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Net Margin',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Net Margin %',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Gross Margin',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Gross Margin %',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Total Resource Cost',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Non-Billable Expenses',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Sales Commission',  style: {font: { bold: true }, border: borderStyle.border}},
                // view 3
                { value: 'Total Revenue',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Total Cost',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Net Margin',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Net Margin %',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Gross Margin',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Gross Margin %',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Total Resource Cost',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Non-Billable Expenses',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Sales Commission',  style: {font: { bold: true }, border: borderStyle.border}},
                // view 4
                { value: 'Total Revenue',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Total Cost',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Net Margin',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Net Margin %',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Gross Margin',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Gross Margin %',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Total Resource Cost',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Non-Billable Expenses',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Sales Commission',  style: {font: { bold: true }, border: borderStyle.border}},
            ];

            let emptyRow = [];
            for (const row of projectColumns) {
                if (row.value === 'Active CRs' || row.value === 'Start Date' || row.value === 'End Date') {
                    let record = { title: '', width: { wpx: 100 } }
                    emptyRow.push(record);    
                } else {
                    let record = { title: '', width: { wpx: 150 } }
                    emptyRow.push(record);    
                }
            }

            let reportHeading = [];
            reportHeading.push(
                [
                    {
                        value: 'Note: ',
                        style: {font: { bold: true}}
                    },
                    { value: 'All values are captured in USD' },
                ],
                [
                    {
                        value: 'Report Date: ',
                        style: {font: { bold: true}}
                    },
                    { value: this.props.reportDate },                ]
            );
    
            const projectDataGrid = [
                {
                    columns: [
                        {title: '', width: {wpx: 150}},
                        {title: '', width: {wpx: 150}},
                    ],
                    data: reportHeading
                },
                {
                    columns: emptyRow,
                    data: [mergedHeaderRow, projectColumns, ...projectData]
                }
            ];
    
            await this.setState({
                projectDataGrid: projectDataGrid
            });
        }
    }

    render() {
        return (
            <ExcelFile
                filename={'Project Profitability Report - ' + moment(new Date()).format('DD-MMM-YYYY')}
                element={ 
                    <Button id={"profitabilityExcel"} className="d-none">
                        {''}
                    </Button>
                }
            >
                <ExcelSheet
                    dataSet={this.state.projectDataGrid ? this.state.projectDataGrid : this.state.nullData}
                    name="Profitability Report"
                />
            </ExcelFile>
        );
    }
}

export default ProjectProfitabilityExcel;
