import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Tooltip } from 'reactstrap';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import { Redirect } from 'react-router';
import {
  DatePicker,
} from '@progress/kendo-react-dateinputs';

import { getSalesPersonIntegrated, getProjectPartnersIntegrated, getRegionsWithCountriesIntegrated, getBillingDiviIntegrated, getPracticeTypesIntegrated, getAllPracticeDivisionMapIntegrated } from '../../integration/MasterDataService';
import { filterBy } from '@progress/kendo-data-query';


import CustomerDetails from '../components/CustomerDetails';
import ResourceRequest from '../components/ResourceRequest';

import * as loggerService from "../../integration/LoggerService";

import { getProject, getProjectStatuses, saveProject, updateProject, viewProject, saveMilestones } from './ProjectService';
import { saveAuditTrail } from "../../integration/auditTrail/AuditTrailService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import BillingDetails from "../components/BillingDetails";
import { getPaymentMethods, getProjectTypes } from "../../masterData/common/CommonService";
import { getCustomerPrograms, getcustomers } from "../../masterData/customer/CustomerService";
import { getPMResources, saveResourceRequests, savePresalesResourceLineHistory, getLegalEntityTypes } from '../../resourceManagement/ResourceService';
import { getOfferings } from "../../masterData/offering/OfferingService";
import moment from "moment";
import $ from "jquery";
import { submitForResourceAllocation } from '../../resourceManagement/resourceRequest/ResourceRequestActivationService';
import Loader from "../../integration/components/Loader";
import { reStructureDate } from '../../integration/CommonUtils';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import Milestones from '../components/Milestones';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';


const customerAll = [];
const resourceAll = [];


class CreateProject extends Component {
  default;

  popupSet = {
    width: 'auto'
  };

  preDefinedPermissions = {
    customer: { preSales: true, billable: false, other: false },
    program: { preSales: true, billable: false, other: false },
    OPID: { preSales: true, billable: true, other: false },
    country: { preSales: false, billable: false, other: false },
    projectName: { preSales: true, billable: true, other: false },
    startDate: { preSales: true, billable: false, other: false },
    status: { preSales: true, billable: true, other: true },
    description: { preSales: true, billable: true, other: false },
    billableStatus: { preSales: true, billable: false, other: false },
    salesPerson: { preSales: false, billable: false, other: false },
    projectPartner: { preSales: false, billable: false, other: false },
    practice: { preSales: true, billable: false, other: false },
    billingRegion: { preSales: false, billable: false, other: false },
    engagementType: { preSales: true, billable: true, other: false },
    billingMethod: { preSales: false, billable: false, other: false },
    offering: { preSales: true, billable: true, other: false },
    endDate: { preSales: true, billable: true, other: false },
    endDateAugmentation: { preSales: true, billable: true, other: false },
    endDateBlanketWork: { preSales: true, billable: true, other: false },
  };

  constructor(props) {

    super(props);
    this.state = {
      action: '',
      project: null,
      cusToolTipOpen: false,
      countryToolTipOpen: false,
      programToolTipOpen: false,
      opidToolTipOpen: false,
      projnameToolTipOpen: false,
      projidToolTipOpen: false,
      statusToolTipOpen: false,
      salespersToolTipOpen: false,
      projpartnerToolTipOpen: false,
      practiceToolTipOpen: false,
      engagementToolTipOpen: false,
      billMethodToolTipOpen: false,
      billRegionToolTipOpen: false,
      offeringToolTipOpen: false,

      toDashboard: false,
      toSearchScreen: false,

      projectname: '',
      projdescription: '',
      startdate: null,
      enddate: null,
      projectConversionStartDate: null,
      billablestatus: true,
      statusItems: [],
      resourceItems: resourceAll.slice(),
      paymentmethodItems: [],
      billingdiviItems: [],
      billingregionItems: [],
      projectTypeItems: [],
      projparnterItems: [],
      salespersonItems: [],
      opid: '',
      programItems: [],
      customerItems: customerAll.slice(),
      countryItems: [],
      offeringItems: [],

      statusValue: null,
      cusnameValue: null,
      prognameValue: null,
      salespersValue: null,
      projpartnerValue: null,
      billingdiviValue: null,
      billingregionValue: null,
      projtypeValue: null,
      paymethodValue: null,
      resourceValue: null,
      primaryPM: null,
      countryValue: null,
      offeringValue: null,
      onHoldStatusBool: false,
      visible: false,
      billale: { value: null, touched: false },
      success: false,
      dialogMessage: '',
      dialogTitle: '',
      isEnableCreate: false,
      isEnableUpdate: false,
      isDisableSave: false,
      disableProjectid: true,
      projectid: '',
      isFormDirty: false,
      showCancelConfirmation: false,
      defaultStatus: null,
      startDateMin: new Date(),
      allSalesPersons: [],
      allProjectPartners: [],
      allBillingRegions: [],
      isErrorMessageVisible: false,

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      isViewMode: false,
      permissions: {
        customer: true,
        program: true,
        OPID: true,
        country: true,
        projectName: true,
        startDate: true,
        status: true,
        description: true,
        billableStatus: true,
        salesPerson: true,
        projectPartner: true,
        practice: true,
        billingRegion: true,
        engagementType: true,
        billingMethod: true,
        offering: true,
        endDate: false,
        endDateAugmentation:false,
        endDateBlanketWork:false
      },

      showStartDateValidationError: false,
      prevStatus: null,
      creatingAProject: false,
      redirectToUnatuhorizedPage: false,
      isAps: false,
      allocComment : null,

      preSalesResourceAllocations : [],
      //for resource augmentation
      endDateAugmentation:null,
      resourceAug:false,

      //for blanket work order
      endDateBlanketWork:null,
      isBlanketWorkOrder:false,

      isEditMode: false,
      isLoading: false,
      loading: false,
      showMessageDialog: false,
      showNbWarning: false,
      practiceItems: [],
      practiceValue: null,
      practiceDivisionMap: [],
      oldOpIdHistory: [],
      allowOpidEdit: true,
      visibleOpidHistory: false,
      poNumber: '',
      contractedEffort: '',
      selected: 0,
      milestones: [],
      showSuccessImage: false,
      showErrorImage: false,
      billingEntityType: [],
      billingEntityValue: null,
      billingEntityToolTipOpen: false
    };

    this.handleChange = this.handleChange.bind(this)

    this.escFunction = this.escFunction.bind(this);

    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleProjTypeChange = this.handleProjTypeChange.bind(this);
    this.handleProjDescChange = this.handleProjDescChange.bind(this);
    this.handleProjNameChange = this.handleProjNameChange.bind(this);
    this.handleStartdateChange = this.handleStartdateChange.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.handleResourceChange = this.handleResourceChange.bind(this);
    this.handlePrimaryPMChange = this.handlePrimaryPMChange.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  async componentDidMount() {
    if (this.props.location.key) {
      window.scrollTo(0, 0);
      this.setHeaderTitle(this.props.location.action);

      document.addEventListener("keydown", this.escFunction, false);
      this.setState({
        loading: true
      });
      this.setInitialData();
    } else {
      this.setInitialPermissionOnLoad();
    }

    $(document).ready(function() {
      $("div.title-remove > span > span > span:last-child").removeAttr("title");
  });

  }

  onSaveAuditTrail = (project, projectId) => {
    const auditTrail = {
      Action: 'Project viewed',
      RefType: 'Project',
      RefId: projectId,
      RefName: project.UniqueId
    }
    saveAuditTrail(auditTrail)
  }

  handleChange(value) {
    this.setState({ projdescription: value })
  }

  escFunction(event) {
    if (event.keyCode === 13) {
      if (this.state.isDisableSave === true) {
        this.reDirectToDashboard();
      }
    }
  }

  setInitialData = async () => {
    this.setState(
      {
        action: this.props.location.action
      },
      () => {
        if (
          (this.props.location.action === 'view' || this.props.location.action === 'edit') &&
          this.props.location.projectId > 0
        ) {
          this.setState({
            isLoading: true
          });
          viewProject(this.props.location.projectId)
            .then(res => {
              this.setState({
                isLoading: false
              });
              if (res && res.data && res.data.id === this.props.location.projectId) {
                if (this.props.location.action === 'view') {
                  this.onSaveAuditTrail(res.data, this.props.location.projectId)
                }
                this.setState(
                  {
                    project: res.data,
                    preSalesResourceAllocations: res.data.ResourceRequest,
                    milestones: res.data.Milestone
                  },
                  () => {
                    if (this.props.location.action === 'view') {
                      this.renderProjectDetails(res.data);
                    } else if (this.props.location.action === 'edit') {
                      this.state.onHoldStatusBool = this.state.project.ProjectStatus.Code === 'ON_HOLD' ? true : false;
                      this.state.permissions.practice = false;
                      this.state.permissions.engagementType = false;

                      if (res.data.ProjectType === 3 && res.data.BillingDivision === 30) { //For application support projects
                        this.state.permissions.endDate = true;
                      } else if(res.data.ProjectType === 6){
                        this.state.permissions.endDateAugmentation = true;
                      }
                      else if(res.data.ProjectType === 5){
                        this.state.permissions.endDateBlanketWork = true;
                      }
                      else{
                        this.state.permissions.endDate = false;
                        this.state.permissions.endDateAugmentation = false;
                        this.state.permissions.endDateBlanketWork = false;
                      }
                      this.updateProjectDetails(res.data);
                    } else {
                      // this.forceUpdate();
                    }

                    this.setPermissionsOfFields();
                    this.populateDropDownData();
                    this.checkOPIdHistory();
                    this.setState({
                      loading: false
                    });
                  }
                );
              }
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        } else {
          this.populateDropDownData();
          this.setState({
            loading: false
          });
        }
      }
    );
  };

  setInitialPermissionOnLoad = () => {
    this.setState({
      redirectToUnatuhorizedPage: true
    })
  }

  setHeaderTitle = async action => {
    if (this.props.onHeaderTitleChange) {
      if (action === 'view') {
        this.props.onHeaderTitleChange('View Project');
      } else if (action === 'edit') {
        this.props.onHeaderTitleChange('Edit Project');
      } else {
        this.props.onHeaderTitleChange('Create a Project');
        this.setState({
          creatingAProject: true
        });
      }
    }
  };

  checkOPIdHistory = async() => {
    if (this.state.project && this.state.project.OpidHistory && this.state.project.OpidHistory.length > 0) {
      let oldOpIdHistory = [];
      const sortedHistory = this.state.project.OpidHistory.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      for (const item of sortedHistory) {
        let isInitOpid = sortedHistory.indexOf(item) == 0 ? true : false;
        const obj = {
          OPID: item.OPID,
          UpdatedUser: item.UpdatedUser,
          createdAt: isInitOpid ? "" : moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss')
        }
        oldOpIdHistory.push(obj);
      }
      if(this.state.project.OpidHistory.length >= 3) {
        await this.setState({
          oldOpIdHistory: oldOpIdHistory,
          allowOpidEdit: false
        });
      }  else {
        await this.setState({
          oldOpIdHistory: oldOpIdHistory,
          allowOpidEdit: true
        });  
      }
    } else {
      await this.setState({
        oldOpIdHistory: this.state.project.OpidHistory,
        allowOpidEdit: true
      });
    }
  }

  onClickOpidHistory = async() => {
    this.setState({
      visibleOpidHistory: !this.state.visibleOpidHistory
    });
  }

  setPermissionsOfFields = () => {
    if (this.props.location.action === 'view') {
      this.setState({
        isViewMode: true
      });
    } else if (this.props.location.action === 'edit') {
      this.setState({
        isEditMode: true
      });
      const cmSheets = this.state.project.CMSheets;
      if (cmSheets.length > 0) {
        const permissions = Object.assign({}, this.state.permissions);
        if (
          this.state.project.ProjectStatus &&
          (this.state.project.ProjectStatus.Code === 'BILLABLE' ||
            this.state.project.ProjectStatus.Code === 'BILLABALE_CR' ||
            this.state.project.ProjectStatus.Code === 'WORK_AT_RISK')
        ) {
          for (const field of Object.keys(permissions)) {
            permissions[field] = this.preDefinedPermissions[field].billable;
          }
        } else if (
          this.state.project.ProjectStatus &&
          this.state.project.ProjectStatus.Code === 'PRESALES'
        ) {
          for (const field of Object.keys(permissions)) {
            permissions[field] = this.preDefinedPermissions[field].preSales;
          }
        } else {
          for (const field of Object.keys(permissions)) {
            permissions[field] = this.preDefinedPermissions[field].other;
          }
        }

        if (this.state.project.ProjectStatus) {
          if (this.state.project.ProjectStatus.Code === 'PRESALES' || this.state.project.ProjectStatus.Code === 'WAIT_CONFIRM' || this.state.project.ProjectStatus.Code === 'ON_HOLD' || this.state.project.ProjectStatus.Code === 'WORK_AT_RISK') {
            permissions['startDate'] = true;
          }
        }

        this.setState({
          permissions: permissions
        });
      }
    }
  };

  populateDropDownData = () => {
    getProjectStatuses().then(response => {
      if (this.state.action === 'edit') {
        let list = response.data;
        if (
          this.state.project.ProjectStatus &&
          this.state.project.ProjectStatus.Code !== 'BILLABALE_CR'
        ) {
          // Remove Billable with CR
          list = response.data.filter(obj => obj.Code !== 'BILLABALE_CR');
        }
        this.setState({
          statusItems: list
        });
      }
      if (this.state.action !== 'edit' && this.state.action !== 'view') {
         const presalesOption = response.data.filter(obj => obj.Code === 'PRESALES');
        this.setState({
          statusValue: presalesOption.length > 0 ? presalesOption[0] : null,
          statusItems: presalesOption,
          startDateMin: new Date('1970-01-01Z00:00:00:000')
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    getPMResources().then(response => {
      this.resourceAll = response.data;
      this.setState({
        resourceItems: response.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    getProjectPartnersIntegrated().then(response => {
      this.allProjectPartners = response.data;
      this.setState({
        projparnterItems: response.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    getBillingDiviIntegrated().then(response => {
      let billingDivionsActive = response.data.length > 0 ? response.data.filter(obj => obj.IsActive == true && obj.SFCode !== null) : [];
      this.allBillingDivisions = billingDivionsActive;

      this.setState({
        billingdiviItems: billingDivionsActive
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

      getPracticeTypesIntegrated().then(response => {
        this.allPracticeTypes = response.data;
        this.setState({
          practiceItems: response.data
        });
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      getAllPracticeDivisionMapIntegrated()
        .then(response => {
            this.setState({
                practiceDivisionMap: response.data
            });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

    getProjectTypes().then(response => {
      let projTypeItemsActive = (response.data && response.data.length > 0) ? (response.data.filter(el => el.IsActive && el.IsActive == true)) : [];
      this.allEngagementTypes = projTypeItemsActive;

      this.setState({
        projectTypeItems: projTypeItemsActive
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    getSalesPersonIntegrated().then(response => {
      this.allSalesPersons = response.data;
      this.setState({
        salespersonItems: response.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    getRegionsWithCountriesIntegrated().then(response => {
      this.allBillingRegions = response.data;
      this.setState({
        billingregionItems: response.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    getPaymentMethods().then(response => {
      this.allBillingMethods = response.data;
      this.setState({
        paymentmethodItems: response.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    getcustomers().then(response => {
      this.customerAll = response.data;
      this.setState({
        customerItems: response.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    getOfferings().then(response => {
      this.allOfferings = response.data;
      this.setState({
        offeringItems: response.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    getLegalEntityTypes().then(response => {
      this.allBillingEntities = response.data;
      this.setState({
        billingEntityType: response.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  //tool tip toggles
  toggleCountryToolTip = () => {
    this.setState({
      countryToolTipOpen: !this.state.countryToolTipOpen
    });
  };

  toggleProjNameToolTip = () => {
    this.setState({
      projnameToolTipOpen: !this.state.projnameToolTipOpen
    });
  };
  toggleStatusToolTip = () => {
    this.setState({
      statusToolTipOpen: !this.state.statusToolTipOpen
    });
  };

  toggleEngagementToolTip = () => {
    this.setState({
      engagementToolTipOpen: !this.state.engagementToolTipOpen
    });
  };
  toggleBillMethodToolTip = () => {
    this.setState({
      billMethodToolTipOpen: !this.state.billMethodToolTipOpen
    });
  };

  toggleOfferingToolTip = () => {
    this.setState({
      offeringToolTipOpen: !this.state.offeringToolTipOpen
    });
  };

  toggleBillingEntityToolTip = () => {
    this.setState({
      billingEntityToolTipOpen: !this.state.billingEntityToolTipOpen
    });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
    }
  };

  //filterevents for the combo boxes
  filterData(filter) {
    const data = this.customerAll.slice();
    return filterBy(data, filter);
  }

  filterChangeResource = event => {
    this.setState({
      resourceItems: this.filterDataResource(event.filter)
    });
  };
  filterDataResource(filter) {
    const data = this.resourceAll.slice();
    return filterBy(data, filter);
  }

  filterCountries = event => {
    this.setState({
      countryItems: this.filterDataCountries(event.filter)
    });
  };
  filterDataCountries(filter) {
    const data = this.countryAll.slice();
    return filterBy(data, filter);
  }

  filterBillingDivData(filter) {
    const data = this.allBillingDivisions.slice();
    return filterBy(data, filter);
  }

  filterPracticeData(filter) {
    const data = this.allPracticeTypes.slice();
    return filterBy(data, filter);
  }

  filterEngagement = event => {
    this.setState({
      projectTypeItems: this.filterEngagementData(event.filter)
    });
  };
  filterEngagementData(filter) {
    const data = this.allEngagementTypes.slice();
    return filterBy(data, filter);
  }

  filterSalespersonData(filter) {
    const data = this.allSalesPersons.slice();
    return filterBy(data, filter);
  }

  filterBillingRegionData(filter) {
    const data = this.allBillingRegions.slice();
    return filterBy(data, filter);
  }
  filterBillingMethod = event => {
    this.setState({
      paymentmethodItems: this.filterBillingMethodData(event.filter)
    });
  };
  filterBillingMethodData(filter) {
    const data = this.allBillingMethods.slice();
    return filterBy(data, filter);
  }

  filterProjectPartnersData(filter) {
    const data = this.allProjectPartners.slice();
    return filterBy(data, filter);
  }

  filterProgramData(filter) {
    const data = this.allPrograms.slice();
    return filterBy(data, filter);
  }

  filterOfferings = event => {
    this.setState({
      offeringItems: this.filterOfferingData(event.filter)
    });
  };
  filterOfferingData(filter) {
    const data = this.allOfferings.slice();
    return filterBy(data, filter);
  }

  filterBillingEntities = event => {
    this.setState({
      billingEntityType: this.filterBillingEntityData(event.filter)
    });
  };
  filterBillingEntityData(filter) {
    const data = this.allBillingEntities.slice();
    return filterBy(data, filter);
  }

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  renderProjectDetails = project => {
    let programObj = null;
    let countryObj = null;
    let salespersonObj = null;
    let projpartnerObj = null;
    let offeringObj = null;
    let projPracticeObj = null;
    let primaryPMObj = null;

    if (project) {
      const statusObj = {
        StatusName: project.ProjectStatus.StatusName,
        id: project.ProjectStatus.id
      };

      /*const resourceObj = {
        Name: project.PresalesResource.Name,
        id: project.PresalesResource.id
      };*/

      const customerObj = {
        Name: project.Customer.Name,
        id: project.Customer.id
      };

      if (project.Program) {
        programObj = {
          Name: project.Program.Name,
          id: project.Program.id
        };
      }

      if (project.SalesPersonRecord) {
        salespersonObj = {
          Name: project.SalesPersonRecord.Name,
          id: project.SalesPersonRecord.id
        };
      }

      if (project.PartnerRecord) {
        projpartnerObj = {
          Name: project.PartnerRecord.Name,
          id: project.PartnerRecord.id
        };
      }

      const billingDiviObj = {
        Name: project.Division.Name,
        id: project.Division.id
      };

      const billingRegionObj = {
        Name: project.Region.Name,
        id: project.Region.id
      };

      const projectTypeObj = {
        Name: project.Type.Name,
        id: project.Type.id
      };

      const paymentmethodObj = {
        Name: project.PaymentMethodRecord.Name,
        id: project.PaymentMethodRecord.id
      };

      if (project.Country) {
        countryObj = {
          Name: project.Country.Name,
          id: project.Country.id
        };
      }

      if (project.Offering) {
        offeringObj = {
          Name: project.Offering.Name,
          id: project.Offering.id
        };
      }

      if (project.ProjectPractice) {
        projPracticeObj = {
          Name: project.ProjectPractice.Name,
          id: project.ProjectPractice.id
        };
      }

      if (project.PrimaryPM) {
        primaryPMObj = {
          Name: project.PrimaryManager.Name,
          id: project.PrimaryManager.id
        };
      }
      
      this.setState({
        projectname: project.ProjectName,
        projdescription: project.ProjectDescription,
        startdate: project.StartDate ? reStructureDate(project.StartDate ) : null,
        statusValue: statusObj,
        prevStatus: statusObj.id,
        billablestatus: project.BillableStatus,
        cusnameValue: customerObj,
        prognameValue: programObj,
        salespersValue: salespersonObj,
        projpartnerValue: projpartnerObj,
        billingdiviValue: billingDiviObj,
        billingregionValue: billingRegionObj,
        projtypeValue: projectTypeObj,
        paymethodValue: paymentmethodObj,
        resourceValue: null,
        primaryPM: primaryPMObj,
        opid: project.OPID,
        projectid: project.UniqueId,
        countryValue: countryObj,
        offeringValue: offeringObj,
        enddate: project.EndDate ? reStructureDate(project.EndDate) : null,
        endDateAugmentation: project.EndDate ? reStructureDate(project.EndDate) : null,
        endDateBlanketWork: project.EndDate ? reStructureDate(project.EndDate) : null,
        practiceValue: projPracticeObj,
        poNumber: project.PONumber,
        contractedEffort: project.ContractedEffort ? Number(project.ContractedEffort).toFixed(2) : '',
        billingEntityValue: project.LegalEntity ? project.LegalEntity : null 
      });

    }

    else {
      return null;
    }
  };

  updateProjectDetails = project => {
    if (project) {
      this.isEnableCreate = true;
      this.isEnableUpdate = false;
      let programObj = null;
      let salespersonObj = null;
      let countryObj = null;
      let projpartnerObj = null;
      let offeringObj = null;
      let projPracticeObj = null;
      let primaryPMObj = null;

      const statusObj = {
        StatusName: project.ProjectStatus.StatusName,
        id: project.ProjectStatus.id
      };

      /*const resourceObj = {
        Name: project.PresalesResource.Name,
        id: project.PresalesResource.id
      };*/

      const customerObj = {
        Name: project.Customer.Name,
        id: project.Customer.id
      };

      this.populatePrograms(project.Customer.id);

      if (project.Program) {
        programObj = {
          Name: project.Program.Name,
          id: project.Program.id
        };
      }

      if (project.SalesPersonRecord) {
        salespersonObj = {
          Name: project.SalesPersonRecord.Name,
          id: project.SalesPersonRecord.id
        };
      }

      if (project.Country) {
        countryObj = {
          Name: project.Country.Name,
          id: project.Country.id
        };
      }

      if (project.PartnerRecord) {
        projpartnerObj = {
          Name: project.PartnerRecord.Name,
          id: project.PartnerRecord.id
        };
      }

      const billingDiviObj = {
        Name: project.Division.Name,
        id: project.Division.id
      };

      const billingRegionObj = {
        Name: project.Region.Name,
        id: project.Region.id,
        Country: project.Region.Country,
        Currency: project.Region.Currency,
        CurrencyId: project.Region.Currency.id
      };

      this.populateCountries(project.Region.Country);
      this.countryAll = project.Region.Country;

      const projectTypeObj = {
        Name: project.Type.Name,
        id: project.Type.id
      };

      const paymentmethodObj = {
        Name: project.PaymentMethodRecord.Name,
        id: project.PaymentMethodRecord.id
      };

      if (project.Offering) {
        offeringObj = {
          Name: project.Offering.Name,
          id: project.Offering.id
        };
      }

      if (project.ProjectPractice) {
        projPracticeObj = {
          Name: project.ProjectPractice.Name,
          id: project.ProjectPractice.id
        };
      }

      if (project.PrimaryPM) {
        primaryPMObj = {
          Name: project.PrimaryManager.Name,
          id: project.PrimaryManager.id
        };
      }

      //const startDateMin = project.StartDate ? new Date(project.StartDate) : new Date();
      this.setState({
        projectname: project.ProjectName,
        projdescription: project.ProjectDescription,
        startdate: project.StartDate ? reStructureDate(project.StartDate) : null,

        startDateMin: (project.ProjectStatus.Code === 'BILLABLE' ||
          project.ProjectStatus.Code === 'WORK_AT_RISK') ? new Date() : new Date('1970-01-01Z00:00:00:000'),
        statusValue: statusObj,
        prevStatus: statusObj.id,
        cusnameValue: customerObj,
        prognameValue: programObj,
        salespersValue: salespersonObj,
        billablestatus: project.BillableStatus,
        projpartnerValue: projpartnerObj,
        billingdiviValue: billingDiviObj,
        billingregionValue: billingRegionObj,
        projtypeValue: projectTypeObj,
        paymethodValue: paymentmethodObj,
        resourceValue: null,
        primaryPM: primaryPMObj,
        opid: project.OPID,
        projectid: project.UniqueId,
        countryValue: countryObj,
        offeringValue: offeringObj,
        practiceValue: projPracticeObj,
        poNumber: project.PONumber,
        contractedEffort: project.ContractedEffort ? Number(project.ContractedEffort).toFixed(2) : '',
        billingEntityValue: project.LegalEntity ? project.LegalEntity : null
      });

      if(this.state.permissions.endDateAugmentation === true){

        this.setState({
          endDateAugmentation: project.EndDate ? new Date(project.EndDate) : null,
        })
        }
      else if(this.state.permissions.endDateBlanketWork === true){
        this.setState({
          endDateBlanketWork: project.EndDate ? new Date(project.EndDate) : null,
        })
      }
      else{
        this.setState({
          enddate: project.EndDate ? new Date(project.EndDate) : null,
        })
      }

    } else {
      return null;
    }
  };

  populatePrograms = customerId => {
    if (customerId) {
      getCustomerPrograms(customerId)
        .then(res => {
          this.allPrograms = res.data;
          if (res) {
            this.setState({
              programItems: res.data
            });
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    } else {
      this.setState({
        programItems: []
      });
    }
  };

  populateCountries = countries => {
    this.setState({
      countryItems: countries
    });
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleWarningDialog = (message, title) => {
    this.setState({
      showMessageDialog: !this.state.showMessageDialog,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleCancelConfirmDialog = () => {
    this.setState({
      showCancelConfirmation: !this.state.showCancelConfirmation
    });
  };

  //change events for datepickers and text inputs
  handleProjNameChange = event => {
    this.setState({
      projectname: event.target.value,
      isFormDirty: true
    });
  };
  handleProjDescChange = event => {
    this.setState({
      projdescription: event.target.value,
      isFormDirty: true
    });
  };

  handleStartdateChange = event => {
    let startDate = event.target.value;

    if (startDate) {
      let endDate = reStructureDate(startDate.valueOf())
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(endDate.getDate() - 1);
      this.state.enddate = endDate;
    }

    this.setState({
      startdate: startDate,
      isFormDirty: true
    });

    if (startDate) {
      this.setState({
        showStartDateValidationError: false
      });
    }
  };

  handleEnddateChange = async event => {

    if(this.state.projtypeValue && this.state.projtypeValue.Name === 'Resource Augmentation'){
      let endDateAug = event.target.value;

      await this.setState({
        endDateAugmentation: endDateAug,
        isFormDirty: true
      });

      if (endDateAug) {
        this.setState({
          showEndDateValidationError: false
        });
      }
    }
    else if(this.state.projtypeValue && this.state.projtypeValue.Name === 'Blanket Work Order'){
      let endDateblanket = event.target.value;

      await this.setState({
        endDateBlanketWork: endDateblanket,
        isFormDirty: true
      });

      if (endDateblanket) {
        this.setState({
          showEndDateValidationError: false
        });
      }
    }
    else{
      let endDate = event.target.value;

      await this.setState({
        enddate: endDate,
        isFormDirty: true
      });

      if (endDate) {
        this.setState({
          showEndDateValidationError: false
        });
      }
    }
  };

  handleProjTypeChange = event => {
    let projtypeValue = event.target.value;

    this.setState({
      projtypeValue: projtypeValue,
      isFormDirty: true
    });

    //for resource augmentation
    if(projtypeValue && projtypeValue.Name === 'Resource Augmentation'){
      this.state.permissions.endDateAugmentation = true;
          this.setState({
            resourceAug:true,
            showStartDateValidationError: false
          });
    }
    else{
      this.state.permissions.endDateAugmentation = false;
    }

    //for blanket work order projects
    if(projtypeValue && projtypeValue.Name === 'Blanket Work Order'){
      this.state.permissions.endDateBlanketWork = true;
      this.setState({
        isBlanketWorkOrder:true,
        showStartDateValidationError: false
      });
    }
    else{
      this.state.permissions.endDateBlanketWork = false;
    }

    if (projtypeValue && this.state.billingdiviValue) {
      this.setState({
        showStartDateValidationError: false
      });
      if (projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) {
        this.state.permissions.endDate = true;
        this.state.isAps = true;
      }else{
        this.state.isAps = false;
        this.state.showStartDateValidationError = false;
        this.state.showEndDateValidationError = false;
      }
    } else {
      this.state.permissions.endDate = false;
    }
  };

  handleOnChange = (event) => {
    const value = event.target.value;
    const field = event.target.name;

    switch (field) {
      case 'customer': {
        this.setState({
          cusnameValue: value,
          programItems: [],
          prognameValue: null
        });
        if (value) {
          this.populatePrograms(value.id);
        } else {
          this.setState({
            programItems: [],
            prognameValue: null,
          });
        }
        break;
      }
      case 'program': {
        this.setState({
          prognameValue: value
        });
        break;
      }
      case 'opid': {
        this.setState({
          opid: value,
        });
        break;
      }
      case 'poNumber': {
        this.setState({
          poNumber: value,
        });
        break;
      }
      case 'contractedEffort': {
        if (value >= 0) {
          this.setState({
            contractedEffort: value,
          });
        } else {
          this.setState({
            contractedEffort: '',
          });
        }
        break;
      }
      default: {
      }
    }
    this.setState({
      isFormDirty: true
    });
  };

  handleFilterChange = (event) => {
    const value = event.target.value;
    const field = event.target.name;

    switch (field) {
      case 'customer': {
        this.setState({
          customerItems: this.filterData(event.filter)
        });
        break;
      }
      case 'program': {
        this.setState({
          programItems: this.filterProgramData(event.filter)
        });
        break;
      }

    }

    this.setState({
      isFormDirty: true
    });
  };





  handleStatusChange = event => {
    // 1 - Presales - PRESALES
    // 2 - Waiting Confirmation - WAIT_CONFIRM
    // 3 - Closed-Opportunity Loss - CLOSE_OP_LOST
    // 4 - On Hold - ON_HOLD
    // 5 - Working At Risk - WORK_AT_RISK
    // 6 - Billable - BILLABLE
    // 7 - Billable with CR - BILLABALE_CR
    // 8 - Closed - CLOSED
    // 9 - Close without bidding - CLOSE_WITHOUT_BID


    if (event.target.value) {
      if (event.target.value.Code === 'BILLABLE' || event.target.value.Code === 'WORK_AT_RISK') {
        this.setState({ startDateMin: new Date(), showStartDateValidationError: false });
      }
      else {
        this.setState({ startDateMin: new Date('1970-01-01Z00:00:00:000'), showStartDateValidationError: false });
      }
      if (this.state.action === 'edit') {
        // const currentProjectStatusId = this.state.project.ProjectStatus.id;
        const currentProjectStatusCode = this.state.project.ProjectStatus.Code;
        const cmSheets = this.state.project.CMSheets;
        let hasApprovedCMS = false;
        for (const cmSheet of cmSheets) {
          if (cmSheet.Status === 'APPROVED') {
            hasApprovedCMS = true;
            break;
          }
        }

        const selectedStatusCode = event.target.value.Code;
        const selectedStatusName = event.target.value.StatusName;

        if (
          selectedStatusCode === 'PRESALES' &&
          !(
            currentProjectStatusCode === 'PRESALES' ||
            currentProjectStatusCode === 'WAIT_CONFIRM' ||
            currentProjectStatusCode === 'ON_HOLD'
          )
        ) {
          this.toggleDialog(
            'Project status cannot be changed to ' + selectedStatusName + ' at current state',
            'Error'
          );
          return;
        }

        // When there is an approved CMS, status can be changed to Waiting Confirmation or Closed-Opportunity Loss
        if (
          (selectedStatusCode === 'WAIT_CONFIRM' || selectedStatusCode === 'CLOSE_OP_LOST') &&
          !hasApprovedCMS
        ) {
          this.toggleDialog(
            'Project status cannot be changed to ' + selectedStatusName + ' at current state',
            'Error'
          );
          return;
        }

        // status can be changed to Close without bidding before converting to a Billable or Working at Risk status
        if (
          selectedStatusCode === 'CLOSE_WITHOUT_BID' &&
          (currentProjectStatusCode === 'WORK_AT_RISK' ||
            currentProjectStatusCode === 'BILLABLE' ||
            currentProjectStatusCode === 'BILLABALE_CR')
        ) {
          this.toggleDialog(
            'Project status cannot be changed to ' + selectedStatusName + ' at current state',
            'Error'
          );
          return;
        }

        // Any project in Presales, Waiting Confirmation, On Hold with an Approved CMS can be changed to Working At Risk
        if (
          selectedStatusCode === 'WORK_AT_RISK' &&
          (!(
            currentProjectStatusCode === 'PRESALES' ||
            currentProjectStatusCode === 'WAIT_CONFIRM' ||
            currentProjectStatusCode === 'ON_HOLD' ||
            currentProjectStatusCode === 'WORK_AT_RISK'
          ) ||
            !hasApprovedCMS)
        ) {
          this.toggleDialog(
            'Project status cannot be changed to ' + selectedStatusName + ' at current state',
            'Error'
          );
          return;
        }

        // Any project in Presales, Waiting Confirmation, On Hold or Working at Risk with an Approved CMS can be changed to Billable
        if (
          selectedStatusCode === 'BILLABLE' &&
          (!(
            currentProjectStatusCode === 'PRESALES' ||
            currentProjectStatusCode === 'WAIT_CONFIRM' ||
            currentProjectStatusCode === 'ON_HOLD' ||
            currentProjectStatusCode === 'WORK_AT_RISK' ||
            currentProjectStatusCode === 'BILLABLE'
          ) ||
            !hasApprovedCMS)
        ) {
          this.toggleDialog(
            'Project status cannot be changed to ' + selectedStatusName + ' at current state',
            'Error'
          );
          return;
        }

        // Billable with CR is an internal status change, not a user change
        if (selectedStatusCode === 'BILLABALE_CR' && currentProjectStatusCode !== 'BILLABALE_CR') {
          this.toggleDialog(
            'Project status cannot be changed to ' + selectedStatusName + ' at current state',
            'Error'
          );
          return;
        }

        // Any Billable, Working at Risk or On Hold project can be converted to Closed
        if (
          selectedStatusCode === 'CLOSED' &&
          !(
            currentProjectStatusCode === 'WORK_AT_RISK' ||
            currentProjectStatusCode === 'BILLABLE' ||
            currentProjectStatusCode === 'ON_HOLD'
          )
        ) {
          this.toggleDialog(
            'Project status cannot be changed to ' + selectedStatusName + ' at current state',
            'Error'
          );
          return;
        }

        if ((selectedStatusCode === 'BILLABLE' || selectedStatusCode === 'WORK_AT_RISK')) {
          if (this.state.startdate == null) {
            this.setState(
              {
                showStartDateValidationError: true
              });
          }
        }

        if (currentProjectStatusCode === 'ON_HOLD' && (selectedStatusCode === 'BILLABLE' || selectedStatusCode === 'WORK_AT_RISK')) {
          const permissions = Object.assign({}, this.state.permissions);
          permissions['description'] = true;
          this.setState({
            permissions: permissions
          });
        }
      }
    }

    this.setState({
      statusValue: event.target.value,
      isFormDirty: true
    });


  };

  handlePaymentChange = event => {
    this.setState({
      paymethodValue: event.target.value,
      isFormDirty: true
    });
  };

  handleResourceChange = event => {
    this.setState({
      resourceValue: event.target.value,
      isFormDirty: true
    });
  };

  handlePrimaryPMChange = event => {
    this.setState({
      primaryPM: event.target.value,
      isFormDirty: true
    });
  };

  handleOfferingChange = event => {
    const value = event.target.value;
    this.setState({
      offeringValue: value,
      isFormDirty: true
    });
  };

  handleBillingEntityChange = event => {
    const value = event.target.value;
    this.setState({
      billingEntityValue: value,
      isFormDirty: true
    });
  };

  handleBillableOnChange = async (event) => {
    const value = event.target.value;
    const field = event.target.name;

    switch (field) {
      case 'billale': {
        if (value === 'billable') {
          this.setState({
            billablestatus: true
          });
        } else {
          this.setState({
            billablestatus: false
          });
        }
        break;
      }
      case 'salesPerson': {
        this.setState({
          salespersValue: value
        });
        break;
      }
      case 'partner': {
        this.setState({
          projpartnerValue: value
        });
        break;
      }
      case 'billingdivi': {
        await this.setState({
          billingdiviValue: value
        });
        if (value && this.state.projtypeValue) {
          this.setState({
            showEndDateValidationError: false
          });
          if (this.state.projtypeValue.Name === "Application Support" && value.id === 30) {
            this.state.permissions.endDate = true;
            this.state.isAps = true;
          }else{
            this.state.isAps = false;
            this.state.showStartDateValidationError = false;
            this.state.showEndDateValidationError = false;
          }
        } else {
          this.state.permissions.endDate = false;
        }
        if (event && event.target && event.target.value) {
          this.practiceDivisionMapping();
          await this.setState({ 
            practiceValue: null
          });
        } else {
          await this.setState({ 
            practiceValue: null
          });
        }
        break;
      }
      case 'billingregion': {
        if (value) {
          this.populateCountries(value.Country);
          this.countryAll = value.Country;
        } else {
          this.populateCountries([]);
          this.countryAll = [];
        }
        this.setState({
          billingregionValue: value,
          countryValue: null
        });
        break;
      }
      case 'country': {
        this.setState({
          countryValue: value
        });
        break;
      }
      case 'selectedPractice': {
        this.setState({
          practiceValue: value
        });
        break;
      }
      default: {
      }
    }
    this.setState({
      isFormDirty: true
    });
  }

  practiceDivisionMapping = async () => {
    if (this.state.billingdiviValue) {
        let practices = [];
        let practicesForDivision = this.state.practiceDivisionMap.filter(item => item.DepartmentCodeSF === this.state.billingdiviValue.SFCode);
        for (const practice of practicesForDivision) {
            let selectedPractice = this.allPracticeTypes.filter(item => item.SFCode === practice.PracticeCodeSF);
            practices.push(selectedPractice[0]);
        }
        await this.setState({ practiceItems: practices })
    }
  };

  handleBillableFilterChange = (event) => {
    const value = event.target.value;
    const field = event.target.name;

    switch (field) {
      case 'salesPerson': {
        this.setState({
          salespersonItems: this.filterSalespersonData(event.filter)
        });
        break;
      }
      case 'partner': {
        this.setState({
          projparnterItems: this.filterProjectPartnersData(event.filter)
        });
        break;
      }
      case 'partners': {
        this.setState({
          projparnterItems: this.filterProjectPartnersData(event.filter)
        });
        break;
      }
      case 'billingdivi': {
        this.setState({
          billingdiviItems: this.filterBillingDivData(event.filter)
        });
        break;
      }
      case 'billingregion': {
        this.setState({
          billingregionItems: this.filterBillingRegionData(event.filter)
        });
        break;
      }
      case 'country': {
        this.setState({
          countryItems: this.filterDataCountries(event.filter)
        });
        break;
      }
      case 'selectedPractice': {
        this.setState({
          practiceItems: this.filterPracticeData(event.filter)
        });
        break;
      }
    }

    this.setState({
      isFormDirty: true
    });
  }

  handleUpdateProj = (resAllocations, allocComment) => {
    this.setState({
      preSalesResourceAllocations: resAllocations,
      allocComment: allocComment
    });
  };

  handleUpdateMilestones = (milestones) => {
    this.setState({
      milestones: milestones
    });
  };

  handleUpdateOwnerComment = (allocComment) => {
    this.setState({allocComment})
  }

  handleSelect = (e) => {
    this.setState({ selected: e.selected });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.state.action === 'view') {
      return;
    }
    
    if((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")){

      this.state.resourceAug = true;
      this.state.showStartDateValidationError = true;
      this.state.showEndDateValidationError = true;
    }
    else
    if((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order")){
      this.state.isBlanketWorkOrder = true;
      this.state.showStartDateValidationError = true;
      this.state.showEndDateValidationError = true;
    }
    else if ((this.state.projtypeValue && this.state.projtypeValue.Name === "Application Support") && (this.state.billingdiviValue && this.state.billingdiviValue.id === 30)) {
      this.state.isAps = true;
      this.state.showStartDateValidationError = true;
      this.state.showEndDateValidationError = true;
    }else{
      this.state.isAps = false;
      this.state.resourceAug = false;
      this.state.isBlanketWorkOrder = false;
      this.state.showStartDateValidationError = false;
      this.state.showEndDateValidationError = false;
    }

    if (this.state.statusValue && (this.state.statusValue.Code === 'BILLABLE' || this.state.statusValue.Code === 'WORK_AT_RISK')) {
      if (this.state.startdate == null) {
        this.setState(
          {
            visible: true,
            showStartDateValidationError: true,
            showSuccessImage: false,
            showErrorImage: true,
          });
        this.toggleDialog('The project start date cannot be empty', 'Error');
        return;
      }
    }

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if(this.state.statusValue && (this.state.statusValue.Code === 'BILLABLE' || this.state.statusValue.Code === 'WORK_AT_RISK'))
    {
      if (this.state.prevStatus !== 5 ){
        if (this.state.startdate && new Date(this.state.startdate) < today) {
          this.setState({ 
            visible: true, 
            showSuccessImage: false,
            showErrorImage: true, 
          });
          this.toggleDialog('The project start date cannot be a past date!', 'Error');
          return;
        }
      }
    }

    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true
      });
      this.setState({ 
        visible: true, 
        showSuccessImage: false,
        showErrorImage: true, 
      });
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else if (this.allocationValidation()){
      this.setState({
        isErrorMessageVisible: true
      });
      this.setState({ 
        visible: true, 
        showSuccessImage: false,
        showErrorImage: true, 
      });
      this.toggleDialog('Please fill mandatory fields in resource mix to continue', 'Error');
    } else if (this.submitForApproval()){
      this.setState({
        isErrorMessageVisible: true
      });
      this.setState({ 
        visible: true, 
        showSuccessImage: false,
        showErrorImage: true, 
      });
      this.toggleDialog('Invalid line selection', 'Error');

    } else if (this.milestonesValidation()){
      this.setState({
        isErrorMessageVisible: true
      });
      this.setState({ 
        visible: true, 
        showSuccessImage: false,
        showErrorImage: true, 
      });
      this.toggleDialog('Please fill mandatory fields in milestones to continue', 'Error');

    }else {
      this.setState({
        isErrorMessageVisible: false
      });
      if (this.state.action === 'edit') {
        this.setState(
          {
            isDisableSave: true
          },
          () => {
            this.updateProject(event);
          }
        );


      } else { //create
            //check for manager select
            let managerSelected = false;
           for(const allocation of this.state.preSalesResourceAllocations){
              if(allocation.dataValueCostDesignation.Name.toLowerCase().includes("manager") && allocation.selected === true){
                managerSelected = true;
              }
            }
           if(managerSelected === true){
             if (!this.state.billablestatus && !this.state.showNbWarning) {
              this.setState({
                showNbWarning: true
              });
              this.toggleWarningDialog('The Billing type of the Project/CR is selected as Non-billable. Please proceed with Project Creation if this is intended.', 'Note');
             } else {
              this.setState({
                isDisableSave: true
              });
              this.createProject(event);
             }
           }
           else{
             this.setState({
               isErrorMessageVisible: true
             });
             this.setState({ 
              visible: true, 
              showSuccessImage: false,
              showErrorImage: true, 
            });
             this.toggleDialog('At least one PM resource request should be selected to continue', 'Error');
           }
      }
    }
  };

  allocationValidation = () => {
    for(const eachAllocation of this.state.preSalesResourceAllocations){
      if (eachAllocation.Designation === null || eachAllocation.dataValueResourceRegion === null || eachAllocation.StartDate === null || eachAllocation.EndDate === null || eachAllocation.AllocationPercentage === null || eachAllocation.AllocationPercentage === 0 || !eachAllocation.OnsiteStatus){
        return true;
        break;
      }
    }
  }

  submitForApproval = () => {
    const selectedResources = this.state.preSalesResourceAllocations.filter(resource => resource.selected === true);
    if (selectedResources && selectedResources.length > 0) {
      if(this.state.statusValue.StatusName !== 'Presales'){
        return true;
      }
      for(const resource of selectedResources){
        if(resource.Status !== 'NOT_SUBMITTED'){
          return true;
          break;
        }
      }
    }
  }

  milestonesValidation = () => {
    if (this.state.milestones && this.state.milestones.length > 0) {
      for(const milestone of this.state.milestones){
        if (!milestone.Name || milestone.StartDate === null ||  milestone.EndDate === null || milestone.Revenue === null){
          return true;
        }
      }  
    } else {
      return false;
    }
  }

  validation = () => {

    if (
      this.validateProperty(this.state.cusnameValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.opid)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.countryValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.projectname)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.statusValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.billingdiviValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.billingregionValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.projtypeValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.paymethodValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } /*else if (
      this.validateProperty(this.state.resourceValue)
        .toString()
        .includes('error')
    ) {
      return false;
    }*/
    else if (
      this.validateProperty(this.state.primaryPM)
        .toString()
        .includes('error')
    ) {
      return false;
    }
    else if ((this.state.isAps || this.state.resourceAug || this.state.isBlanketWorkOrder) &&
      this.validateProperty(this.state.startdate)
        .toString()
        .includes('error')
    ) {
      return false;
    }
    else if ((this.state.isAps && !this.state.resourceAug && !this.state.isBlanketWorkOrder) &&
      this.validateProperty(this.state.enddate)
        .toString()
        .includes('error')
    ) {
      return false;
    }
    else if ((this.state.resourceAug && !this.state.isAps && !this.state.isBlanketWorkOrder) &&
        this.validateProperty(this.state.endDateAugmentation)
            .toString()
            .includes('error')
    ) {
      return false;
    }
    else if ((!this.state.resourceAug && !this.state.isAps && this.state.isBlanketWorkOrder) &&
        this.validateProperty(this.state.endDateBlanketWork)
            .toString()
            .includes('error')
    ) {
      return false;
    }
    else if (
      this.validateProperty(this.state.offeringValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.projdescription)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  createProject = event => {

    let endDate = null

    if (this.state.isAps) {
      endDate = this.state.enddate ? this.state.enddate.toDateString() : null;
    }
    else if(this.state.resourceAug){
      endDate = this.state.endDateAugmentation ? this.state.endDateAugmentation.toDateString() : null;
    }
    else if(this.state.isBlanketWorkOrder){
      endDate = this.state.endDateBlanketWork ? this.state.endDateBlanketWork.toDateString() : null;
    }

    const project = {
      ProjectName: this.state.projectname.trim(),
      Status: this.state.statusValue.id,
      StatusName: this.state.statusValue.Code,
      prevStatus: this.state.prevStatus,
      StartDate: this.state.startdate ? this.state.startdate.toDateString() : null,
      EndDate: endDate,
      ProjectDescription: this.state.projdescription,
      BillableStatus: this.state.billablestatus,
      BillingDivision: this.state.billingdiviValue.id,
      BillingRegion: this.state.billingregionValue.id,
      ProjectType: this.state.projtypeValue.id,
      PaymentMethod: this.state.paymethodValue.id,
      SalesPerson: this.state.salespersValue ? this.state.salespersValue.id : null,
      Partner: this.state.projpartnerValue ? this.state.projpartnerValue.id : null,
      CustomerId: this.state.cusnameValue.id,
      OPID: this.state.opid.trim(),
      ProgramId: this.state.prognameValue ? this.state.prognameValue.id : null,
      //ResourceId: this.state.resourceValue.id,
      PrimaryPM: this.state.primaryPM ? this.state.primaryPM.id : null,
      ResourceId: null,
      CountryId: this.state.countryValue.id,
      OfferingId: this.state.offeringValue.id,
      IsPoolProject:false,
      PracticeId: this.state.practiceValue ? this.state.practiceValue.id : null,
      PONumber: this.state.poNumber ? this.state.poNumber.trim() : null,
      ContractedEffort: this.state.contractedEffort ? Number(this.state.contractedEffort).toFixed(2) : null,
      BillingEntityId: this.state.billingEntityValue ? this.state.billingEntityValue.id : null
    };

    saveProject(project)
      .then( async res => {
          var allocationsToSave = [];
          let allocPendingLines = [];
          for(const eachAllocation of this.state.preSalesResourceAllocations){
            const AllocationObj = {
              CMSheetId: res.data.CMSheets ? res.data.CMSheets[0].id : null,
              Type: 'Project',
              ProjectId: res.data.id,
              Status: eachAllocation.selected === true ? 'RES_ALLOC_PENDING' : eachAllocation.Status,
              Comment: (eachAllocation.selected === true && this.state.allocComment) ? this.state.allocComment.replace(/(<([^>]+)>)/ig, '') : (eachAllocation.Comment ? eachAllocation.Comment : null),
              CMSheetVersion: res.data.CMSheets ? res.data.CMSheets[0].Version : null,
              Designation: eachAllocation.Designation,
              ResourceRegion: eachAllocation.ResourceRegion,
              EstimatedEndDate: eachAllocation.EstimatedEndDate ? eachAllocation.EstimatedEndDate.toDateString() : null,
              StartDate: eachAllocation.StartDate ? eachAllocation.StartDate.toDateString() : null,
              EndDate: eachAllocation.EndDate ? eachAllocation.EndDate.toDateString() : null,
              AllocationPercentage: eachAllocation.AllocationPercentage,
              EffortDays: eachAllocation.EffortDays,
              TotalEffortDays: eachAllocation.TotalEffortDays,
              IsPreSales: eachAllocation.IsPreSales,
              IsActive: eachAllocation.IsActive,
              ProjectName: res.data.ProjectName,
              UniqueId: res.data.UniqueId,
              selected: eachAllocation.selected,
              CostPerResource: Number(eachAllocation.CostPerResource),
              DirectCost: Number(eachAllocation.DirectCost),
              TotalCost: Number(eachAllocation.TotalCost),
              ManagerComment: eachAllocation.ManagerComment,
              OnsiteStatus: eachAllocation.OnsiteStatus
            };
            allocationsToSave.push(AllocationObj);
            if (eachAllocation.selected === true) {
              allocPendingLines.push(AllocationObj);
            }
          }
          await saveResourceRequests(allocationsToSave)
            .then(
            async saveRes => {
              if (allocPendingLines.length > 0) {
                await savePresalesResourceLineHistory(allocPendingLines)
              }
            }
          ).catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
              this.toggleDialog(error.response.data, 'Error');
            });

          // Save Milestones
          if (this.state.paymethodValue.id !== 2 && this.state.milestones && this.state.milestones.length > 0) {
            for(const milestone of this.state.milestones) {
              milestone.ProjectId = res.data.id;
              milestone.CrId = null;
              milestone.StartDate = moment(new Date(milestone.StartDate)).format('YYYY-MM-DD');
              milestone.EndDate = moment(new Date(milestone.EndDate)).format('YYYY-MM-DD');
              milestone.Revenue = Number(Number(milestone.Revenue).toFixed(2));
              milestone.IsActive = true;
              milestone.FileId = milestone.FileId ? milestone.FileId : null
            }
            await saveMilestones(this.state.milestones).then(saveRes => {
            }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleDialog(error.response.data, 'Error');
            });  
          }
          this.setState({
            showSuccessImage: true,
            showErrorImage: false,
          });
          this.toggleDialog(`The project ${res.data.UniqueId} is successfully created`, 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleDialog(error.response.data, 'Error');
        this.setState({
          isDisableSave: false
        });
      });
  };

  updateProject = event => {
    if (!this.state.startdate && (this.state.statusValue.Code === 'BILLABLE' || this.state.statusValue.Code === 'WORK_AT_RISK')) {
      this.setState({
        showStartDateValidationError: true,
        isDisableSave: false
      });
      return;
    }

    this.updateAfterValidation();
  };

  updateAfterValidation = () => {

    let endDate = null

    if (this.state.isAps) {
      endDate = this.state.enddate ? this.state.enddate.toDateString() : null;
    }
    else if(this.state.resourceAug){
      endDate = this.state.endDateAugmentation ? this.state.endDateAugmentation.toDateString() : null;
    }
    else if(this.state.isBlanketWorkOrder){
      endDate = this.state.endDateBlanketWork ? this.state.endDateBlanketWork.toDateString() : null;
    }

    let startDateOnProjectConversionToBillable = null;
    if (this.state.statusValue.Code === 'BILLABLE' || this.state.statusValue.Code === 'WORK_AT_RISK') {
      startDateOnProjectConversionToBillable = this.state.startdate ? new Date(this.state.startdate) : null;
      startDateOnProjectConversionToBillable = startDateOnProjectConversionToBillable ? moment(startDateOnProjectConversionToBillable).format('YYYY-MM-DD') : null
    }
    const project = {
      ProjectName: this.state.projectname.trim(),
      Status: this.state.statusValue.id,
      prevStatus: this.state.prevStatus,
      StatusName: this.state.statusValue.Code,
      StartDate: this.state.startdate ? this.state.startdate.toDateString() : null,
      ProjectConversionStartDate: startDateOnProjectConversionToBillable,
      EndDate: endDate,
      ProjectDescription: this.state.projdescription,
      BillableStatus: this.state.billablestatus,
      BillingDivision: this.state.billingdiviValue.id,
      BillingRegion: this.state.billingregionValue.id,
      ProjectType: this.state.projtypeValue.id,
      PaymentMethod: this.state.paymethodValue.id,
      SalesPerson: this.state.salespersValue ? this.state.salespersValue.id : null,
      Partner: this.state.projpartnerValue ? this.state.projpartnerValue.id : null,
      CustomerId: this.state.cusnameValue.id,
      OPID: this.state.opid.trim(),
      ProgramId: this.state.prognameValue ? this.state.prognameValue.id : null,
      ResourceId: null,
      PrimaryPM: this.state.primaryPM ? this.state.primaryPM.id : null,
      CountryId: this.state.countryValue.id,
      OfferingId: this.state.offeringValue.id,
      IsPoolProject:false,
      PracticeId: this.state.practiceValue ? this.state.practiceValue.id : null,
      PONumber: this.state.poNumber ? this.state.poNumber.trim() : null,
      ContractedEffort: this.state.contractedEffort ? Number(this.state.contractedEffort).toFixed(2) : null,
      BillingEntityId: this.state.billingEntityValue ? this.state.billingEntityValue.id : null
      };

    updateProject(project, this.state.project.id)
      .then(async res => {
        var allocationsToSave = [];
        let allocPendingLines = [];
        for(const eachAllocation of this.state.preSalesResourceAllocations){
          const AllocationObj = {
            id : eachAllocation.id,
            CMSheetId: res.data.CMSheets[0] ? res.data.CMSheets[0].id : null,
            Type: 'Project',
            ProjectId: res.data.id,
            Status: eachAllocation.selected === true ? 'RES_ALLOC_PENDING' : eachAllocation.Status,
            Comment: (eachAllocation.selected === true && this.state.allocComment) ? this.state.allocComment.replace(/(<([^>]+)>)/ig, '') : (eachAllocation.Comment ? eachAllocation.Comment : null),
            CMSheetVersion: res.data.CMSheets[0] ? res.data.CMSheets[0].Version : null,
            Designation: eachAllocation.Designation,
            ResourceRegion: eachAllocation.ResourceRegion,
            EstimatedEndDate: eachAllocation.EstimatedEndDate ? eachAllocation.EstimatedEndDate.toDateString() : null,
            StartDate: eachAllocation.StartDate ? eachAllocation.StartDate.toDateString() : null,
            EndDate: eachAllocation.EndDate ? eachAllocation.EndDate.toDateString() : null,
            AllocationPercentage: eachAllocation.AllocationPercentage,
            EffortDays: eachAllocation.EffortDays,
            TotalEffortDays: eachAllocation.TotalEffortDays,
            IsPreSales: eachAllocation.IsPreSales,
            IsActive: eachAllocation.IsActive,
            ProjectName: res.data.ProjectName,
            UniqueId: res.data.UniqueId,
            selected: eachAllocation.selected,
            CostPerResource: Number(eachAllocation.CostPerResource),
            DirectCost: Number(eachAllocation.DirectCost),
            TotalCost: Number(eachAllocation.TotalCost),
            ManagerComment: eachAllocation.ManagerComment,
            OnsiteStatus: eachAllocation.OnsiteStatus
          };
          allocationsToSave.push(AllocationObj);
          if (eachAllocation.selected === true) {
            allocPendingLines.push(AllocationObj);
          }
        }
        await saveResourceRequests(allocationsToSave).
        then(async saveRes => {
          if (allocPendingLines.length > 0) {
            await savePresalesResourceLineHistory(allocPendingLines)
          }
        })
          .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.toggleDialog('Error occured while saving pre sales resource request', 'Error');
            this.setState({
              isDisableSave: false
            });
        });

        // Save Milestones
        if (this.state.paymethodValue.id !== 2 && this.state.milestones && this.state.milestones.length > 0) {
          for(const milestone of this.state.milestones){
            milestone.ProjectId = res.data.id;
            milestone.CrId = null;
            milestone.StartDate = moment(new Date(milestone.StartDate)).format('YYYY-MM-DD');
            milestone.EndDate = moment(new Date(milestone.EndDate)).format('YYYY-MM-DD');
            milestone.Revenue = Number(Number(milestone.Revenue).toFixed(2));
            milestone.IsActive = true;
            milestone.FileId = milestone.FileId ? milestone.FileId : null
          }
          await saveMilestones(this.state.milestones).then(saveRes => {
          }).catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
              this.toggleDialog(error.response.data, 'Error');
          });  
        }

        this.setState({
          showSuccessImage: true,
          showErrorImage: false,
        });
        this.toggleDialog('The project has been successfully updated', 'Success');
        
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleDialog(error.response.data, 'Error');
        this.setState({
          isDisableSave: false
        });
      });
  };

  onClickCancel = event => {
    event.preventDefault();
    if (this.state.isFormDirty) {
      this.toggleConfirmDialog(
        'This form contains unsaved data. Do you want to close it?',
        this.navigateBack
      );
    } else {
      this.navigateBack();
    }
  };

  reDirectToDashboard = event => {
    this.setState({
      toDashboard: true
    });
  };

  navigateBack = () => {
    this.navigateToSearchScreen();
  };

  navigateToSearchScreen = event => {
    this.setState({
      toSearchScreen: true
    });
  };

  toggleConfirmDialog = async (message, action) => {
    await this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
      confirmDialogMessage: message,
      confirmDialogAction: action
    });
  };

  render() {
    const classes = 'tooltip-inner';
    if (this.state.toDashboard === true) {
      return <Redirect to="/" />;
    }
    if (this.state.toSearchScreen === true) {
      return <Redirect to="/projects/search/search" />;
    }
    if (this.state.redirectToUnatuhorizedPage === true) {
      return <Redirect to="/NotPermitted" />;
    }
    return (
      <div className="main-card">
        <Loader loading={this.state.isLoading} />
        <CustomerDetails
          customerItems={this.state.customerItems}
          customerValue={this.state.cusnameValue}
          programItems={this.state.programItems}
          programValue={this.state.prognameValue}
          opidValue={this.state.opid}
          poNumber={this.state.poNumber}
          billingDivisionValue={this.state.billingdiviValue}
          contractedEffort={this.state.contractedEffort}
          handleFilterChange={this.handleFilterChange}
          handleOnChange={this.handleOnChange}
          viewMode={this.state.isViewMode}
          action={this.state.action}
          projectIdValue={this.state.projectid}
          permissions={this.state.permissions}
          allowOpidEdit={this.state.allowOpidEdit}
          oldOpIdHistory={this.state.oldOpIdHistory}
          onClickOpidHistory={this.onClickOpidHistory}
          errorMessageVisible={this.state.isErrorMessageVisible} >
        </CustomerDetails>

        <div className="main-seperator" />
        <div className="main-heading">Project Details</div>
        <div className="row">
          <div className="col-md-2">
            <div className="d-block">
              <label htmlFor="" className="mandatory">
                Project Name:
                </label>
            </div>
            <div className="d-block">
              <div className="" id="projnameToolTip">
                <Input
                  autoComplete="off"
                  type="text"
                  name="projectname"
                  value={this.state.projectname}
                  onChange={this.handleProjNameChange}
                  required={true}
                  validityStyles={this.state.projectname.touched}
                  disabled={this.state.isViewMode || !this.state.permissions.projectName}
                  maxLength="100"
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.projectname)}>
                    Please enter a project name
                  </span>
                ) : null}
              </div>
              {this.state.projectname ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.projnameToolTipOpen}
                  target="projnameToolTip"
                  toggle={this.toggleProjNameToolTip}
                >
                  {this.state.projectname}
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="col-md-1"></div>
          <div className="col-md-2">
            <div className="d-block">
              {((this.state.projtypeValue && this.state.billingdiviValue) && this.state.projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) || (this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order") ? (
                <div className="mandatory">
                  <label htmlFor="">Start Date:</label>
                </div>

              ) :

                (
                  <div className="">
                    <label htmlFor="">Start Date:</label>
                  </div>
                )}
            </div>
            <div className="d-block">
              <div className="title-remove">
                {((((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) && (this.state.endDateAugmentation !== null)) || (((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order")) && (this.state.endDateBlanketWork !== null))) ?
                    (
                        <DatePicker
                            width="100"
                            name="startdate"
                            onChange={this.handleStartdateChange}
                            value={reStructureDate(this.state.startdate)}
                            min={(this.state.creatingAProject === true || this.state.action === 'edit') ? new Date() : this.state.startDateMin}
                            disabled={this.state.isViewMode || !this.state.permissions.startDate}
                            format="MM/dd/yyyy"
                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                            required={(((this.state.projtypeValue && this.state.billingdiviValue) && this.state.projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order"))) ? true : false}
                            max={(this.state.endDateAugmentation ? this.state.endDateAugmentation : this.state.endDateBlanketWork)}
                        />
                    ) : (
                        <DatePicker
                            width="100"
                            name="startdate"
                            onChange={this.handleStartdateChange}
                            value={reStructureDate(this.state.startdate)}
                            min={this.state.creatingAProject === true || this.state.action === 'edit' ? new Date() : this.state.startDateMin}
                            disabled={this.state.isViewMode || !this.state.permissions.startDate}
                            format="MM/dd/yyyy"
                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                            required={(((this.state.projtypeValue && this.state.billingdiviValue) && this.state.projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order"))) ? true : false}
                        />
                    )}

                {this.state.showStartDateValidationError === true
                  ? (
                    <span className={this.validateProperty(this.state.startdate)}>
                      Please enter a start date
                  </span>
                  ) : null}
              </div>
            </div>
          </div>

          {(((this.state.projtypeValue && this.state.billingdiviValue) && this.state.projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) || (this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order")) && (
            <>
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <div className="d-block">
                  <div className="">
                    <label htmlFor="" className="mandatory">End Date:</label>
                  </div>
                </div>
                <div className="d-block">
                  <div className="">
                    <DatePicker
                      width="100"
                      name="enddate"
                      onChange={this.handleEnddateChange}
                      value={this.state.projtypeValue.Name === 'Resource Augmentation' ? this.state.endDateAugmentation : (this.state.projtypeValue.Name === "Blanket Work Order" ? this.state.endDateBlanketWork : this.state.enddate)}
                      min={((this.state.projtypeValue.Name === 'Resource Augmentation' && this.state.startdate) || (this.state.projtypeValue.Name === "Blanket Work Order" && this.state.startdate)) ? this.state.startdate : (this.state.creatingAProject === true ? this.state.startdate ? this.state.startdate : new Date() : this.state.startDateMin)}
                      disabled={this.state.projtypeValue.Name === 'Resource Augmentation' ? !this.state.permissions.endDateAugmentation : (this.state.projtypeValue.Name === "Blanket Work Order" ? !this.state.permissions.endDateBlanketWork : this.state.isViewMode || !this.state.permissions.endDate)}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      required={true}
                    />
                    {this.state.showEndDateValidationError === true  ? (
                      <span className={this.validateProperty(this.state.permissions.endDate === true ? this.state.enddate : (this.state.permissions.endDateAugmentation === true ? this.state.endDateAugmentation : (this.state.permissions.endDateBlanketWork === true ? this.state.endDateBlanketWork :null)))}>
                        Please enter a end date
                  </span>
                    ) : null}
                  </div>
                </div>
              </div>
              </>
          )}

          { this.state.project && (this.state.action && (this.state.action === 'edit' || this.state.action === 'view') && (this.state.projtypeValue && this.state.billingdiviValue) &&  (!((this.state.projtypeValue && this.state.projtypeValue.Name === "Application Support") && this.state.billingdiviValue.id === 30) && (this.state.projtypeValue && this.state.projtypeValue.Name !== "Resource Augmentation") && (this.state.projtypeValue && this.state.projtypeValue.Name !== "Blanket Work Order"))) && (
                        <>
                        <div className="col-md-1"></div>
                        <div className="col-md-2">
                          <div className="d-block">
                            <div className="">
                              <label htmlFor="">End Date:</label>
                            </div>
                          </div>
                          <div className="d-block">
                          <div className="">
                            <DatePicker
                              width="70"
                              name="enddate"
                              value={this.state.project.UserDefinedEndDate
                                ? reStructureDate(this.state.project.UserDefinedEndDate)
                                : this.state.project.EndDate ?
                                reStructureDate(this.state.project.EndDate)
                                : this.state.project.EstimatedEndDate ? 
                                reStructureDate(this.state.project.EstimatedEndDate)
                                : null
                              }
                              disabled={true}
                              format="MM/dd/yyyy"
                              formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                            />
                          </div>
                          </div>
                        </div>
                        </>
          )}

          <div className="col-md-1"></div>

          <div className="col-md-2">
            <div className="d-block">
              <label htmlFor="" className="mandatory">
                Status:
                </label>
            </div>
            <div className="d-block">
              <div className="" id="statusToolTip">
                <ComboBox
                  data={this.state.statusItems}
                  textField="StatusName"
                  dataItemKey="id"
                  onChange={this.handleStatusChange}
                  value={this.state.statusValue}
                  required={true}
                  disabled={
                    this.state.isViewMode ||
                    this.state.isEditMode ||
                    !this.state.permissions.status ||
                    (window.USER_PERMISSION &&
                      window.USER_PERMISSION.indexOf('CHG_PRJ_STAT') === -1)
                  }
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.statusValue)}>
                    Please select a status
                  </span>
                ) : null}
              </div>
              {this.state.statusValue ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.statusToolTipOpen}
                  target="statusToolTip"
                  toggle={this.toggleStatusToolTip}
                >
                  {this.state.statusValue.StatusName}
                </Tooltip>
              ) : null}
            </div>
          </div>
        </div>
        <div className="d-block">
          <label htmlFor="" className="mandatory">Project Description:</label>
        </div>
        <div className="d-block">
          <div className="">
            <ReactQuill
              className={this.state.projdescription == '' ? "quil-required" : ""}
              name="projectname"
              value={this.state.projdescription}
              onChange={this.handleChange}
              readOnly={this.state.onHoldStatusBool || this.state.isViewMode}
            />
            {/* this.state.onHoldStatusBool || this.state.isViewMode */}
            {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.projdescription)}>
                    Please enter the project description
                  </span>
                ) : null}

          </div>
        </div>

        <div className="main-seperator" />

        <BillingDetails
          billableStatus={this.state.billablestatus}
          salesPersonItems={this.state.salespersonItems}
          salesPersonValue={this.state.salespersValue}
          projParnterItems={this.state.projparnterItems}
          projPartnerValue={this.state.projpartnerValue}
          billingDiviItems={this.state.billingdiviItems}
          billingDiviValue={this.state.billingdiviValue}
          practiceItems={this.state.practiceItems}
          practiceValue={this.state.practiceValue}
          billingRegionItems={this.state.billingregionItems}
          billingRegionValue={this.state.billingregionValue}
          countryItems={this.state.countryItems}
          countryValue={this.state.countryValue}
          projectTypeItems={this.state.projectTypeItems}
          projTypeValue={this.state.projtypeValue}
          handleBillableOnChange={this.handleBillableOnChange}
          handleBillableFilterChange={this.handleBillableFilterChange}
          permissions={this.state.permissions}
          viewMode={this.state.isViewMode}
          action={this.state.action}
          errorMessageVisible={this.state.isErrorMessageVisible} >
        </BillingDetails>

        <div className="row billable-mt">
          <div className="col-md-2">
            <div className="d-block">
              <label htmlFor="" className="mandatory">
                Engagement Type:
                </label>
            </div>
            <div className="d-block">
              <div className="" id="engagementToolTip">
                <ComboBox
                  data={this.state.projectTypeItems}
                  textField="Name"
                  dataItemKey="id"
                  placeholder="Please Select"
                  value={this.state.projtypeValue}
                  onChange={this.handleProjTypeChange}
                  required={true}
                  disabled={this.state.isViewMode || this.state.action === 'edit'}
                  filterable={true}
                  onFilterChange={this.filterEngagement}
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.projtypeValue)}>
                    Please select an engagement type
                  </span>
                ) : null}
              </div>
              {this.state.projtypeValue ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.engagementToolTipOpen}
                  target="engagementToolTip"
                  toggle={this.toggleEngagementToolTip}
                >
                  {this.state.projtypeValue.Name}
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="col-md-2">
            <div className="d-block">
              <label htmlFor="" className="mandatory">
                {' '}
                Billing Method:
                </label>
            </div>
            <div className="d-block">
              <div className="" id="billmethodToolTip">
                <ComboBox
                  data={this.state.paymentmethodItems}
                  textField="Name"
                  dataItemKey="id"
                  placeholder="Please Select"
                  value={this.state.paymethodValue}
                  onChange={this.handlePaymentChange}
                  required={true}
                  disabled={this.state.isViewMode || !this.state.permissions.billingMethod}
                  filterable={true}
                  onFilterChange={this.filterBillingMethod}
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.paymethodValue)}>
                    Please select a billing method
                  </span>
                ) : null}
              </div>
              {this.state.paymethodValue ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.billMethodToolTipOpen}
                  target="billmethodToolTip"
                  toggle={this.toggleBillMethodToolTip}
                >
                  {this.state.paymethodValue.Name}
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="col-md-2">
            <div className="d-block">
              <label className="mandatory">
                Offering:
                </label>
            </div>
            <div className="d-block">
              <div className="" id="offeringToolTip">
                <ComboBox
                  data={this.state.offeringItems}
                  textField="Name"
                  dataItemKey="id"
                  placeholder="Please Select"
                  value={this.state.offeringValue}
                  onChange={this.handleOfferingChange}
                  required={true}
                  disabled={this.state.isViewMode || !this.state.permissions.offering}
                  filterable={true}
                  onFilterChange={this.filterOfferings}
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.offeringValue)}>
                    Please select an offering
                  </span>
                ) : null}
              </div>
              {this.state.offeringValue ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.offeringToolTipOpen}
                  target="offeringToolTip"
                  toggle={this.toggleOfferingToolTip}
                >
                  {this.state.offeringValue.Name}
                </Tooltip>
              ) : null}
            </div>
          </div>
          <div className="col-md-2">
            <div className="d-block">
              <label className="">
                Billing Entity:
              </label>
            </div>
            <div className="d-block">
              <div className="" id="billingEntityToolTip">
                <ComboBox
                  data={this.state.billingEntityType}
                  textField="Name"
                  dataItemKey="id"
                  placeholder="Please Select"
                  value={this.state.billingEntityValue}
                  onChange={this.handleBillingEntityChange}
                  disabled={this.state.isViewMode}
                  filterable={true}
                  onFilterChange={this.filterBillingEntities}
                />
              </div>
              {this.state.billingEntityValue ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.billingEntityToolTipOpen}
                  target="billingEntityToolTip"
                  toggle={this.toggleBillingEntityToolTip}
                >
                  {this.state.billingEntityValue.Name}
                </Tooltip>
              ) : null}
            </div>
          </div>
        </div>
        <div className="main-seperator" />
      <TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
        <TabStripTab title="Pre-sales Resource Request">
        <ResourceRequest
          resourceItems={this.state.resourceItems}
          allocComment={this.state.allocComment}
          resourceValue={this.state.resourceValue}
          handleResourceChange={this.handleResourceChange}
          handlePrimaryPMChange={this.handlePrimaryPMChange}
          primaryPM={this.state.primaryPM}
          filterChangeResource={this.filterChangeResource}
          viewMode={this.state.isViewMode}
          isEditMode={this.state.isEditMode}
          isDisableComment={this.state.viewMode || (this.state.statusValue ? (this.state.statusValue !== null ?this.state.statusValue.StatusName !== 'Presales' : false) : false)}
          statusValue={this.state.statusValue}
          countryValue={this.state.countryValue}
          billingregionValue={this.state.billingregionValue}
          isAPS={this.state.isAPS}
          action={this.state.action}
          project={this.state.changeRequest ? this.state.changeRequest : this.state.project}
          startdate={this.state.startdate}
          onUpdateProj={this.handleUpdateProj}
          handleUpdateOwnerComment={this.handleUpdateOwnerComment}
          preSalesResourceAllocations={this.state.preSalesResourceAllocations}
          Type={'Project'}
          errorMessageVisible={this.state.isErrorMessageVisible} >
        </ResourceRequest>
        </TabStripTab>
        <TabStripTab title="Milestones" 
            disabled={(this.state.paymethodValue == null || this.state.paymethodValue.id === 2) ? true : false }
        >
          <Milestones
            viewMode={this.state.isViewMode}
            isEditMode={this.state.isEditMode}
            action={this.state.action}
            project={this.state.changeRequest ? this.state.changeRequest : this.state.project}
            Type={'Project'}
            onUpdateMilestones={this.handleUpdateMilestones} 
            milestones={this.state.milestones}
          >
          </Milestones>
        </TabStripTab>
          </TabStrip>
        <div className="row mt-3">
          <div className="col-md-12 btn-align-right">
            <Button
              type="submit"
              primary={true}
              disabled={this.state.isViewMode || this.state.isDisableSave}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button type="button" onClick={this.onClickCancel}>
              Cancel
            </Button>
          </div>
        </div>
        {this.state.visible === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.state.dialogTitle === 'Error' ? this.toggleDialog : this.reDirectToDashboard} width="300px">
            {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success"/>
                </div>
            )}
            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image"/>
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <DialogActionsBar>
              <button
                className="k-button modal-primary"
                onClick={
                  this.state.dialogTitle === 'Error' ? this.toggleDialog : this.reDirectToDashboard
                }
              >
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleWarningDialog} width="300px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <DialogActionsBar>
              <button
                className="k-button modal-primary"
                onClick={this.toggleWarningDialog}
              >
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.visibleOpidHistory && (
          <Dialog width={600} title={"OPID Change History"} onClose={this.onClickOpidHistory}>
            <div className="row">
              <div className="col-md-12">
                <Grid
                  data={this.state.oldOpIdHistory}
                  style={{ height: '100px' }}
                >
                  <Column field="OPID" title="OPID" width="150px"/>
                  <Column field="UpdatedUser" title="User" width="150px"/>
                  <Column field="createdAt" title="Updated Date" />
                </Grid>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.onClickOpidHistory}>Close</button>
            </DialogActionsBar>
          </Dialog>
        )}

        <Loader loading={this.state.loading}/>
      </div>
    );
  }
}

export default CreateProject;
