import React, { Component } from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy } from '@progress/kendo-data-query';
import { getRegionsIntegrated, getcustomersIntegrated, getBillingDiviDropDownIntegrated, getPracticeTypesIntegrated, } from '../../integration/MasterDataService';
import { getProjectStatuses } from '../../projectManagement/project/ProjectService';
import { getAllProjectsData, searchProjectMarginRecords, getLoggedUserProfile, getProjectMarginRecordsExcel } from './ProjectProfitabilityService';
import ProjectProfitabilityExcel from './ProjectProfitabilityExcel';
import Loader from '../../integration/components/Loader';
import { Link } from 'react-router-dom';
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import * as loggerService from "../../integration/LoggerService";

class ProjectProfitability extends Component {
    _export;

    isMount = false;

    constructor(props) {
        super(props);

        this.state = {

            projectAndCRs: [],
            projectAndCRsAll: [],
            selectedProjectName: [],
            region: [],
            regionAll: [],
            selectedRegion: [],
            validRegions: [],
            customers: [],
            customersAll: [],
            selectedCustomer: [],
            department: [],
            departmentAll: [],
            selectedDepartment: [],
            practice: [],
            practiceAll: [],
            validDepartment: [],
            selectedPractice: [],
            projectStatuses: [],
            projectStatusesAll: [],
            selectedStatus: [],
            projects: [],
            projectMarginsExcel: [],
            reportDate: null,
            excelDownload: false,
            userRoleAllowed: false,
            showSuccessImage: false,
            showErrorImage: false,
            skip: 0,
            take: 20,
            sort: [
                { field: 'updatedAt', dir: 'desc' }
            ],
            loading: false
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.props.onHeaderTitleChange('Project Profitability');
        this.setInitialData();
    }

    setInitialData = async() => {
        await this.setState({
            loading: true
        });
        await this.populateRegions();
        await this.populateDepartments();
        await this.populatePractices();
        await this.validateRegions();
        await this.validateDepartments();
        await this.populateCustomers();
        await this.populateAllProjectsData();
        await this.populateProjectStatuses();
        await this.submitSearch(); 
        await this.setState({
            loading: false
        });
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    validateDepartments = async () => {
        if (!this.state.userRoleAllowed) {
            const userRoles = window.LOGGED_USER.roles;
            const practiceHead = userRoles.some(obj => obj.Rolename === 'Practice Head');

            if (practiceHead) {
                let resourceDepartment = null;
                await getLoggedUserProfile().then(res => {
                    if (res.data) {
                        resourceDepartment = res.data.Practice
                    }
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

                if (resourceDepartment) {
                    const validDepartment = this.state.departmentAll.filter(x => x.id === resourceDepartment);    
                    await this.setState({
                        department: validDepartment,
                        validDepartment: validDepartment,
                        selectedDepartment: validDepartment
                    });    
                }    
            }
        }
    }

    validateRegions = async() => {
        let validRegions = [];
        const userPermissions = window.USER_PERMISSION;

        const globalPermission= userPermissions.some(obj => obj === 'GLOBAL_PRJ_MGN');
        if (globalPermission) {
            await this.setState({
                userRoleAllowed: true
            });
        } else {
            const asiaPermission= userPermissions.some(obj => obj === 'AMEA_PRJ_MGN');
            if (asiaPermission) {
                const asiaRegios = this.state.regionAll.filter(x => x.id === 3 || x.id === 5 || x.id === 18 || x.id === 19);
                validRegions = validRegions.concat(asiaRegios);
            }
    
            const anzPermission = userPermissions.some(obj => obj === 'ANZ_PRJ_MGN');
            if (anzPermission) {
                const anzRegios = this.state.regionAll.filter(x => x.id === 4);
                validRegions = validRegions.concat(anzRegios);
            }
    
            const ukPermission = userPermissions.some(obj => obj === 'UK_EUROPE_PRJ_MGN');
            if (ukPermission) {
                const ukRegios = this.state.regionAll.filter(x => x.id === 1 ||  x.id === 21);
                validRegions = validRegions.concat(ukRegios);
            }
    
            const usPermission = userPermissions.some(obj => obj === 'AMERICAS_PRJ_MGN');
            if (usPermission) {
                const usRegios = this.state.regionAll.filter(x => x.id === 2);
                validRegions = validRegions.concat(usRegios);
            }
    
            if (validRegions && validRegions.length > 0) {
                await this.setState({
                    region: validRegions,
                    validRegions: validRegions,
                    selectedRegion: validRegions
                });
            }    
        }
    };

    populateRegions = async() => {
        await getRegionsIntegrated()
            .then(res => {
            if (this.isMount) {
                this.setState({
                    region: res.data,
                    regionAll: res.data
                });
            }
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    populateAllProjectsData = async() => {
        const fields = {};
        fields.AllAccess = this.state.userRoleAllowed;
        fields.ValidRegions = this.state.validRegions.map(value => value.id);;
        fields.ValidDepartment = this.state.validDepartment.map(value => value.id);;
        await getAllProjectsData(fields)
            .then(res => {  
                if (this.isMount) {
                    this.setState({
                    projectAndCRs: res.data,
                    projectAndCRsAll: res.data,
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };
    
    populateCustomers = async() => {
        await getcustomersIntegrated()
            .then(res => {
                if (this.isMount) {
                this.setState({
                    customers: res.data,
                    customersAll: res.data
                });
                }
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    populateDepartments = async () => {
        await getBillingDiviDropDownIntegrated().then(response => {
            const divisions = response.data.filter(el => el.IsActive === true && el.SFCode !== null)
            this.setState({
                department: divisions,
                departmentAll: divisions,
            });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }

    populatePractices = async () => {
        await getPracticeTypesIntegrated().then(response => {
            this.setState({
                practice: response.data,
                practiceAll: response.data
            });
            })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }

    populateProjectStatuses = async() => {
        await getProjectStatuses()
            .then(res => {
                const projectStatuses = res.data.filter(item => item.id === 6 || item.id === 5 || item.id === 8 || item.id === 11 || item.id === 13 || item.id === 14);
                if (this.isMount) {
                    this.setState({
                        projectStatuses: projectStatuses,
                        projectStatusesAll: projectStatuses,
                        selectedStatus: projectStatuses.filter(item => item.id === 6 || item.id === 5)
                    });
                }
            })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };
    
    filterData(filter, allData) {
        const data = allData.slice();
        return filterBy(data, filter);
    }

    filterChangeProjectName = event => {
        if (this.isMount) {
            this.setState({
                projectAndCRs: this.filterData(event.filter, this.state.projectAndCRsAll)
            });
        }
    };

    filterChangeRegion = event => {
        if (this.isMount) {
            this.setState({
                region: this.filterData(event.filter, this.state.regionAll)
            });
        }
    };

    filterChangeCustomer = event => {
        if (this.isMount) {
            this.setState({
                customers: this.filterData(event.filter, this.state.customersAll)
            });
        }
    };

    filterChangeDepartment = event => {
        if (this.isMount) {
            this.setState({
                department: this.filterData(event.filter, this.state.departmentAll)
            });
        }
    };

    filterChangePractice = event => {
        if (this.isMount) {
            this.setState({
                practice: this.filterData(event.filter, this.state.practiceAll)
            });
        }
    };

    filterChangeStatus = event => {
        if (this.isMount) {
            this.setState({
                projectStatuses: this.filterData(event.filter, this.state.projectStatusesAll)
            });
        }
    };

    handleInputChange = event => {
        const valueObj = event.target.value;
        const field = event.target.name;
        if (this.isMount) {
        this.setState({
            [field]: valueObj
        });
        }
    };

    pageChange = event => {
        if (this.isMount) {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        }, () => this.submitSearch(null));
        }
    };

    submitSearch = async(event) => {
        if (event) {
            event.preventDefault();
            this.setState({
                skip: 0,
                take: 20
            });        
        } 

        if (this.isMount) {
            await this.setState({
                loading: true
            });
        }
        const fields = {
            Projects: this.state.selectedProjectName && this.state.selectedProjectName.length > 0 ? this.state.selectedProjectName : this.state.projectAndCRsAll,
            Region: this.state.selectedRegion,
            Customer: this.state.selectedCustomer,
            Department: this.state.selectedDepartment,
            Practice: this.state.selectedPractice,
            Status: this.state.selectedStatus
        };

        fields.count = 0;
        fields.skip = event ? 0 : this.state.skip;
        fields.take = event ? 20 : this.state.take;
        fields.sort = event || this.state.sort.length === 0 ? [{ field: 'updatedAt', dir: 'desc' }] : this.state.sort;

        if (fields.Projects && fields.Projects.length > 0) {
            await searchProjectMarginRecords(fields)
            .then(res => {
                if (this.isMount) {
                    this.setState({
                        projects: res.data,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        }   
        await this.setState({
            loading: false,
        });
    }

    exportExcel = async (event) => {
        await this.setState({
            loading: true
        });
        const fields = {
            Projects: this.state.selectedProjectName && this.state.selectedProjectName.length > 0 ? this.state.selectedProjectName : this.state.projectAndCRsAll,
            Region: this.state.selectedRegion,
            Customer: this.state.selectedCustomer,
            Department: this.state.selectedDepartment,
            Practice: this.state.selectedPractice,
            Status: this.state.selectedStatus
        };
        if (fields.Projects && fields.Projects.length > 0) {
            await getProjectMarginRecordsExcel(fields)
            .then(res => {
                if (this.isMount) {
                    this.setState({
                        projectMarginsExcel: res.data.rows,
                        reportDate: res.data.ReportDate,
                        loading: false,
                        excelDownload: true
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        }   
        await this.setState({
            loading: false,
        });
    }

    cancelSearch = event => {
        event.preventDefault();
        if (this.isMount) {
        this.setState({
            selectedProjectName: [],
            selectedRegion: [],
            selectedCustomer: [],
            selectedDepartment: null,
            selectedPractice: null,
            selectedStatus: []
        });
        }    
    };

    render() {
        return (
            <div className="main-card">
                <div className="row">
                    <div className="col-md-8">
                        <div className="main-heading">Project Profitability</div>
                    </div>
                </div>
                <form onSubmit={this.submitSearch}>
                
                    <div className="row">

                        <div className="col-md-2">
                            <div className="d-block">
                                <div className=""><label>Project Name:</label></div>
                            </div>
                            <div className="d-block">
                                <div className="">
                                <MultiSelect
                                    className="text-break"
                                    name="selectedProjectName"
                                    textField="DisplayName"
                                    value={this.state.selectedProjectName}
                                    data={this.state.projectAndCRs}
                                    placeholder={'Please Select'}
                                    filterable={true}
                                    onFilterChange={this.filterChangeProjectName}
                                    onChange={this.handleInputChange} 
                                />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="d-block">
                                <div className=""><label>Region:</label></div>
                            </div>
                            <div className="d-block">
                                <div className="">
                                <MultiSelect
                                    className="text-break"
                                    name="selectedRegion"
                                    textField="Name"
                                    value={this.state.selectedRegion}
                                    data={this.state.region}
                                    placeholder={'Please Select'}
                                    filterable={true}
                                    onFilterChange={this.filterChangeRegion}
                                    onChange={this.handleInputChange} 
                                />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="d-block">
                                <div className=""><label>Customer:</label></div>
                            </div>
                            <div className="d-block">
                                <div className="">
                                <MultiSelect
                                    name="selectedCustomer"
                                    textField="Name"
                                    value={this.state.selectedCustomer}
                                    data={this.state.customers}
                                    placeholder={'Please Select'}
                                    filterable={true}
                                    onFilterChange={this.filterChangeCustomer}
                                    onChange={this.handleInputChange}
                                />   
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="d-block">
                                <div className=""><label>Department:</label></div>
                            </div>
                            <div className="d-block">
                                <div className="">
                                <MultiSelect
                                    name="selectedDepartment"
                                    textField="Name"
                                    value={this.state.selectedDepartment}
                                    data={this.state.department}
                                    placeholder={'Please Select'}
                                    filterable={true}
                                    onFilterChange={this.filterChangeDepartment}
                                    onChange={this.handleInputChange}
                                />   
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="d-block">
                                <div className=""><label>Practice:</label></div>
                            </div>
                            <div className="d-block">
                                <div className="">
                                <MultiSelect
                                    name="selectedPractice"
                                    textField="Name"
                                    value={this.state.selectedPractice}
                                    data={this.state.practice}
                                    placeholder={'Please Select'}
                                    filterable={true}
                                    onFilterChange={this.filterChangePractice}
                                    onChange={this.handleInputChange}
                                />   
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="d-block">
                                <div className=""><label>Project Status:</label></div>
                            </div>
                            <div className="d-block">
                                <div className="">
                                <MultiSelect
                                    className="text-break"
                                    name="selectedStatus"
                                    textField="StatusName"
                                    value={this.state.selectedStatus}
                                    data={this.state.projectStatuses}
                                    placeholder={'Please Select'}
                                    filterable={true}
                                    onFilterChange={this.filterChangeStatus}
                                    onChange={this.handleInputChange} 
                                />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-12 btn-align-right">
                        <Button primary={true} type="submit" onClick={this.submitSearch}>
                            Search
                        </Button>
                        <Button type="button" onClick={this.cancelSearch}>
                            Clear
                        </Button>
                        </div>
                    </div>

                </form>

                <div className="main-seperator" />

                <div className="row">
                    <div className="col-md-6">
                        <div className="main-heading">Search Results</div>
                    </div>
                    <div className="col-md-6">
                        <div className="btn-align-right">
                            <Button primary className="pull-left" onClick={this.exportExcel} disabled={this.state.generateBtnDisable}>
                                {'Export to Excel'}
                            </Button>
                            {!this.state.isLoading && (
                                <ProjectProfitabilityExcel
                                    projects={this.state.projectMarginsExcel}
                                    reportDate={this.state.reportDate}
                                    updateData={this.state.excelDownload}
                                    dataUpdated={() => {
                                        this.setState({ excelDownload: false });
                                    }}
                                />
                            )}
                        </div>
                    </div> 
                </div>
                <div className="row">
                    <div className="col-md-12 profitability-grid">
                        <Grid
                            data={this.state.projects.rows}
                            skip={this.state.skip}
                            take={this.state.take}
                            total={this.state.projects.count}
                            pageable={true}
                            onPageChange={this.pageChange}
                            resizable
                            style={this.state.projects.rows && this.state.projects.rows.length > 10 ? { height: '500px' } : ''}
                        >
                            <Column 
                                field="UniqueId" 
                                title="Project ID" 
                                width="100px"
                                headerClassName="profitability-grid-borderFixed"
                                locked={true} 
                            />
                            <Column 
                                field="Customer" 
                                title="Customer" 
                                width="120px"
                                headerClassName="profitability-grid-borderFixed"
                                locked={true} 
                            />
                            <Column 
                                field="ProjectName" 
                                title="Project Name" 
                                width="130px"
                                headerClassName="profitability-grid-borderFixed"
                                locked={true} 
                            />
                            <Column
                                headerClassName="profitability-grid-border"
                                headerCell={() => (
                                    <div>
                                        <span className="profitability-grid-header">View 4</span><br />
                                    </div>
                            )}>
                            <Column 
                                headerClassName="profitability-grid-border"
                                headerCell={() => (
                                    <div>
                                        Cost = Past actuals + Future allocations<br />
                                        Revenue = Past actuals + Future allocations
                                    </div>
                                )}
                            >
                                <Column headerClassName="profitability-grid-border" field="TotalRevenueV4" title="Total Revenue" width="100px" className="profitability-grid-text-align"/>
                                <Column field="TotalCostV4" title="Total Cost" width="100px" className="profitability-grid-text-align"/>
                                <Column field="NetMarginPctV4" title="Net Margin %" width="100px" className="profitability-grid-text-align"/>
                                <Column field="NetMarginV4" title="Net Margin" width="100px" className="profitability-grid-text-align"/>
                            </Column>
                            </Column>
                            <Column
                                headerClassName="profitability-grid-border"
                                headerCell={() => (
                                    <div>
                                        <span className="profitability-grid-header">View 3</span><br />
                                    </div>
                            )}>
                            <Column 
                                headerClassName="profitability-grid-border"
                                headerCell={() => (
                                    <div>
                                        Cost = Past + Future allocations<br />
                                        Revenue = Past actuals + Future allocations
                                    </div>
                                )}
                            >
                                <Column headerClassName="profitability-grid-border" field="TotalRevenueV3" title="Total Revenue" width="100px" className="profitability-grid-text-align"/>
                                <Column field="TotalCostV3" title="Total Cost" width="100px" className="profitability-grid-text-align"/>
                                <Column field="NetMarginPctV3" title="Net Margin %" width="100px" className="profitability-grid-text-align"/>
                                <Column field="NetMarginV3" title="Net Margin" width="100px" className="profitability-grid-text-align"/>
                            </Column>
                            </Column>
                            <Column
                                headerClassName="profitability-grid-border"
                                headerCell={() => (
                                    <div>
                                        <span className="profitability-grid-header">View 2</span><br />
                                    </div>
                            )}>
                            <Column 
                                headerClassName="profitability-grid-border"
                                headerCell={() => (
                                    <div>
                                        Cost = Past + Future allocations<br />
                                        Revenue = Past + Future allocations
                                    </div>
                                )}
                            >
                                <Column headerClassName="profitability-grid-border" field="TotalRevenueV2" title="Total Revenue" width="100px" className="profitability-grid-text-align"/>
                                <Column field="TotalCostV2" title="Total Cost" width="100px" className="profitability-grid-text-align"/>
                                <Column field="NetMarginPctV2" title="Net Margin %" width="100px" className="profitability-grid-text-align"/>
                                <Column field="NetMarginV2" title="Net Margin" width="100px" className="profitability-grid-text-align"/>
                            </Column>
                            </Column>
                            <Column
                                headerClassName="profitability-grid-border"
                                headerCell={() => (
                                    <div>
                                        <span className="profitability-grid-header">View 1</span><br />
                                    </div>
                            )}>
                            <Column 
                                headerClassName="profitability-grid-border"
                                headerCell={() => (
                                    <div>
                                        Cost = based on resource estimation<br />
                                        Revenue = based on resource estimation
                                    </div>
                                )}
                            >
                                <Column headerClassName="profitability-grid-border" field="TotalRevenueV1" title="Total Revenue" width="100px" className="profitability-grid-text-align"/>
                                <Column field="TotalCostV1" title="Total Cost" width="100px" className="profitability-grid-text-align"/>
                                <Column field="NetMarginPctV1" title="Net Margin %" width="100px" className="profitability-grid-text-align"/>
                                <Column field="NetMarginV1" title="Net Margin" width="100px" className="profitability-grid-text-align"/>
                            </Column>
                            </Column>
                            <Column headerClassName="profitability-grid-border" field="Department" title="Department" width="100px"/>
                            <Column field="Practice" title="Practice" width="100px"/>
                            <Column field="BillingRegion" title="Billing Region" width="100px"/>
                            <Column field="ProjectManager" title="Project Manager" width="120px"/>
                            <Column field="ProjectStatus" title="Project Status" width="100px"/>
                            <Column field="CrCount" title="Active CRs" width="80px"/>
                            <Column 
                                field="Actions" 
                                title="Actions" 
                                width="80px"
                                cell={props => (
                                    <td className="action-buttons">
                                        <Link
                                            key={props.dataItem.Project.OPID}
                                            to={{
                                                pathname: '/ProjectResourceRequest/ProjectResourceRequest',
                                                action: 'view',
                                                project: props.dataItem.Project
                                            }}
                                        >
                                            <div className="rounded-link">
                                                <span className="k-icon k-i-preview" title="View" />
                                            </div>
                                            <br></br>
                                        </Link>
                                    </td>
                                )}
                            />
                        </Grid>
                    </div>
                </div>
                <Loader loading={this.state.loading} />
            </div>
        );
    }
}   

export default ProjectProfitability;



