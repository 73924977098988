
import React, { Component } from 'react';
import { MultiSelect, ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactQuill from "react-quill";
import { getPermissionList } from '../../userManagement/role/RoleService';
import TaskManagementCell from "./TaskManagementCell";
import { Redirect } from 'react-router';

import { Tooltip } from 'reactstrap';

import {
  getFactoryProjByRegionAndPractice,
  getProjectStatuses, updateLinkToFactory, updateLinkToFactoryCR
} from '../../projectManagement/project/ProjectService';

import {
  getPaymentMethods,
  getProjectTypes
} from '../../masterData/common/CommonService';
import { getRegionsIntegrated, getBillingDiviIntegrated, getCustomerProgramsIntegrated, getcustomersIntegrated, getHolidaysIntegrated } from '../../integration/MasterDataService';
import {
  getAllUniqueIds,
  searchProjectsNew,
  searchProjectsCR,
  searchProjectsPerforamnceFix,
  getRecentActiveProjects,
  getAllOPIDs,
  deleteProject, getCMSStatus, updateProject, saveStatusChange, saveStatusChangeHistory,
  saveActivityLog, getActivityLogData, getActivityLogDataSort, getApprovalsData, saveConversionStartdate
} from '../project/ProjectService';

import {
  deleteCMSheet,
  getCMSheetApprovals,
  getCmsTypes, getStatusChangeApprovals,
  setWorkflow,
  setWorkflowStatus,
  withdrawCMSheet,
  setReviseCMSWorkflow
} from '../cmSheet/CMSheetService';

import { getCRsOfAProject, deleteChangeReq, getAllCRUniqueIds } from '../changeRequest/ChangeRequestService';
import {
  getAllPMResources,
  getProjectResources,
  getAllocationPercentageHistory,
  updateAllocationPercentageHistory,
  getLeavesIntegrated,
  getLegalEntityTypes
} from "../../resourceManagement/ResourceService";

import ProjectCell from './ProjectCell';
import CMSheetCell from './CMSheetCell';
import CRDetailsCell from './CRDetailsCell';

import { PermissibleRender } from '@brainhubeu/react-permissible';
import CMSDeleteDialog from '../components/dialog/CMSDeleteDialog';
import Loader from '../../integration/components/Loader';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import ResourceRequestCell from './ResourceRequestCell';
import $ from "jquery";
import {getProjectOwners, getResourceRequestSubLines, deallocateRequestsFromStatusChange, getProjectOrCrResourceRequest} from "../../resourceManagement/ResourceService";
import UpdatedDateCell from "./UpdatedDateCell";
import { getAllDocumentTypesByStatus } from '../../masterData/documentType/DocumentTypeService';
import moment from 'moment';
import { uploadFile } from '../../integration/fileUpload/FileUploadService';
import DeleteCell from '../../resourceManagement/DeleteCell';
import StatusFileUploadCell from './StatusFileUploadCell';
import ActivityLogFilesCell from './ActivityLogFilesCell';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import {selectWorkflow, selectWorkflowStatus, selectReviseCMSRequestWorkflow} from "../workflow/WorkflowService";
import { saveAuditTrail } from '../../integration/auditTrail/AuditTrailService';
import ApprovalStatusCell from './ApprovalStatusCell';
import StatusChangeCommentCell from './StatusChangeCommentCell';
import { reStructureDate, getFormattedDate, isWeekend } from '../../integration/CommonUtils';
import { getCostDetails, getCostofAllocatedResource } from '../../masterData/costCard/CostCardService';
import { getAllPendingTimeEntriesForReviseCms } from '../../Timesheets/projectTaskManagement/ProjectTaskManagementService';


const apiUrl = require('../../config.js').get(process.env.REACT_APP_ENV).apiUrl;
const ProjStatuses = require('./StatusChangeEnums');
const Status = require('./StatusEnums');
const ApprovalStatusChange = require('./ApprovalStatusChangeEnums');

const resourceAll = [];
const CMSStatusAll = [];

class ProjectSearch extends Component {
  comboBoxPlaceHolder = 'Please Select';
  isMount = false;
  CROrProjectCurrentStatus = null;

  popupSet = {
    width: 'auto'
  };

  constructor(props) {
    super(props);
    this.state = {
      SearchRequest: {
        ProjectName: '',
        StartDate: null,
        StartDateBefore: null,
        Statuses: [],
        BillableStatus: false,
        NonBillableStatus: false,
        CustomerId: null,
        ProgramId: null,
        OPID: '',
        UniqueId: null,
        BillingDivision: [],
        BillingRegion: [],
        ProjectType: [],
        PaymentMethod: [],
        MyProjects: false,
        FactoryProjects: false,
        skip: 0,
        take: 15,
        sort:[{ field: 'updatedAt', dir: 'desc' }],
        projectIds:[],
        crCMS: null,
        project:null
      },

      cusToolTipOpen: false,
      countryToolTipOpen: false,
      programToolTipOpen: false,
      opidToolTipOpen: false,
      projnameToolTipOpen: false,
      projidToolTipOpen: false,
      statusToolTipOpen: false,
      salespersToolTipOpen: false,
      projpartnerToolTipOpen: false,
      practiceToolTipOpen: false,
      engagementToolTipOpen: false,
      billMethodToolTipOpen: false,
      billRegionToolTipOpen: false,
      projectManagerTooltipOpen: false,
      cmsToolTipOpen: false,
      cmsToolTipCROpen: false,
      projectId: null,
      requestType: null,
      Statuses: [],

      Customers: [],
      CustomersAll: [],
      Programs: [],
      ProgramsAll: [],
      Divisions: [],
      DivisionsAll: [],
      Regions: [],
      RegionsAll: [],
      ProjectTypes: [],
      ProjectTypesAll: [],
      PaymentMethods: [],
      PaymentMethodsAll: [],
      UniqueIds: [],
      UniqueIdsAll: [],
      opids: [],
      opidsAll: [],

      ProjectStatuses: [],
      Customer: null,
      Program: null,
      Division: [],
      Region: [],
      ProjectType: [],
      PaymentMethod: [],
      UniqueId: null,
      opidvalue: null,
      StartDate: null,
      StartDateBefore: null, 

      Projects: [],
      changeRequest: [],
      skip: 0,
      take: 15,

      showMessageDialog: false,
      showErrorMessageDialog: false,
      showSuccessImg: false,
      showErrorImg: false,
      dialogMessage: '',
      dialogTitle: '',

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      projectIdToDelete: null,
      projectNameToDelete: null,
      cmSheetIdToDelete: null,

      CRsOfAProject: [],
      showCRSelectDialog: false,
      showCRSelectDialogOne: false,
      selectedCRId: null,
      selectedCMSId: null,
      selectedCMSheet: null,
      selectedCMSReference: null,
      projectIdOfSelectedCMS: null,
      cmsAction: null,
      redirectToEditCR: false,
      redirectToEditViewCMS: false,
      ChangeRequestAction: '',
      redirectToCreateCMS: false,
      redirectToCreateCMSPool: false,
      redirectToEditCMS: false,
      redirectToEditCMSPool: false,
      redirectToResourceReq: false,
      loading: false,
      redirectToApproveCMS: false,
      selectedProject: '',
      IsPoolProject: false,
      key:'',
      id:'',
      // View CMS state
      projectCMS: [],
      changeRequestCMS: [],
      sort: [{ field: 'updatedAt', dir: 'desc' }],

      resourceItems: resourceAll.slice(),
      resourceValue: null,
      CMSStatus: [],
      CMSStatusAll: [],
      SelectedCMSStatus: [],
      UniqueCRIds: [],
      UniqueCRIdsAll: [],
      cmsTypes: [],
      selectedCmsType: [],
      changeStatusDialog: false,
      isACR: false,
      isAProj : false,

      // Status change validation
      invalidatePermission: true,
      statusChangeCr: null,
      statusValueLst: [],
      selectedChangeToStatus: null,
      projtypeValue: null,
      billingdiviValue: null,
      showStartDateValidationError: false,
      documentType: null,
      allDocumentTypesForStatus: [],
      documentTypesForDropdown: [],
      projectOrCr : null,
      toSearchPage : false,
      startdateStatus : null,
      currentStatus: [],

      RBtnCount: true,
      isErrorMessageVisible: false,
      initStatusLst: [],

      files: [],
      selectedFile: "",
      uploadedFileId: "",
      isCannotUpload: true,
      rowCount: 0,
      fileUploadDetails: [],
      deletedFileUploadDetails: [],
      selectedDocTypes: [],
      projMandatoryDocTypes: [],
      CRMandatoryDocTypes: [],
      DocUploadErrorMessageVisible: false,
      fileUploadMessageVisible: false,

      stsChangeComment: '',
      allActivityLogList: [],
      activityLogList: [],

      showCloseSubCategory: false,
      disableFileUpBtnDiv: false,
      disableCommentBoxDiv: false,
      showWorkflowSelectDialog: false,
      matchingWorkflowList: [],
      selectedWorkFlowId: null,
      activityLogId:null,
      statusChangeObj: {},
      isApprovalStatusPending: false,
      reqStatus: null,
      approvalsDetails: [],
      approversName: [],
      resourceReqSubLines: [],
      showStartDateErrorMessage: false,
      allResources:[],
      statusChangeGridDetails: [],
      selectedStatus: null,
      deallocatedMainLines: [],
      deallocatedSubLines: [],
      activeProjectManager: null,
      showCMSWithdrawDialog: false,
      withdrawReason: null,
      showRequestReviseCMSDialog: false,
      requestReviseCMSReason: null,
      showReviseCMSWorkflowSelectDialog: false,
      selectedProjectOrCR: null,
      isPONumberRequired: false,
      poNumber: null,
      showPONumberErrorMessage: false,
      disablePONumber: true,
      billingEntityType: [],
      billingEntityValue: null,
      showBillingEntityErrorMessage: false
    };
    this.toggleChangeStatusDialog = this.toggleChangeStatusDialog.bind(this);
  }

  toggleChangeStatusDialog() {
    this.setState({
      selectedChangeToStatus : null,
      documentType : null,
      changeStatusDialog: !this.state.changeStatusDialog,
      isErrorMessageVisible: false,
      fileUploadDetails: [],
      DocUploadErrorMessageVisible: false,
      fileUploadMessageVisible: false,
      allActivityLogList: [],
      activityLogList: [],
      showCloseSubCategory: false,
      disableFileUpBtnDiv: false,
      disableCommentBoxDiv: false,
      stsChangeComment: '',
      currentStatus: [],
      showStartDateValidationError: false,
      isApprovalStatusPending: false,
      reqStatus: null,
      approvalsDetails: [],
      approversName: [],
      allDocumentTypesForStatus: [],
      documentTypesForDropdown: [],
      showStartDateErrorMessage: false
    });

    this.CROrProjectCurrentStatus = null;
  }

  handleChangeStatusPopUp = async (dataItem) => {
    // validate the permissions
    this.statusChangePermission(dataItem, 'Project');
    const projectTypeObj = {
      Name: dataItem.Type.Name,
      id: dataItem.Type.id
    };

    const billingDiviObj = {
      Name: dataItem.Division.Name,
      id: dataItem.Division.id
    };

    this.setState({
      projtypeValue: projectTypeObj,
      billingdiviValue: billingDiviObj,
      endDateAugmentation: dataItem.EndDate ? new Date(dataItem.EndDate) : null,
      endDateBlanketWork: dataItem.EndDate ? new Date(dataItem.EndDate) : null,
      projectActivated: dataItem.StatusConvertedToActive,
      currentStatus: dataItem.ProjectStatus ? this.state.currentStatus.push(dataItem.ProjectStatus) : null,
      startdateStatus: reStructureDate(dataItem.StartDate),
      projectOrCr: dataItem,
      isPONumberRequired: dataItem.Customer && dataItem.Customer.PONumber ? true : false,
      poNumber: dataItem.PONumber ? dataItem.PONumber : "",
      billingEntityValue: dataItem.LegalEntity ? dataItem.LegalEntity : null
  });

    //start date - status change popup
    if(dataItem.StartDate === null){
      this.setState({
        startdateStatus: null
      });
    }

    // Open the change status popup
    this.toggleChangeStatusDialog();
  };

  handleWithdrawCMS = (project, projectCMS, crCMS) => {
    projectCMS = projectCMS.filter(v => v.data.CMSheets[0].Status === "PENDING_APPROVALS")
    crCMS = crCMS.filter(v => v.data.CMSheets[0].Status === "PENDING_APPROVALS")
    
    if (this.isMount) {
      this.setState(
        {
          projectIdOfSelectedCMS: project.id,
          projectCMS: projectCMS,
          project: project, 
          changeRequestCMS: crCMS,
          cmsAction: 'withdraw'
        },
        () => {
          if (projectCMS.length === 1 && crCMS.length === 0) {
            this.setSelectedCMSId(projectCMS[0].data.id, projectCMS[0].data, 'projectCMS');
            this.viewEditCMS();
            // this.populateSearchGirdData();
          } 
          else if (projectCMS.length === 0 && crCMS.length === 1) {
            this.setSelectedCMSId(crCMS[0].data.id, crCMS[0].data, 'crCMS');
            this.viewEditCMS();
            // this.populateSearchGirdData();
          } 
          else {
            this.toggleViewCMSSelectDialog();
          }
        }
      )
    }
  } 

  withdrawCMS = async () => {
    if (!this.state.withdrawReason) {
      this.setState({
        isErrorMessageVisible: true,
      });
      return;
    } else {
      await this.setState({
        loading: true
      });
      const approveObj = {
          projectCMS: this.state.selectedCMSheet,
          projectId: this.state.selectedCRId,
          comment: this.state.withdrawReason
      };  
      if(this.state.viewCMSDialog == true){
        this.toggleViewCMSSelectDialog();
      }
      
      await withdrawCMSheet(approveObj)
        .then(async res => {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
          if (this.state.showCMSWithdrawDialog) {
            this.toggleCMSWithdrawDialog('', null);
          }
          this.toggleDialog('Withdrawn successfully', 'Success');
          await this.populateSearchGirdData();
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.toggleDialog(error.response, 'Error');
        });
        await this.setState({
          loading: false
        });
    }
  }

  submitRequestReviseCMS = async() => {
    if (!this.state.requestReviseCMSReason) {
      this.setState({
        isErrorMessageVisible: true,
      });
      return;
    } else {
      const selectedProjectOrCR = this.state.selectedProjectOrCR;
      const projData = {
        Department: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.BillingDivisionId : selectedProjectOrCR.BillingDivision,
        Practice: selectedProjectOrCR.PracticeId,
        Region: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.BillingRegion : selectedProjectOrCR.BillingRegion,
      }
      await selectReviseCMSRequestWorkflow(projData).then(res => {
        if (res.data.length > 1) {
          this.setState(
            {
              matchingWorkflowList: res.data
            },
            () => {
              this.toggleReviseCMSWorkflowSelectDialog();
            }
          );
        } else if (res.data.length === 1) {
          this.setState(
            {
              selectedWorkFlowId: res.data[0].id
            },
            () => {
              this.setWorkflowReviseCMS();
            }
          );
        } else {
          const message =
            'This request has failed to find a appropriate workflow for approvals. Please contact your system administrator to correct this issue.';
          const title = 'Error';
          this.setState({
            showSuccessImage: false,
            showErrorImage: true,
            isErrorMessageVisible: false
          });
          this.toggleMessageDialog(message, title);
        }
      });
    }
  }

  setWorkflowReviseCMS = async() => {
    await this.setState({
      showReviseCMSWorkflowSelectDialog: false,
      showRequestReviseCMSDialog: false,
      loading: true
    });
    const selectedProjectOrCR = this.state.selectedProjectOrCR;
    if (selectedProjectOrCR) {
      const workflowData = {
        ProjectId: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.ProjectId : selectedProjectOrCR.id,
        CrId: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.id : null,
        CMSheetId: selectedProjectOrCR.CMSheets[0].id,
        CMSheetVersion: selectedProjectOrCR.CMSheets[0].Version,
        WorkflowId: this.state.selectedWorkFlowId,
        Comment: this.state.requestReviseCMSReason
      };
      await setReviseCMSWorkflow(workflowData)
      .then(async res => {
        await this.setState({
          selectedWorkFlowId: null,
          showErrorImage: false,
          showSuccessImage: true
        });
        this.toggleMessageDialog(`CMS Revision request submitted for Approval`, 'Success');
        await this.populateSearchGirdData();
      })
      .catch(error => {
        this.setState({
          loading: false,
          showErrorImage: true,
          showSuccessImage: false
        });
        this.toggleMessageDialog(error, 'Error');
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });     
    }
  }

  toggleReviseCMSWorkflowSelectDialog = () => {
    this.setState({
      showReviseCMSWorkflowSelectDialog: !this.state.showReviseCMSWorkflowSelectDialog
    });
  };


  statusChangePermission = async (project, type) =>{

    this.setState({
      loading: true
    });

    const term = 'CHG_TO_';
    const userPermissions = this.filterPermissions(term, window.USER_PERMISSION);
    let userPermissionsLst = [];
    let permissionLst = [];
    let statusLst = [];
    let modifiedStatusLst = [];
    let currentStatus;
    let selectedCR;

    // Get all DB permissions
    await getPermissionList().then(response => {
      permissionLst = response.data;
      userPermissions.forEach(function (value) {
        const item = response.data.filter(i => i.PermissionName === value);
        if (item) {
          userPermissionsLst.push(item[0])
        }
      });
    }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
    });


    if(type === 'Project'){
      currentStatus = (project && project.ProjectStatus) ? project.ProjectStatus : null;
      this.state.currentStatus.push(currentStatus);
      this.CROrProjectCurrentStatus = currentStatus.StatusName;
      this.CROrProjectCurrentStatusId = currentStatus.id;

      await getProjectOrCrResourceRequest(type, project.id).then(res => {
        if(res.data && res.data.length > 0){
          project.ResourceRequest = res.data;
        }
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }
    else if (type === 'ChangeRequest') {
      const selectedCrId = this.state.selectedCRId;
      const selectedCr = project.ChangeRequests.filter(obj => obj.id === selectedCrId);
      this.CROrProjectCurrentStatus = selectedCr[0].Status.StatusName;
      this.CROrProjectCurrentStatusId = selectedCr[0].Status.id;
      selectedCR = selectedCr;

      const projectTypeObj = {
        Name: selectedCr[0].Type.Name,
        id: selectedCr[0].Type.id
      };

      const billingDiviObj = {
        Name: selectedCr[0].BillingDivision.Name,
        id: selectedCr[0].BillingDivision.id
      };

      await getProjectOrCrResourceRequest(type, selectedCr[0].id).then(res => {
        if(res.data && res.data.length > 0){
          selectedCr[0].ResourceRequest = res.data;
        }
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

      await this.setState({
        projtypeValue: projectTypeObj,
        billingdiviValue: billingDiviObj,
        endDateAugmentation: selectedCr[0].EndDate ? new Date(selectedCr[0].EndDate) : null,
        endDateBlanketWork: selectedCr[0].EndDate ? new Date(selectedCr[0].EndDate) : null,
        projectActivated: selectedCr[0].StatusConvertedToActive,
        startdateStatus: new Date(selectedCr[0].StartDate),
        projectOrCr: selectedCr[0],
        isPONumberRequired: selectedCr[0].Customer && selectedCr[0].Customer.PONumber ? true : false,
        poNumber: selectedCr[0].PONumber ? selectedCr[0].PONumber : "",
        billingEntityValue: selectedCr[0].LegalEntity ? selectedCr[0].LegalEntity : null
    });

      //start date
      if(selectedCr[0].StartDate === null){
        this.setState({
          startdateStatus: null
        });
      }

      currentStatus = (selectedCr && selectedCr.length > 0) ? selectedCr[0].Status : null;
      this.state.currentStatus.push(currentStatus);
    }

    // filter all permissions related to status change
    permissionLst = this.filterPermissions(term, permissionLst.map(a => a.PermissionName));

    if(currentStatus.Code === 'DEAL_ON_HOLD') {
      const cmsApprovedValidationLst = [Status['Waiting_Confirmation'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermissionsProjectStatus = [Status['Presales'].code, Status['Waiting_Confirmation'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Without_Bidding'].code, Status['Opportunity_Lost'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'PRESALES') {
      const cmsApprovedValidationLst = [Status['Waiting_Confirmation'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermissionsProjectStatus = [Status['Waiting_Confirmation'].code, Status['Deal_On_Hold'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Without_Bidding'].code, Status['Opportunity_Lost'].code];  
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'WAIT_CONFIRM') {
      const cmsApprovedValidationLst = [Status['Deal_On_Hold'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermissionsProjectStatus = [Status['Deal_On_Hold'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'WORK_AT_RISK') {
      const cmsApprovedValidationLst = [Status['Active'].code, Status['Project_On_Hold'].code];
      const validPermissionsProjectStatus = [Status['Active'].code, Status['Project_On_Hold'].code, Status['Discontinue'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'PROJECT_ON_HOLD') {
      const cmsApprovedValidationLst = [Status['Active'].code];
      const validPermissionsProjectStatus = [Status['Active'].code, Status['Discontinue'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'BILLABLE') { // Active
      let cmsApprovedValidationLst = [Status['Project_On_Hold'].code, Status['Complete'].code];
      let validPermissionsProjectStatus = [Status['Project_On_Hold'].code, Status['Complete'].code, Status['Discontinue'].code];
      if (!this.state.projectOrCr.StatusConvertedToActive) {
        cmsApprovedValidationLst = [Status['Project_On_Hold'].code];
        validPermissionsProjectStatus = [Status['Project_On_Hold'].code];  
      }
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'COMPLETE') {
      const cmsApprovedValidationLst = [];
      const validPermissionsProjectStatus = [Status['Closed'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));
    } 

    //copy statusLst
    modifiedStatusLst = statusLst.map((_arrayElement) => Object.assign({}, _arrayElement));
    
    for(let el of modifiedStatusLst){
      switch(el.PermissionDescription){
        case 'Change to Presale': {
          el.PermissionDescription = "Presale";
          break;
        }
        case 'Change to Waiting Confirmation': {
          el.PermissionDescription = "Waiting Confirmation";
          break;
        }
        case 'Change to Deal on-hold': {
          el.PermissionDescription = "Deal on-hold";
          break;
        }
        case 'Change to Working at Risk': {
          el.PermissionDescription = "Working at Risk";
          break;
        }
        case 'Change to Active': {
          el.PermissionDescription = "Active";
          break;
        }
        case 'Change to Project on-hold': {
          el.PermissionDescription = "Project on-hold";
          break;
        }
        case 'Change to Close without bidding': {
          el.PermissionDescription = "Close without bidding";
          break;
        }
        case 'Change to Close – Opportunity Lost': {
          el.PermissionDescription = "Close - Opportunity Lost";
          break;
        }
        case 'Change to Close - Discontinue': {
          el.PermissionDescription = "Close - Discontinue";
          break;
        }
        case 'Change to Complete': {
          el.PermissionDescription = "Complete";
          break;
        }
        case 'Change to Close': {
          el.PermissionDescription = "Close";
          break;
        }
        case 'Change to Closed': {
          el.PermissionDescription = "Closed";
          break;
        }
        default : {
        }
      }
    }

    // Set the field Enable/Disable param
    if (statusLst.length > 0) {
      if (this.isMount) {
        await this.setState({
          invalidatePermission: false,
          statusValueLst: modifiedStatusLst
        });
      }
    } else {
      if (this.isMount) {
        await this.setState({
          invalidatePermission: true
        });
      }
    }
    await this.populateActivityLogData(this.state.projectOrCr, project.id);

    let projectOrCr = this.state.projectOrCr
    const statusChangeObj = {
      projectId: projectOrCr.ProjectId ? projectOrCr.ProjectId : projectOrCr.id,
      crId: projectOrCr.ProjectId ? projectOrCr.id : null,
    };

    await this.populateSortedActivityLogData(statusChangeObj.projectId,statusChangeObj.crId,true);

    if(this.state.sortedActivity.length > 0){
      if(this.state.sortedActivity[0].ApprovalStatus === 1){
        await this.setState({
          isPendingStatus: true
        });
      }
    }

    this.setState({
      loading: false
    });
  };

  getValidPermissions = (userPermissionsLst, permissionLst, cmsApprovedValidationLst, CMSheets) => {
    let statusLst = [];
    let statusLstEdit = [];
    this.state.initStatusLst = [];

    //get valid status list
    for(let item of userPermissionsLst) {
      let permission = permissionLst.filter(i => i === item.PermissionName);
      if (permission && permission.length == 1) {
        if(cmsApprovedValidationLst.includes(permission[0])) { // check cMS Approved validation
          let cmSheet = CMSheets[CMSheets ? CMSheets.length - 1 : 0];
          if(cmSheet && cmSheet.Status === 'APPROVED'){
            this.state.initStatusLst.push(item);
          }
        } 
        else {
          this.state.initStatusLst.push(item);
        }
      }
    }

    //copy status list
    statusLstEdit = this.state.initStatusLst.map((_arrayElement) => Object.assign({}, _arrayElement));
    
    //change status --> close
    for(let el of statusLstEdit){
      if((el.PermissionName === Status['Without_Bidding'].code) || (el.PermissionName === Status['Opportunity_Lost'].code) || el.PermissionName === Status['Discontinue'].code){
        el.PermissionName = Status['Close'].code;
        el.PermissionDescription = "Close";
        el.IsActive = true;
        el.id = 108;
      }
    }

    //remove duplicates
    function getUniqueListBy(statusLstEdit, key) {
      return [...new Map(statusLstEdit.map(item => [item[key], item])).values()];
    }

    statusLst = getUniqueListBy(statusLstEdit, 'PermissionName');
    return statusLst;
  };

  filterPermissions = (needle, heystack) => {
    let query = needle.toLowerCase();
    return heystack.filter(item => item.toLowerCase().indexOf(query) >= 0);
  };

  handleOnChangeStatus =  async event => {
    const name = event.target.name;
    const value = event.target.value;

    await this.setState({
      selectedStatus: event,
      disablePONumber: true
    });

    const openCrMessage = "CR(s) belonging to this project are still open";
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const resorceAllocationMessage = `There are Resource allocations running beyond the completion date. Do you want to change the end dates of the overrunning allocations to the Project completion date ${todayDate}?`;
    const titleError = "Error";

    let changeToClose = Status['Close'].code;
    let changeToCloseOppLst = Status['Opportunity_Lost'].code;
    let changeToCloseWthtBid = Status['Without_Bidding'].code;
    let changeToCloseDis = Status['Discontinue'].code;
    let changeToComplete = Status['Complete'].code;

    let openCR = false;
    let projResAllocationBeyondCompDate = false;
    let crResAllocationBeyondCompDate = false;
    let count = 0;
    this.state.isErrorMessageVisible = false;
    this.state.RBtnCount = true;
    
    $("input:radio").prop('checked', false);
    $("input:radio").prop('disabled', true);

    if (this.isMount) {
      await this.setState({
        [name]: value,
        disableCommentBoxDiv: true
      });

      if((value !== null) && (value.PermissionDescription === "Close")){
        this.setState({
          showCloseSubCategory: true
        });
      }

      if((value !== null) && (value.PermissionDescription !== "Close")){
        this.setState({
          showCloseSubCategory: false
        });
      }

      if(this.state.projectOrCr.ProjectName && this.state.projectOrCr.ChangeRequests.length > 0){
        let elements = (this.state.projectOrCr.ChangeRequests);
      
        for(let el of elements){
          if(el.Status.StatusName === "Active" || 
            el.Status.StatusName === "Presales" || 
            el.Status.StatusName === "Waiting Confirmation" || 
            el.Status.StatusName === "Deal On Hold" || 
            el.Status.StatusName === "Project On Hold" || 
            el.Status.StatusName === "Working At Risk"){
              openCR = true;
              break;
          }
          else{
            openCR = false;
          }
        }
      }
      
      //project resource allocation
      if(this.state.projectOrCr.ProjectName){
        if(this.state.projectOrCr.ResourceRequest && this.state.projectOrCr.ResourceRequest.length > 0){
          let deallocatedMainLines = [];
          let deallocatedSubLines = [];
          for(let resourceReq of this.state.projectOrCr.ResourceRequest){
            if(resourceReq.Status === 'COMPLETE' && resourceReq.IsActive){
              let endDate = new Date(resourceReq.EndDate);
              let completionDate = new Date();
  
              if((resourceReq.AllocatedResourceID !== null) && (endDate.getTime() >= completionDate.getTime())){
                projResAllocationBeyondCompDate = true;
                deallocatedMainLines.push(resourceReq);
                // break;
              }
            }

            if(resourceReq.Status === 'EDIT' && resourceReq.IsActive){
              this.setState({
                loading: true
              });
              
              let resourceReqId = resourceReq.id;
              await getResourceRequestSubLines(resourceReqId).then(response => {
                let subLinesData = response.data;
                this.setState({
                  resourceReqSubLines: subLinesData
                });
              }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });

              for(let el of this.state.resourceReqSubLines){
                if(el.Status == 'COMPLETE' && el.IsActive){
                  let endDate = new Date(el.EndDate);
                  let completionDate = new Date();
  
                  if((el.ResourceId !== null) && (endDate.getTime() >= completionDate.getTime())){
                    projResAllocationBeyondCompDate = true;
                    deallocatedSubLines.push(el);
                    // break;
                  }
                }
              }

              this.setState({
                loading: false
              });
            }
          }
          this.setState({
            deallocatedMainLines: deallocatedMainLines,
            deallocatedSubLines: deallocatedSubLines
          });
        }
      }

      //CR resource allocation
      if(!this.state.projectOrCr.ProjectName){
        if(this.state.projectOrCr.ResourceRequest && this.state.projectOrCr.ResourceRequest.length > 0){
          let deallocatedMainLines = [];
          let deallocatedSubLines = [];
          for(let resourceReq of this.state.projectOrCr.ResourceRequest){
            if(resourceReq.Status === 'COMPLETE' && resourceReq.IsActive){
              let endDate = new Date(resourceReq.EndDate);
              let completionDate = new Date();
    
              if((resourceReq.AllocatedResourceID !== null) && (endDate.getTime() >= completionDate.getTime())){
                crResAllocationBeyondCompDate = true;
                deallocatedMainLines.push(resourceReq);
                // break;
              }
            }

            if(resourceReq.Status === 'EDIT' && resourceReq.IsActive){
              this.setState({
                loading: true
              });
              
              let resourceReqId = resourceReq.id;
              await getResourceRequestSubLines(resourceReqId).then(response => {
                let subLinesData = response.data;
                this.setState({
                  resourceReqSubLines: subLinesData
                });
              }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });

              for(let el of this.state.resourceReqSubLines){
                if(el.Status == 'COMPLETE' && el.IsActive){
                  let endDate = new Date(el.EndDate);
                  let completionDate = new Date();
  
                  if((el.ResourceId !== null) && (endDate.getTime() >= completionDate.getTime())){
                    crResAllocationBeyondCompDate = true;
                    deallocatedSubLines.push(el);
                    // break;
                  }
                }
              }

              this.setState({
                loading: false
              });
            }
          }
          this.setState({
            deallocatedMainLines: deallocatedMainLines,
            deallocatedSubLines: deallocatedSubLines
          });
        }
      }

      // Start Date - Status Changed to Active/ Working At Risk
      // Project
      if(this.state.projectOrCr.ProjectName){
        if(value !== null && (value.PermissionDescription === 'Active' || value.PermissionDescription === 'Working at Risk')){
          let activeOrWarStartDate = (this.state.projectOrCr.ActiveOrWARStartDate && this.state.projectOrCr.ActiveOrWARStartDate !== null) ? new Date(this.state.projectOrCr.ActiveOrWARStartDate) : null;
          let projOrCrStartDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? new Date(this.state.projectOrCr.StartDate) : null;

          this.setState({
            startdateStatus: (activeOrWarStartDate !== null) ? (activeOrWarStartDate) : (projOrCrStartDate)
          });
        }
        else{
          let startDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? new Date(this.state.projectOrCr.StartDate) : null;

          this.setState({
            startdateStatus: (startDate !== null) ? startDate : null
          });
        }
      }
      // CR
      if(!this.state.projectOrCr.ProjectName){
        if(value !== null && (value.PermissionDescription === 'Active' || value.PermissionDescription === 'Working At Risk')){
          let activeOrWarStartDate = (this.state.projectOrCr.ActiveOrWARStartDate && this.state.projectOrCr.ActiveOrWARStartDate !== null) ? new Date(this.state.projectOrCr.ActiveOrWARStartDate) : null;
          let projOrCrStartDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? new Date(this.state.projectOrCr.StartDate) : null;
          
          this.setState({
            startdateStatus: (activeOrWarStartDate !== null) ? (activeOrWarStartDate) : (projOrCrStartDate)
          });
        }
        else{
          let startDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? new Date(this.state.projectOrCr.StartDate) : null;

          this.setState({
            startdateStatus: (startDate !== null) ? startDate : null
          });
        }
      }

      if((value !== null) && (value.PermissionName === changeToCloseWthtBid || value.PermissionName === changeToCloseOppLst || value.PermissionName === changeToCloseDis)){
        value.PermissionName = changeToClose;
      }

      if((value !== null) && (value.PermissionName === changeToClose)){
        if(openCR === true){
          this.setState({
            showErrorImg: true
          });
          this.toggleErrorMessageDialog(openCrMessage, titleError);
          this.toggleChangeStatusDialog(); 
        }
        
        if(openCR === false){
          for(let el of this.state.initStatusLst){
            if(el.PermissionName === changeToCloseWthtBid){
              $("input:radio[value='wthtbid']").prop('disabled',false);
              count++;
            }
            else if(el.PermissionName === changeToCloseOppLst){
              $("input:radio[value='oppolost']").prop('disabled',false);
              count++;
            }
            else if(el.PermissionName === changeToCloseDis){
              $("input:radio[value='discontinue']").prop('disabled',false);
              count++;
            }
            this.state.RBtnCount = false;
          }

          if(count === 1){
            for(let el of this.state.initStatusLst){
              switch (el.PermissionName) {
                case changeToCloseWthtBid:
                  $("input:radio[value='wthtbid']").prop('checked',true);
                  value.PermissionName = changeToCloseWthtBid;
                  this.state.RBtnCount = true;
                  break;
                
                case changeToCloseOppLst:
                  $("input:radio[value='oppolost']").prop('checked',true);
                  value.PermissionName = changeToCloseOppLst;
                  this.state.RBtnCount = true;
                  break;

                case changeToCloseDis:
                  if(projResAllocationBeyondCompDate){
                    $("input:radio[value='discontinue']").prop('checked',false);
                    $("input:radio[value='discontinue']").prop('disabled',true);

                    this.setState({
                      showErrorImg: true
                    });
                    this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
                  }
                  if(crResAllocationBeyondCompDate){
                    $("input:radio[value='discontinue']").prop('checked',false);
                    $("input:radio[value='discontinue']").prop('disabled',true);

                    this.setState({
                      showErrorImg: true
                    });
                    this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
                  }
                  else{
                    $("input:radio[value='discontinue']").prop('checked',true);
                    value.PermissionName = changeToCloseDis;
                    this.state.RBtnCount = true;
                  }
                  break;
              
                default:
                  break;
              }
            }
          }

          else if(count > 1){
            $("input:radio[name='changestatus']").on('change', function(){
              if($("input:radio[value='wthtbid']").is(':checked')){
                value.PermissionName = changeToCloseWthtBid;
              }
              else if($("input:radio[value='oppolost']").is(':checked')){
                value.PermissionName = changeToCloseOppLst;
              }
              else if($("input:radio[value='discontinue']").is(':checked')){
                value.PermissionName = changeToCloseDis;
              }
            });
          }
        }
      }

      if((value !== null) && (value.PermissionName === changeToComplete)){
        let cmsApproved = false;

        for(let cms of this.state.projectOrCr.CMSheets){
          if(cms.Status === "APPROVED"){
            cmsApproved = true;
          }
          else{
            cmsApproved = false;
          }
        }

        if(this.state.projectOrCr.ProjectName && this.state.projectOrCr.ProjectStatus.StatusName === "Active" && cmsApproved === true){
          if(openCR === true){
            this.setState({
              showErrorImg: true
            });
            this.toggleErrorMessageDialog(openCrMessage, titleError);
            this.toggleChangeStatusDialog();
          }
          else if(projResAllocationBeyondCompDate){
            this.setState({
              showErrorImg: true
            });
            this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
          }
        }

        else if(this.state.projectOrCr.Status.StatusName === "Active" && cmsApproved === true){
          if(crResAllocationBeyondCompDate){
            this.setState({
              showErrorImg: true
            });
            this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
          }   
        }

        else{
          $("Button[id='statusPopupSaveBtn']").prop('disabled',true);
        }
      }
    }

    if(value !== null){
      //get document types for the selected status

      this.setState({
        loading: true
      });

      let docTypesForProject = [];
      let docTypesForCr = [];
      let statusKey = value.PermissionName;
      let documentTypes = await getAllDocumentTypesByStatus(ProjStatuses[statusKey].value);

      //Project
      if(!(this.state.projectOrCr.ProjectId)){
        docTypesForProject = documentTypes.data.filter(el => el.IsProjectApplicable || el.IsProjectMandatory);
        await this.setState({
          allDocumentTypesForStatus : docTypesForProject
        });
      }
      //CR
      else if(this.state.projectOrCr.ProjectId){
        docTypesForCr = documentTypes.data.filter(el => el.IsCRApplicable || el.IsCRMandatory);
        await this.setState({
          allDocumentTypesForStatus : docTypesForCr
        });
      }

      //document 'isMandatory'
      for(let el of this.state.allDocumentTypesForStatus){
        if(!(this.state.projectOrCr.ProjectId)){
          if(el.IsProjectMandatory){
            el.isMandatory = "true";
          }
        }
        else if(this.state.projectOrCr.ProjectId){
          if(el.IsCRMandatory){
            el.isMandatory = "true";
          }
        }
      }

      if(value.PermissionDescription === 'Active'){
        this.setState({
          isPONumberRequired: this.state.projectOrCr.Customer && this.state.projectOrCr.Customer.PONumber ? true : false,
          poNumber: this.state.projectOrCr.PONumber ? this.state.projectOrCr.PONumber : "",
          billingEntityValue: this.state.projectOrCr.LegalEntity ? this.state.projectOrCr.LegalEntity : null,
          disablePONumber: false
        });
      }
  
      this.setState({
        documentTypesForDropdown: this.state.allDocumentTypesForStatus,
        loading: false
      });
    }

    if(value === null){
      this.setState({
        showCloseSubCategory: false,
        isErrorMessageVisible: false,
        DocUploadErrorMessageVisible: false,
        fileUploadMessageVisible: false,
        disableFileUpBtnDiv: false,
        disableCommentBoxDiv: false,
        fileUploadDetails: [],
        stsChangeComment: ''
      });
    }
  };

  deallocateResourceRequest = async () => {

    await this.setState({
      loading: true
    });

    let mainLinesToBeUpdated = [];
    let mainLinesToBeDeleted = [];
    let subLinesToBeUpdated = [];
    let subLinesToBeDeleted = [];

    if (this.state.deallocatedMainLines && this.state.deallocatedMainLines.length > 0) {
      for (const request of this.state.deallocatedMainLines) {
        request.ResDeallocationStartDate = moment(new Date()).format('YYYY-MM-DD');

        if(request.ResDeallocationStartDate >= request.StartDate){
          //update data
          await this.deallocateAllocationPercentageHistory(request);
          await this.getCostForDesignation(request);
          mainLinesToBeUpdated.push(request);
        } else {
          let deletedIds = [];
          deletedIds.push(request.id)
          let mainLineItem = {
            deletedIds: deletedIds,
            type: request.Type,
            id: this.state.projectOrCr.id
          };

          mainLinesToBeDeleted.push(mainLineItem);
        }
      }
    }

    if (this.state.deallocatedSubLines && this.state.deallocatedSubLines.length > 0) {
      for (const request of this.state.deallocatedSubLines) {
        request.ResDeallocationStartDate = moment(new Date()).format('YYYY-MM-DD');

        if(request.ResDeallocationStartDate >= request.StartDate){
          //update data
          await this.deallocateAllocationPercentageHistory(request);
          await this.getCostForDesignation(request);
          subLinesToBeUpdated.push(request);
        } else {
          let deletedIds = [];
          deletedIds.push(request.id)
          subLinesToBeDeleted.push(deletedIds);
        }
      }
    }

    let items = {
      projectOrCr: this.state.projectOrCr,
      mainLinesToBeUpdated: mainLinesToBeUpdated,
      mainLinesToBeDeleted: mainLinesToBeDeleted,
      subLinesToBeUpdated: subLinesToBeUpdated,
      subLinesToBeDeleted: subLinesToBeDeleted
    };
    const deallocatedLinesTot = mainLinesToBeUpdated.length + mainLinesToBeDeleted.length + subLinesToBeUpdated.length + subLinesToBeDeleted.length;
 
    await deallocateRequestsFromStatusChange(items)
      .then(async res => {
        if (res.data && res.data.length > 0) {
          this.state.projectOrCr.ResourceRequest = res.data;
          await this.setState({
            showConfirmDialog: false,
            showSuccessImg: true
          });
          this.toggleMessageDialog(`${deallocatedLinesTot} Resources were deallocated.`, 'Success');
          await this.handleOnChangeStatus(this.state.selectedStatus);
        }

      })
      .catch(async error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

      await this.setState({
        loading: false
      });
  }

  deallocateAllocationPercentageHistory = async (dataItem) => {

    let lineAllocationHistoryList = [];
    const resourceRequestId = dataItem && dataItem.ResourceRequestId ? 
                              dataItem.ResourceRequestId : dataItem.id;
    const resourceRequestIdSub = dataItem && dataItem.ResourceRequestId ? 
                              dataItem.id : null;
    const newEndDate = moment(dataItem.ResDeallocationStartDate).format('YYYY-MM-DD');
    const resourceDetails = {
      resourceRequestId: resourceRequestId,
      resourceRequestIdSub: resourceRequestIdSub,
    };
    await getAllocationPercentageHistory(resourceDetails)
    .then(res => {
      lineAllocationHistoryList = res.data;
    }).catch(err => {
      loggerService.writeLog(err, LOG_TYPES.ERROR);
    });

    const validAlocationHistoryList = lineAllocationHistoryList.filter(record => new Date(record.StartDate) >= new Date(newEndDate) || new Date(record.EndDate) >= new Date(newEndDate));

    for (const record of validAlocationHistoryList) {
      if (record.StartDate > newEndDate && record.EndDate > newEndDate) {
        record.IsActive = false;
      } else if (record.StartDate <= newEndDate && record.EndDate >= newEndDate) {
        record.EndDate = moment(newEndDate).format('YYYY-MM-DD')
      }
    }
    for (const item of validAlocationHistoryList) {
      await this.calculateAllocationHistoryLineEffort(item, dataItem);
    }

    await updateAllocationPercentageHistory(validAlocationHistoryList)
    .then(async res => {
      
    }).catch(err => {
      loggerService.writeLog(err, LOG_TYPES.ERROR);
    });
  }

  calculateAllocationHistoryLineEffort = async (historyLine, request) => {

    let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
    let isHoliday = false;
    let isLeave = false;
    let isHalfDay = false;

    if (request && historyLine.StartDate && historyLine.AllocationPercentage > 0) {

      let startDate = new Date(historyLine.StartDate);
      let endDate = new Date(historyLine.EndDate);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      let effort = 0;
      let resourceCountryId = null;
      if (request.OnsiteStatus === "Offshore") {
        resourceCountryId = request.dataValueResourceRegion ? request.dataValueResourceRegion.DefaultCountryId : null;
      } else if (request.OnsiteStatus === "Onsite") {
        resourceCountryId = this.state.projectOrCr.CountryId;
      }
      let holidays = []; 
      holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
        if (res && res.data) {
          return res.data;
        }
        return [];
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      if (!holidays) {
        holidays = [];
      }
      const leaveObj = {
        ResourceId: request.ResourceId, 
        StartDate: moment(historyLine.StartDate).format('YYYY-MM-DD'), 
        EndDate: moment(historyLine.EndDate).format('YYYY-MM-DD')
      }
      let resourceLeaves = await getLeavesIntegrated(leaveObj).then(res => {
        if (res && res.data) {
            return res.data;
        }
        return [];
      })
      .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
      let loop = new Date(startDate);
      while (loop <= endDate) {
        isHoliday = false;
        isLeave = false;
        isHalfDay = false;
        const dateStr = getFormattedDate(loop);
        if (holidays.indexOf(dateStr) > -1) {
          isHoliday = true;
        }

        if (resourceLeaves && resourceLeaves.length > 0) {
          let leaveDate = resourceLeaves.filter(
              leave => leave.LeaveDate === dateStr
          );
          if (leaveDate && leaveDate.length > 0) {
              for (const leave of leaveDate) {
                  if (leave.Duration === '8') {
                      isLeave = true;
                  } else {
                      isHalfDay = true;
                  }
              }
            }
        }

        if (!isHoliday && !isLeave && !isWeekend(loop)) {
          if (!isHalfDay) {
            totalWorkingDays = totalWorkingDays + 1;
          } else {
            totalWorkingDays = totalWorkingDays + 0.5;
          }
        }

        let newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }

      effort = totalWorkingDays * (historyLine.AllocationPercentage / 100);
      historyLine.EffortDays = Number((effort).toFixed(2));
    }
  };

  getCostForDesignation = async dataItem => {

    let billingRegion = 0;
    let projOrCr = null;
    let projectId = null;
    let DirectCostInUSD = 0;
    let CostPerResourceInUSD = 0;
    let totalCostUSD = 0;
    let EffortDays = 0;

    billingRegion = this.state.projectOrCr.BillingRegion;
    projOrCr = this.state.projectOrCr;
    projectId = this.state.projectOrCr.id;

    let newItem = {
      newResProjectOrCRName: this.state.projectOrCr,
      newResRegion:dataItem.dataValueResourceRegion ? dataItem.dataValueResourceRegion : null,
      newOnSiteStatus: dataItem.OnsiteStatus,
      newStartDate: moment(dataItem.StartDate).format('MM/DD/YYYY'),
      newEndDate:  moment(new Date(dataItem.ResDeallocationStartDate)).format('MM/DD/YYYY'),
      newEffortDays: dataItem.EffortDays.toFixed(2),
      newAllocation: dataItem.AllocationPercentage,
      EstimatedEndDate:moment(new Date(dataItem.ResDeallocationStartDate)).format('MM/DD/YYYY'),
      newResDesignation: dataItem.Designation == 57 ? { id: dataItem.Designation } : dataItem.AllocatedDesignationId ?  { id: dataItem.AllocatedDesignationId } : dataItem.Resource ? { id: dataItem.Resource.DesignationId } : null,
      resourceCountry : dataItem.Resource.GeneralCountry,
      projOrCrCountry : projOrCr.CountryId,
      resourceId: null,
      ResourceRequestId: dataItem.ResourceRequestId ? dataItem.ResourceRequestId : dataItem.id,
      ResourceRequestSubLineId: dataItem.ResourceRequestId ? dataItem.id : null,
      CostPerOutSourcedResource: dataItem.Designation == 57 ? (dataItem.CostPerOutSourcedResource && dataItem.CostPerOutSourcedResource > 0) ? dataItem.CostPerOutSourcedResource : dataItem.InitialCostPerResource : null
    };

    let costDate_ = new Date(dataItem.StartDate);

    const costCard = {
      billingRegionId: billingRegion,
      resourceRegionId: dataItem.Resource ? dataItem.Resource.Region : null,
      designationId: dataItem.Designation == 57 ? dataItem.Designation : dataItem.AllocatedDesignationId ? dataItem.AllocatedDesignationId : dataItem.Resource ? dataItem.Resource.DesignationId : null,
      projectId: projectId,
      costDate: moment(new Date()).format('YYYY-MM-DD')
    };

    costDate_ = moment(costDate_).format('YYYY-MM-DD');
    // const costItems = {
    //   costCard : costCard,
    //   newItem : newItem,
    //   costDate: costDate_
    // };
    const costItems = {
      resourceRequest: dataItem,
      mainLine: dataItem.ResourceRequestId ? false : true,
      projectId: projectId,
      ofTypeProject: projOrCr.ProjectId ? false : true,
    };
    await getCostofAllocatedResource(costItems)
        .then(res => {
          totalCostUSD = res.data.TotalCost;
          DirectCostInUSD = res.data.DirectCost;
          CostPerResourceInUSD = res.data.CostPerResource;
          EffortDays = res.data.EffortDays;
        })
        .catch( async err => {
          totalCostUSD = 0;
          DirectCostInUSD = 0;
          CostPerResourceInUSD = 0;
          EffortDays = 0;
          loggerService.writeLog(err, LOG_TYPES.ERROR);

          const message = 'Cost card record not found for the selected designation and resource region';
          const title = 'Error';
          await this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });

          await this.toggleMessageDialog(message, title);
        });

    dataItem.TotalCost = totalCostUSD;
    dataItem.DirectCost = DirectCostInUSD;
    dataItem.CostPerResource = CostPerResourceInUSD;
    dataItem.EffortDays = EffortDays;

    // Revenue
    if (EffortDays && dataItem.RateValue) {
      dataItem.TotalRevenue = EffortDays * dataItem.RateValue;
    } else {
      dataItem.TotalRevenue = 0;
    }

    // Cost to Revenue ratio
    if (totalCostUSD > 0 && dataItem.TotalRevenue > 0) {
      dataItem.CostToRevenueRatio = (totalCostUSD / dataItem.TotalRevenue).toFixed(2);
    } else {
      dataItem.CostToRevenueRatio = 0;
    }
  };

  handleStatusChangeRbtn = async event => {
    const value = event.target.value;
    let permissionName;

    if($("input:radio[value=value]").prop('checked',true)){ 
      this.state.isErrorMessageVisible = false;
      this.state.RBtnCount = true;

      if(value === 'wthtbid'){
        permissionName = Status['Without_Bidding'].code;
      }
      else if(value === 'oppolost'){
        permissionName = Status['Opportunity_Lost'].code;
      }
      else if(value === 'discontinue'){
        permissionName = Status['Discontinue'].code;
      }
    }

    else if($("input:radio[value=value]").prop('checked',false)){
      this.state.RBtnCount = false;
    }

    //get document types for the selected status
    let docTypesForProject = [];
    let docTypesForCr = [];
    let statusKey = permissionName;
    let documentTypes = await getAllDocumentTypesByStatus(ProjStatuses[statusKey].value);

    //Project
    if(!(this.state.projectOrCr.ProjectId)){
      docTypesForProject = documentTypes.data.filter(el => el.IsProjectApplicable || el.IsProjectMandatory);
      await this.setState({
        allDocumentTypesForStatus : docTypesForProject
      });
    }
    //CR
    else if(this.state.projectOrCr.ProjectId){
      docTypesForCr = documentTypes.data.filter(el => el.IsCRApplicable || el.IsCRMandatory);
      await this.setState({
        allDocumentTypesForStatus : docTypesForCr
      });
    }

    //document 'isMandatory'
    for(let el of this.state.allDocumentTypesForStatus){
      if(!(this.state.projectOrCr.ProjectId)){
        if(el.IsProjectMandatory){
          el.isMandatory = "true";
        }
      }
      else if(this.state.projectOrCr.ProjectId){
        if(el.IsCRMandatory){
          el.isMandatory = "true";
        }
      }
    }
    await this.setState({
      documentTypesForDropdown : this.state.allDocumentTypesForStatus
    });
  };

  handleFileUpload = async event => {
    let uploadedFiles = [];

    for(let i=0; i<event.target.files.length; i++){
      uploadedFiles.push(event.target.files[i]);
    }

    for(let i = 0; i < uploadedFiles.length; i++){
      if( uploadedFiles[i].type === "application/pdf" || 
          uploadedFiles[i].type === "application/msword" ||
          uploadedFiles[i].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          uploadedFiles[i].type === "application/vnd.ms-excel" || 
          uploadedFiles[i].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || 
          uploadedFiles[i].type === "application/vnd.ms-powerpoint" || 
          uploadedFiles[i].type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          uploadedFiles[i].type === "image/png" ||
          uploadedFiles[i].type === "image/jpeg" ||
          uploadedFiles[i].type === "application/vnd.ms-outlook" ||
          uploadedFiles[i].type === "application/msoutlook" ||
          uploadedFiles[i].name.includes('.msg') ||
          uploadedFiles[i].name.includes('.htm') ){
        await this.setState({
          selectedFile: uploadedFiles[i],
          loaded: 0,
          isCannotUpload: false,
          fileUploadMessageVisible: false,
          DocUploadErrorMessageVisible: false,
          loading: true
        });
      }
      else{
        await this.setState({
          fileUploadMessageVisible: true,
          DocUploadErrorMessageVisible: false
        });
      }
      
      if(this.state.isCannotUpload === false){
        // UPLOAD LOGIC
        var form_data = new FormData();
        form_data.append('file', this.state.selectedFile);
        form_data.append('referenceType', 'StatusChange');
        form_data.append('referenceId', this.state.projectOrCr.id);
        form_data.append('docType', this.state.documentType.DocType);

        await uploadFile(form_data).then(res => {
          let fields = {};

          if(this.state.documentType){
            fields.DocType = this.state.documentType.DocType;
            fields.projectId = this.state.projectOrCr.id;
          }
          
          if(res){
            fields.FileUpload = res.data;
          }

          if(this.isMount){
            this.setState(prevState => {
              this.setState({ rowCount: prevState.rowCount + 1 });
              fields.id = this.state.rowCount;
            });
          }

          this.state.fileUploadDetails.push(fields);
          let documentType  = this.state.documentTypesForDropdown.filter(item => item.id !== this.state.documentType.id);      
          
          this.setState({
            //documentType: null,
            selectedFile: [],
            disableFileUpBtnDiv: false,
            loading: false,
            documentTypesForDropdown: documentType
          });
        })
        .catch(error => {
          //error
        });
      }
    }

    if(this.state.fileUploadMessageVisible === false){
      await this.setState({
        documentType: null,
        selectedFile: []
      });
    }
  };

  OnDelete = async (event, id) => {
    event.preventDefault();
    
    let fileUploadDetails = [...this.state.fileUploadDetails];
    let array = [...this.state.fileUploadDetails];
    let deletedFilesCopy = [...this.state.deletedFileUploadDetails];
    let deletedFile = fileUploadDetails.filter(item => item.id === id);
    const deletedDocType = deletedFile[0].FileUpload.DocumentType
    let addDocType  = this.state.allDocumentTypesForStatus.filter(item => item.DocType === deletedDocType);
    let documentType = this.state.documentTypesForDropdown;
    if(addDocType && addDocType.length >0) {
      documentType.push(addDocType[0]);
    }
    
    deletedFilesCopy.push(deletedFile[0]);
    array = array.filter(item => item.id !== id);

    if(this.isMount){
      await this.setState({
        fileUploadDetails: array,
        deletedFileUploadDetails: deletedFilesCopy,
        documentTypesForDropdown: documentType
      });
    }
  };

  filterStatusOnChange = event => {
    this.setState({
      projects: this.filterStatusData(event.filter)
    });
  };

  filterStatusData(filter) {
    const data = this.state.statusValueLst.slice();
    return filterBy(data, filter);
  }

  handleOnChangeDocType =  async event => {
    const name = event.target.name;
    const value = event.target.value;

    if (this.isMount) {
      await this.setState({
        [name]: value,
        DocUploadErrorMessageVisible: false,
        fileUploadMessageVisible: false,
        disableFileUpBtnDiv: true
      });
    }
  };

  filterDocTypeOnChange = event => {
    this.setState({
      projects: this.filterDocTypeData(event.filter)
    });
  };

  filterDocTypeData(filter) {
    const data = this.state.documentTypesForDropdown.slice();
    return filterBy(data, filter);
  }

  handleHighlightMandatoryDocs = (li, itemProps) => {
    if(itemProps.dataItem.isMandatory){
       const itemChildren = <span>{li.props.children} <span className="mandatory"></span></span>;
      return React.cloneElement(li, li.props, itemChildren);
    }
    else{
      const itemChildren = <span>{li.props.children}</span>;
     return React.cloneElement(li, li.props, itemChildren);
    }
  }
  handlePONumberChange = event => {
    let value = event.target.value;
    this.setState({
      poNumber: value,
    });
  }

  handleStartdateChange = event => {
    let startDate = event.target.value;

    this.setState({
      startdateStatus: startDate,
      showStartDateErrorMessage: false
    });

    if (startDate) {
      this.setState({
        showStartDateValidationError: false
      });
    }
  };

  onChangeStsChgComment = statusComment => {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        stsChangeComment: statusComment
      });
    }, 1000);
  };

  handleBillingEntityChange = event => {
    const value = event.target.value;
    this.setState({
      billingEntityValue: value,
      isFormDirty: true
    });
  };

  filterBillingEntities = event => {
    this.setState({
      billingEntityType: this.filterBillingEntityData(event.filter)
    });
  };

  filterBillingEntityData(filter) {
    const data = this.allBillingEntities.slice();
    return filterBy(data, filter);
  }

  onSaveStatusChange = async() => {

    this.setState({
      loading: true
    });

    let proOrcmsId = null;
    let startDateErrorMessage = false;
    let closeStatusErrorMessage = false;
    let pastStartDateErrorMessage = false;
    let wasPresale = false;

    if (this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk' || this.state.selectedChangeToStatus.PermissionDescription === 'Waiting Confirmation')) {
      if (this.state.startdateStatus == null) {
        startDateErrorMessage = true;
      }
    }

    if ((this.state.currentStatus[0].StatusName === 'Presales') &&
        (this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk')) &&
        ($("DatePicker[name='startdateStatus']").prop('disabled', false)) && (!this.state.projectActivated || this.state.projectActivated == null)) {
          let startDate = moment(this.state.startdateStatus).format('YYYY-MM-DD');
          let currentDate = moment(new Date()).format('YYYY-MM-DD');
          wasPresale = true;
          if(startDate < currentDate){
            pastStartDateErrorMessage = true;
          }
    }

    // Project/CR Start Date - Status Changed to Active/Working At Risk
    let activeOrWarStartDate = null;

    if(this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk')){
      activeOrWarStartDate = (this.state.startdateStatus && this.state.startdateStatus !== null) ? (moment(this.state.startdateStatus).format('YYYY-MM-DD')) : null;
    }

    if(!(this.state.RBtnCount)){
      closeStatusErrorMessage = true;
    }

    if((!this.state.projectActivated) &&
      (this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk'))){
      let conversionStartDate = moment(this.state.startdateStatus).format('YYYY-MM-DD');
      let projOrCrId = this.state.projectOrCr.id;
      let isProject = this.state.projectOrCr.ProjectId ? false : true;

      let item = {
        conversionStartDate: conversionStartDate,
        projOrCrId: projOrCrId,
        isProject: isProject,
        wasPresale: wasPresale,
        PONumber: this.state.poNumber ? this.state.poNumber.trim() : null, // update PO number
        BillingEntityId: this.state.billingEntityValue ? this.state.billingEntityValue.id : null
      };

      await saveConversionStartdate(item);
    }

    let errorMessage = false;
    let trueCount = 0;
    let falseCount = 0;
    let currentUploadedFiles = [];

    //get mandatory doc types for status
    for(let el of this.state.allDocumentTypesForStatus){
      if(el.IsProjectMandatory){
        this.state.projMandatoryDocTypes.push(el.DocType);
      }
      if(el.IsCRMandatory){
        this.state.CRMandatoryDocTypes.push(el.DocType);
      }
    }

    //remove duplicate files
    function getUniqueListBy(fileUploadDetails, key) {
      return [...new Map(fileUploadDetails.map(item => [item[key], item])).values()];
    }
    currentUploadedFiles = getUniqueListBy(this.state.fileUploadDetails, 'DocType');

    //check for mandatory doc types
    if(currentUploadedFiles.length > 0){
      if(!(this.state.projectOrCr.ProjectId)){
        for(let docType of this.state.projMandatoryDocTypes){
          for(let upFile of currentUploadedFiles){
            if(docType === upFile.DocType){
              trueCount = trueCount+1;
            }
            else{
              falseCount = falseCount+1;
            }
          }
        }
      }
      else if(this.state.projectOrCr.ProjectId){
        for(let docType of this.state.CRMandatoryDocTypes){
          for(let upFile of currentUploadedFiles){
            if(docType === upFile.DocType){
              trueCount = trueCount+1;
            }
            else{
              falseCount = falseCount+1;
            }
          }
        }
      }
    }
    //save without mandatory document upload
    else if(currentUploadedFiles.length === 0){
      if((!this.state.projectOrCr.ProjectId) && (this.state.projMandatoryDocTypes.length > 0)){
        errorMessage = true;
      }
      else if((this.state.projectOrCr.ProjectId) && (this.state.CRMandatoryDocTypes.length > 0)){
        errorMessage = true;
      }
    }

    if(currentUploadedFiles.length > 0){
      if((!this.state.projectOrCr.ProjectId) && (trueCount === this.state.projMandatoryDocTypes.length)){
        errorMessage = false;
      }
      else if((this.state.projectOrCr.ProjectId) && (trueCount === this.state.CRMandatoryDocTypes.length)){
        errorMessage = false;
      }
      else{
        errorMessage = true;
      }
    }

    let poNumberError = false;
    if (this.state.selectedChangeToStatus && this.state.selectedChangeToStatus.PermissionDescription === 'Active' && 
        this.state.isPONumberRequired && (!this.state.poNumber || this.state.poNumber === '')) {
      poNumberError = true;
    }

    let billingEntityError = false;
    if (this.state.selectedChangeToStatus && this.state.selectedChangeToStatus.PermissionDescription === 'Active' && 
        !this.state.billingEntityValue) {
          billingEntityError = true;
    }

    if (this.state.selectedChangeToStatus && this.state.selectedChangeToStatus.PermissionDescription === 'Active') {
      const billingMethod = this.state.projectOrCr.ProjectId ? this.state.projectOrCr.PaymentMethodId : this.state.projectOrCr.PaymentMethod;
      if (billingMethod && billingMethod !== 2 && this.state.projectOrCr.Milestone && this.state.projectOrCr.Milestone.length <= 0) {
        this.setState({
          showErrorImg: true,
          loading: false
        });
        const msg = "Please add a milestone at Project Creation and try again."
        this.toggleErrorMessageDialog(msg, 'Error');
        return;
      }
    }

    if(startDateErrorMessage || closeStatusErrorMessage || errorMessage || pastStartDateErrorMessage || poNumberError || billingEntityError){
      if(closeStatusErrorMessage){
        this.setState({
          isErrorMessageVisible: true,
          loading: false
        });
      }
      if(startDateErrorMessage){
        this.setState({
          showStartDateValidationError: true,
          loading: false
        });
      }
      if(errorMessage){
        this.setState({
          DocUploadErrorMessageVisible: true,
          fileUploadMessageVisible: false,
          projMandatoryDocTypes: [],
          CRMandatoryDocTypes: [],
          loading: false
        });
      }
      if(pastStartDateErrorMessage){
        this.setState({
          showStartDateErrorMessage: true,
          loading: false
        });
      }
      if(poNumberError){
        this.setState({
          showPONumberErrorMessage: true,
          loading: false
        });
      }
      if(billingEntityError){
        this.setState({
          showBillingEntityErrorMessage: true,
          loading: false
        });
      }
      return;
    }

    let projectOrCr = this.state.projectOrCr;
    //update project/cr
    let status = ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value;
    let startDate = this.state.startdateStatus ? (moment(this.state.startdateStatus).format('YYYY-MM-DD')) : null;
    let fileUploadIds = [];
    let uploadedFiles = this.state.fileUploadDetails;
    let fileUpLength = this.state.fileUploadDetails.length;
    let statusComment = this.state.stsChangeComment;
    let changedDate = moment(new Date()).format('YYYY-MM-DD');
    let statusName = ProjStatuses[this.state.selectedChangeToStatus.PermissionName].name;

    for(let el of uploadedFiles){
      fileUploadIds.push(el.FileUpload.id);
    }

    let statusChangeObj = {
      //projectId : projectOrCr.id,
      projectId: projectOrCr.ProjectId ? projectOrCr.ProjectId : projectOrCr.id,
      crId: projectOrCr.ProjectId ? projectOrCr.id : null,
      projStartDate : startDate,
      projStatus : status,
      fileIds: fileUploadIds,
      comment: statusComment,
      changedDate: changedDate,
      IsWorkflowExist: false,
      isCr : projectOrCr.ProjectId ? true : false,
      wfId: null,
      createdBy: window.LOGGED_USER.displayName,
      activeOrWarStartDate: activeOrWarStartDate
    };

    await this.populateSortedActivityLogData(statusChangeObj.projectId,statusChangeObj.crId,false);

    if(this.state.sortedActivity.length === 1){
      this.setState(
          {
            prevState: null,
          })
    }
    else if(this.state.sortedActivity.length === 2){
      this.setState(
          {
            prevState: this.state.sortedActivity[1],
          })
    }

    let startDateWF = this.state.startdateStatus ? (moment(this.state.startdateStatus).format('YYYY-MM-DD')) : (moment(new Date()).format('YYYY-MM-DD'));

    //pick WF for status change
    selectWorkflowStatus(projectOrCr.CMSheets[0] ? projectOrCr.CMSheets[0].id:0,{ EstimatedStartDate: startDateWF, SelectedCurrency: projectOrCr.Currency, PrevStatus: this.state.prevState ? parseInt(this.state.prevState.Status) : 0, CurrentStatus: this.CROrProjectCurrentStatusId,EndStatus: statusChangeObj.projStatus,projectOrCr:projectOrCr}).then(res => {
      if (res.data.length > 1) {

        statusChangeObj.IsWorkflowExist = true;
        statusChangeObj.ApprovalStatus = ApprovalStatusChange.PENDING.value;

        this.setState(
            {
              matchingWorkflowList: res.data,
              cmsId : projectOrCr.CMSheets[0] ? projectOrCr.CMSheets[0].id : null,
              statusChangeObj:statusChangeObj,
              isMoreWorkflow:true
            },
            () => {
              this.toggleWorkflowSelectStatusDialog();
            }
        );
      } else if (res.data.length === 1) {

        statusChangeObj.IsWorkflowExist = true;
        statusChangeObj.ApprovalStatus = ApprovalStatusChange.PENDING.value;
        statusChangeObj.IsOneWorkflow = true;
        statusChangeObj.wfId = res.data[0].id;

         this.saveActivityLogWithStatus(statusChangeObj,projectOrCr,res.data[0].id);
      }
    else {
        this.saveActivityLogWithStatus(statusChangeObj,projectOrCr,null);
      }
    });

    //save on audit trail
    this.onSaveAuditTrail(statusChangeObj, statusName, projectOrCr.UniqueId);
  };

  navigateToDashboard = () => {
    this.setState({
      navigateToDashboard: true
    });
  };

  saveActivityLogWithStatus = async (statusChangeObj,projectOrCr,selectedWorkFlowId) => {
    this.setState({
      loading: true
    });

    await saveStatusChangeHistory(statusChangeObj).then(response => {
      this.setState({
            showSuccessImg: true,
            activityLogId:response.data.id
          },
          () => {
               if(statusChangeObj.IsWorkflowExist === false){
                  this.setState({
                    loading: false
                  });
                  this.toggleMessageDialog(`Status changed successfully`, 'Success');
                  this.toggleChangeStatusDialog();
          }

               if(statusChangeObj.IsOneWorkflow === true){
                 this.setState(
                     {
                       selectedWorkFlowId: selectedWorkFlowId
                     },
                     () => {
                       this.setWorkflowToStatus(projectOrCr.CMSheets[0] ? projectOrCr.CMSheets[0].id : null,statusChangeObj);
                     }
                 );
               }
          }
      );
    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
      this.toggleMessageDialog(error.response.data, 'Error');
    });
  }

  setWorkflowToStatus = async (cmSheetId,statusChangeObj) => {
    this.setState({
      loading: true
    });

    if(this.state.selectedWorkFlowId !== null){
      var statusChangeObjActivity = this.state.statusChangeObj;
      statusChangeObjActivity.wfId = this.state.selectedWorkFlowId;
      if(this.state.isMoreWorkflow === true) {
        await this.saveActivityLogWithStatus(statusChangeObjActivity, this.state.projectOrCr, null);
      }
      await setWorkflowStatus({ cmSheetId: cmSheetId, workflowId: this.state.selectedWorkFlowId ,statusChangeObj:statusChangeObj,activityLogId : this.state.activityLogId})
          .then(res => {
            if(this.state.isMoreWorkflow === true){
              this.toggleWorkflowSelectStatusDialog();
            }

              this.toggleMessageDialog(`Status Change submitted for Approval`, 'Success');
              this.toggleChangeStatusDialog();

            this.setState({ selectedWorkFlowId: null });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            if(this.state.isMoreWorkflow === true){
              this.toggleWorkflowSelectStatusDialog();
            }
            const message = error.response.data;
            const title = 'Error';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
            this.toggleMessageDialog(message, title);
          });

      this.setState({
        loading: false
      });


    }
  };

  onSaveAuditTrail = (statusChangeObj, statusName, uniqueId) => {
    this.setState({
      loading: true
    });

    let userName = window.LOGGED_USER.displayName;

    //project
    if(statusChangeObj.isCr === false){
      const auditTrailObj = {
        Action: 'Project status changed to ' + statusName,
        RefType: 'Project' ,
        RefId: statusChangeObj.projectId,
        RefName: uniqueId
      }
      saveAuditTrail(auditTrailObj);
    }
    //cr
    else{
      const auditTrailObj = {
        Action: 'CR status changed to ' + statusName,
        RefType: 'Change Request' ,
        RefId: statusChangeObj.crId,
        RefName: uniqueId
      }
      saveAuditTrail(auditTrailObj);
    }
  };

  populateActivityLogData = async (projectOrCr, resourceId) => {
    await getActivityLogData(resourceId).then(response => {
      let ProjActivityLogLst = [];
      let CrActivityLogLst = [];
      let changedDate = '';
      let selectedCrActivityLogLst = [];

      this.setState({
        allActivityLogList: response.data
      });

      //activityLog status name
      for(let activityLogItem of this.state.allActivityLogList){
        for(let status in ProjStatuses){
          if(ProjStatuses[status].value == activityLogItem.Status){
            activityLogItem.Status = ProjStatuses[status].name;
          }
        }
      }

      for(let el of this.state.allActivityLogList){
        changedDate = moment(el.changedDate).format('YYYY-MM-DD') ;
        el.changedDate = changedDate;
      }

      for(let el of this.state.allActivityLogList){
        if(el.CrId === null){
          ProjActivityLogLst.push(el);
        }
        else{
          CrActivityLogLst.push(el);
        }
      }

      if(projectOrCr.ProjectId){
        for(let el of CrActivityLogLst){
          if(el.CrId === projectOrCr.id){
            selectedCrActivityLogLst.push(el);
          }
        }

        this.setState({
          activityLogList: selectedCrActivityLogLst
        });
      }
      else{
        this.setState({
          activityLogList: ProjActivityLogLst
        });
      }

      let maxId = this.state.activityLogList.length > 0 ? (Math.max(...this.state.activityLogList.map(el => el.id))) : 0;
      let lastRecord = this.state.activityLogList.filter(el => el.id == maxId);

      if(lastRecord && lastRecord.length > 0){
        if(lastRecord[0].ApprovalStatus && lastRecord[0].ApprovalStatus === 'PENDING'){
          this.setState({
            isApprovalStatusPending: true,
            reqStatus: lastRecord[0].Status
          });

          let ProjOrCrId = lastRecord[0].CrId !== null ? lastRecord[0].CrId : lastRecord[0].ProjectId;
          let workflowId = lastRecord[0].WorkflowId;
          let type = lastRecord[0].CrId !== null ? 'ChangeRequest' : 'Project';

          const approvalObj = {
            ProjectId: ProjOrCrId,
            WorkflowId: workflowId,
            Type: type
          };

          this.populateApprovalData(approvalObj);
        }
      }

      // this.populateCurrentApprovals(projectOrCr.CRName ? projectOrCr.id : (projectOrCr.projectId ? projectOrCr.projectId : projectOrCr.id))

      let statusChangeDetails = [];
      let activityLogList = this.state.activityLogList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      for (const item of activityLogList) {
        if (item.ApprovalStatus === 'PENDING') {
          let activityLogObj = {
            CrId: item.CrId,
            ProjectId: item.ProjectId,
            FileUpload: item.FileUpload,
            Status: item.Status,
            DateTime: moment(item.createdAt).format("MM-DD-YYYY hh:mm:ss A"),
            changedBy: item.changedBy,
            Approver: item.changedBy,
            ApprovalStatus: '',
            Comment:item.Comment,
          }
          statusChangeDetails.push(activityLogObj);
          if (item.Approvals && item.Approvals.length > 0) {
            let Approvals = item.Approvals.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            for (const approval of Approvals) {
              let approvalObj = {
                CrId: item.CrId,
                ProjectId: item.ProjectId,
                FileUpload: [],
                Status: item.Status,
                DateTime: approval.Date ? moment(approval.Date).format("MM-DD-YYYY hh:mm:ss A") : "",
                changedBy: item.changedBy,
                Approver: approval.Resource.Name,
                ApprovalStatus: approval.Status,
                Comment:approval.Comment,
              }
              statusChangeDetails.push(approvalObj);
            }
          }
        } else if (item.WorkflowId == null){
          let activityLogObj = {
            CrId: item.CrId,
            ProjectId: item.ProjectId,
            FileUpload: item.FileUpload,
            Status: item.Status,
            DateTime: moment(item.createdAt).format("MM-DD-YYYY hh:mm:ss A"),
            changedBy: item.changedBy,
            Approver: item.changedBy,
            ApprovalStatus: '',
            Comment:item.Comment,
          }
          statusChangeDetails.push(activityLogObj);
        }
      }

      this.setState({  
        statusChangeGridDetails: statusChangeDetails,
      });

    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  populateApprovalData = async (approvalObj) => {
    await getApprovalsData(approvalObj).then(response => {
      this.setState({
        approvalsDetails: response.data
      });

      if(this.state.approvalsDetails && this.state.approvalsDetails.length > 0){
        let approvalsDetails = this.state.approvalsDetails;
        let approvers = [];
        
        approvalsDetails.forEach(el => {approvers.push(el.Resource.Name);})

        if(approvers.length > 1){
          var approversSet = "";
          var i = 1;

          for(let approver of approvers){
            if(i >= approvers.length){
              approversSet += approver + '';
            }
            else{
              approversSet += approver + ', ';
            }
            i++;
          }

          this.setState({
            approversName: approversSet
          });
        }
        else{
          this.setState({
            approversName: approvers
          });
        }
      }

    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  populateSortedActivityLogData = async (projectId,crId,isPending) => {
    this.setState({
      loading: true
    });

    let ids = [];
    ids  = {
      projectId : projectId,
      crId: crId,
      isPending:isPending,
      forPickWK:true
    }

    await getActivityLogDataSort(ids).then(response => {
      this.setState({
        sortedActivity: response.data
      });
    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  populateCurrentApprovals = async (projectId) => {
    await getStatusChangeApprovals(projectId)
        .then(res => {
          this.setState({
            approversList: res.data
          });

          for(const item of res.data){
            for(const obj of this.state.activityLogList){
              if(obj.id === item.ActivityLogId){
                obj.Approver = item.Approver
                obj.ApproverStatus = item.Status
              }
            }
          }

        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });


  };


  reDirectToSearch = event => {
    this.setState({
      toSearchPage: true
    });
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validateCommentProperty = value => {
    if (value && value.length > 0 && value != "<p><br></p>" ) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  unsubscribe = (id) =>{
    this.setState({
      id:id
      });
  }

  subscribe = async(id) =>{
      if(this.state.id !== 10)
      {
        await this.setState({
          id:id
        });
        if(this.state.id === 10)
        {
          this.setState({
            loading: true
          });
          searchProjectsNew(this.state.take).then(async res => {            
            this.setState({              
              searchState: true
            });
            if(res.data)
            {
              let projectIds = [];
              projectIds = res.data.rows.map(a=>a.id);
              await this.setState(prevState => {
                const SRequest = {
                 ...prevState.SearchRequest, projectIds: projectIds        
                }                
                return {
                  SearchRequest: SRequest
                };
              });
              searchProjectsCR(this.state.SearchRequest).then(response=>
              {
                  for(const proj of res.data.rows){
                    const filteredProject = response.data.find(pro => pro.id === proj.id);
                    if(filteredProject){
                      const index = res.data.rows.findIndex(a=>a.id=== proj.id);
                      res.data.rows[index].ChangeRequests = filteredProject.ChangeRequests;
                    }
                  }
                  this.setState({  
                    Projects: res.data,
                    loading: false
                  });                
              });
            }            
          });
          this.populateStatuses();
          this.populateCustomers();
          this.populateDivisions();
          this.populateRegions();
          this.populateProjectTypes();
          this.populatePaymentMethods();
          this.populateProjectIds();
          this.populateOPIDs();
          this.populatePMResources();
          this.populateCMSStatus();
          this.populateCRIds();
          this.populateCMSTypes();
          this.populateAllResources();
          this.populateLegalEntityTypess();          
        }        
      }
  }

  componentWillUnmount() {
    this.unsubscribe(10);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    this.setHeaderTitle();
    this.subscribe(10);    


    $(document).ready(function () {
      $("div.title-remove > span > span > span:last-child").removeAttr("title");
    });

  }


  populateSearchGirdData = async () => {
    searchProjectsPerforamnceFix(this.state.SearchRequest).then(async res => {
      this.setState({              
        searchState: true
      });
      if(res.data)
      {
        let projectIds = [];
        projectIds = res.data.rows.map(a=>a.id);
        await this.setState(prevState => {
          const SRequest = {
           ...prevState.SearchRequest, projectIds: projectIds        
          }                
          return {
            SearchRequest: SRequest
          };
        });
        await searchProjectsCR(this.state.SearchRequest).then(response=>
        {
            for(const proj of res.data.rows){
              const filteredProject = response.data.find(pro => pro.id === proj.id);
              if(filteredProject){
                const index = res.data.rows.findIndex(a=>a.id=== proj.id);
                res.data.rows[index].ChangeRequests = filteredProject.ChangeRequests;
              }
            }
            this.setState({  
              Projects: res.data,
              loading: false
            });                
        });
      }            
    });

  }

  populateAllResources = async () =>{
    await getProjectResources()
        .then(res => {
            if(res.data && res.data.length > 0)
            {
              this.setState({
              allResources:res.data
              });
            }
            else{
              this.setState({
                allResources:[]
                });
            }    
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Search Projects');
    }
  };

  toggleCusTooltip = () => {
    if (this.isMount) {
      this.setState({
        cusToolTipOpen: !this.state.cusToolTipOpen
      });
    }
  };

  toggleProgToolTip = () => {
    if (this.isMount) {
      this.setState({
        programToolTipOpen: !this.state.programToolTipOpen
      });
    }
  };

  toggleOpidToolTip = () => {
    if (this.isMount) {
      this.setState({
        opidToolTipOpen: !this.state.opidToolTipOpen
      });
    }
  };
  toggleProjNameToolTip = () => {
    if (this.isMount) {
      this.setState({
        projnameToolTipOpen: !this.state.projnameToolTipOpen
      });
    }
  };

  toggleProjIdToolTip = () => {
    if (this.isMount) {
      this.setState({
        projidToolTipOpen: !this.state.projidToolTipOpen
      });
    }
  };

  toggleStatusToolTip = () => {
    if (this.isMount) {
      this.setState({
        statusToolTipOpen: !this.state.statusToolTipOpen
      });
    }
  };

  togglePracticeToolTip = () => {
    if (this.isMount) {
      this.setState({
        practiceToolTipOpen: !this.state.practiceToolTipOpen
      });
    }
  };

  togglePMToolTip = () => {
    if (this.isMount) {
      this.setState({
        projectManagerTooltipOpen: !this.state.projectManagerTooltipOpen
      });
    }
  };

  OnActivate = async (event, id, isMoreCR, type, CRs, selectedProject) => {
    event.preventDefault();
    if (CRs.length > 1) {
      await this.setState({
        showCRSelectDialogOne: true,
        changeRequest: CRs,
        requestType: 'ChangeRequest',
        selectedProject: selectedProject
      });
    }
    else if (CRs.length === 1) {
      await this.setState({
        redirectToResourceReq: true,
        selectedCRId: id,
        requestType: 'ChangeRequest',
        ChangeRequestForResourceRequest: CRs[0],
        tempCR: CRs,
        selectedProject: selectedProject
      });
    }
  };

  toggleEngagementToolTip = () => {
    if (this.isMount) {
      this.setState({
        engagementToolTipOpen: !this.state.engagementToolTipOpen
      });
    }
  };

  toggleBillMethodToolTip = () => {
    if (this.isMount) {
      this.setState({
        billMethodToolTipOpen: !this.state.billMethodToolTipOpen
      });
    }
  };

  toggleBillRegionToolTip = () => {
    if (this.isMount) {
      this.setState({
        billRegionToolTipOpen: !this.state.billRegionToolTipOpen
      });
    }
  };

  toggleCMSToolTip = () => {
    if (this.isMount) {
      this.setState({
        cmsToolTipOpen: !this.state.cmsToolTipOpen
      });
    }
  };

  toggleCMSCRToolTip = () => {
    if (this.isMount) {
      this.setState({
        cmsToolTipCROpen: !this.state.cmsToolTipCROpen
      });
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      }, () => this.submitSearch(null));
    }
  };

  retrieveRecentActiveProjects = count => {
    this.setState({
      loading: true
    });
    getRecentActiveProjects(count)
      .then(res => {
        if (this.isMount) {
          this.setState({
            Projects: res.data,
            loading: false
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateProjectIds = () => {
    getAllUniqueIds()
      .then(res => {
        if (this.isMount) {
          this.setState({
            UniqueIds: res.data,
            UniqueIdsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCRIds = () => {
    getAllCRUniqueIds()
      .then(res => {
        if (this.isMount) {
          this.setState({
            UniqueCRIds: res.data,
            UniqueCRIdsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCMSTypes = () => {
    getCmsTypes().then(res => {
      this.setState({
        cmsTypes: res.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  validateStatusChangeFields = () => {

  };

  populateOPIDs = () => {
    getAllOPIDs()
      .then(res => {
        if (this.isMount) {
          this.setState({
            opids: res.data,
            opidsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populatePMResources = async () => {
    let resources = [];

    await getAllPMResources().then(res => {

      this.setState({
        resourceItemsPro: res.data
      });

      for(const obj of res.data){
        resources.push(obj)
      }

    })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

    // await getProjectOwners().then(response => {
    //   let resourceAll = [];

    //   this.resourceAllPool = response.data;
    //   this.setState({
    //     resourceItemsPool: response.data
    //   });

    //   for(const obj of response.data){
    //     let resource = {
    //       id:obj.id,
    //       Name:obj.displayName
    //     }
    //     resourceAll = resources.filter(obj => obj.id  === resource.id);
    //     if(resourceAll && resourceAll.length === 0){
    //       resources.push(resource)
    //     }
    //   }
    // })
    //     .catch(error => {
    //       loggerService.writeLog(error, LOG_TYPES.ERROR);
    //     });

    this.resourceAll = resources

    await this.setState({
      resourceItems: resources
    });
  };

  populateCMSStatus = () => {
    getCMSStatus()
      .then(res => {
        if (this.isMount) {
          this.CMSStatusAll = res.data;
          this.setState({
            CMSStatus: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  filterChangeResource = event => {
    this.setState({
      resourceItems: this.filterDataResource(event.filter)
    });
  };

  handleResourceChange = event => {
    this.setState({
      resourceValue: event.target.value,
    });
  };

  handleActivePMChange = event => {
    this.setState({
      activeProjectManager: event.target.value,
    });
  };

  handleOnChangeCMSStatus = event => {
    this.setState({
      SelectedCMSStatus: event.target.value,
    });
  };

  
  handleCmsTypeChange = event => {
    const value = event.target.value;

    this.setState({
      selectedCmsType: value
    });
  };

  populateStatuses = () => {
    getProjectStatuses()
      .then(res => {
        if (this.isMount) {
          let list = res.data;
          list = res.data.filter(obj => (obj.Code !== 'ON_HOLD'));

          this.setState({
            Statuses: list
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCustomers = () => {
    getcustomersIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            Customers: res.data,
            CustomersAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populatePrograms = customerId => {
    if (customerId) {
      getCustomerProgramsIntegrated(customerId)
        .then(res => {
          if (this.isMount) {
            this.setState({
              Programs: res.data,
              ProgramsAll: res.data
            });
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    } else {
      if (this.isMount) {
        this.setState({
          Programs: []
        });
      }
    }
  };

  populateDivisions = () => {
    getBillingDiviIntegrated()
      .then(res => { 
        if (this.isMount) {
          this.setState({
            Divisions: res.data,
            DivisionsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateLegalEntityTypess = () => {
    getLegalEntityTypes().then(response => {
      this.allBillingEntities = response.data;
      this.setState({
        billingEntityType: response.data
      });
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  populateRegions = () => {
    getRegionsIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            Regions: res.data,
            RegionsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateProjectTypes = () => {
    getProjectTypes()
      .then(res => {
        if (this.isMount) {
          this.setState({
            ProjectTypes: res.data,
            ProjectTypesAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populatePaymentMethods = () => {
    getPaymentMethods()
      .then(res => {
        if (this.isMount) {
          this.setState({
            PaymentMethods: res.data,
            PaymentMethodsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  handleOnChangeDropDown = async event => {
    const field = event.target.name;
    const value = event.target.value;

    if(field === 'UniqueCRId' && !value){
      if(this.state.Customer != null){
        this.setState({
          UniqueCRId : null,
          opids:  this.state.opidsAll.filter(val => val.CustomerId === this.state.Customer.id),
          UniqueIds: this.state.UniqueIdsAll.filter(val => val.CustomerId === this.state.Customer.id),
        });
      }
    }
    if (field === 'Customer' && value) {
      this.populatePrograms(value.id);
      if(this.state.UniqueCRId == null){
        this.setState({
          opids:  this.state.opidsAll.filter(val => val.CustomerId === value.id),
          UniqueIds: this.state.UniqueIdsAll.filter(val => val.CustomerId === value.id),
          UniqueCRIds: this.state.UniqueCRIdsAll.filter(val => val.CustomerId === value.id)
        });
      }
      else{
        this.setState({
          opids:  [],
          UniqueIds: [],
        });
      }
    }
    if (field === 'Customer' && !value) {
      if (this.isMount) {
        this.setState({
          Programs: [],
          Program: null,
          opids: this.state.opidsAll,
          UniqueIds: this.state.UniqueIdsAll,
          UniqueCRIds: this.state.UniqueCRIdsAll
        });
      }
    }
    if ((field === 'opidvalue' || field === 'UniqueId') && value) {
      this.setState({
        UniqueCRIds: this.state.UniqueCRIdsAll.filter(val => val.ProjectId === value.id)
      });    
    }
    if ((field === 'opidvalue' || field === 'UniqueId') && !value) {
      this.setState({
        UniqueCRIds: this.state.UniqueCRIdsAll
      });    
    }
    if (this.isMount) {
      this.setState({
        [field]: value
      });
    }
  };

  handleOnChangeInput = event => {
    const field = event.target.name;
    // const value = event.target.value;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (this.isMount) {
      this.setState(prevState => {
        return {
          SearchRequest: { ...prevState.SearchRequest, [field]: value }
        };
      });
    }
  };

  handleOnChangeDates = event => {
    const d = event.target.value;
    const field = event.target.name;
    if (d != null) {
      const month = d.getMonth() + 1;
      const dStr = d.getFullYear() + '-' + (month <= 9 ? '0' + month : month) + '-' + d.getDate();
      if (this.isMount) {
        this.setState(prevState => {
          return {
            [field]: d,
            SearchRequest: { ...prevState.SearchRequest, [field]: dStr }
          };
        });
      }
    }
  };

  submitSearch = (event) => {
    if (event) {
      event.preventDefault();
      this.setState({
        skip: 0,
        take: 15,
        sort: [{ field: 'updatedAt', dir: 'desc' }],
      });
    }
    const userDetails = window.LOGGED_USER;

    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    if (userDetails)
      if (this.isMount) {
        this.setState(
          prevState => {
            const SRequest = {
              ...prevState.SearchRequest,
              Statuses: prevState.ProjectStatuses.map(value => value.id),
              CustomerId: prevState.Customer && prevState.Customer.id ? prevState.Customer.id : null,
              ProgramId: prevState.Program && prevState.Program.id ? prevState.Program.id : null,
              BillingDivision: prevState.Division.map(value => value.id),
              BillingRegion: prevState.Region.map(value => value.id),
              ProjectType: prevState.ProjectType.map(value => value.id),
              PaymentMethod: prevState.PaymentMethod.map(value => value.id),
              UniqueId:
                prevState.UniqueId && prevState.UniqueId.UniqueId
                  ? prevState.UniqueId.UniqueId
                  : null,
              OPID: prevState.opidvalue && prevState.opidvalue.OPID ? prevState.opidvalue.OPID : null,
              ResourceId: prevState.resourceValue && prevState.resourceValue.id ? prevState.resourceValue.id : null,
              ActiveProjectManager: prevState.activeProjectManager && prevState.activeProjectManager.id ? prevState.activeProjectManager.id : null,
              CMSStatus: prevState.SelectedCMSStatus.map(value => value.value),
              CRId: prevState.UniqueCRId && prevState.UniqueCRId.UniqueId ? prevState.UniqueCRId.UniqueId : null,
              CMSType: prevState.selectedCmsType.map(value => value.value),
              StartDateBefore: prevState.StartDateBefore ? (moment(prevState.StartDateBefore).format('YYYY-MM-DD')) : null,
              skip: event ? 0 : this.state.skip,
              take: event ? 15 : this.state.take,
              sort: event || this.state.sort.length === 0 ? [{ field: 'updatedAt', dir: 'desc' }] : this.state.sort,
              loggedUserId : window.LOGGED_USER && window.LOGGED_USER.user ?  window.LOGGED_USER.user : null
            };
            if (prevState.SearchRequest.MyProjects) {
              SRequest.User = userDetails.userId;
            }
            return {
              SearchRequest: SRequest
            };
          },

          () => {
            this.populateSearchGirdData();
          }
        );
      }
  };

  cancelSearch = event => {
    if (this.isMount) {
      this.setState({
        SearchRequest: {
          ProjectName: '',
          StartDate: null,
          StartDateBefore: null,
          Statuses: [],
          UniqueId: null,
          BillableStatus: false,
          NonBillableStatus: false,
          CustomerId: null,
          ProgramId: null,
          OPID: '',
          BillingDivision: [],
          BillingRegion: [],
          ProjectType: [],
          PaymentMethod: [],
          MyProjects: false,
          FactoryProjects: false,
          ResourceId: null,
          CMSType: [],
          CRId: null,
          CMSStatus: []
        },
        ProjectStatuses: [],
        Customer: {},
        Program: {},
        Division: [],
        Region: [],
        ProjectType: [],
        PaymentMethod: [],
        opidvalue: null,
        StartDate: null,
        UniqueId: null,
        resourceValue: null,
        SelectedCMSStatus: [],
        UniqueCRId: null,
        selectedCmsType: [],
        StartDateBefore: null,
        activeProjectManager: null,
        skip: 0,
        take: 15
      },
        () => {
          this.populatePMResources();
          this.submitSearch(null);
        }
      );
    }
  };

  filterChangeCombo = event => {
    clearTimeout(this.timeout);
    const field = event.target.name;
    this.timeout = setTimeout(() => {
      switch (field) {
        case 'Customer': {
          if (this.isMount) {
            this.setState({
              Customers: this.filterComboData(event.filter, this.state.CustomersAll)
            });
          }
          break;
        }
        case 'Program': {
          if (this.isMount) {
            this.setState({
              Programs: this.filterComboData(event.filter, this.state.ProgramsAll)
            });
          }
          break;
        }
        case 'Division': {
          if (this.isMount) {
            this.setState({
              Divisions: this.filterComboData(event.filter, this.state.DivisionsAll)
            });
          }
          break;
        }
        case 'Region': {
          if (this.isMount) {
            this.setState({
              Regions: this.filterComboData(event.filter, this.state.RegionsAll)
            });
          }
          break;
        }
        case 'ProjectType': {
          if (this.isMount) {
            this.setState({
              ProjectTypes: this.filterComboData(event.filter, this.state.ProjectTypesAll)
            });
          }
          break;
        }
        case 'PaymentMethod': {
          if (this.isMount) {
            this.setState({
              PaymentMethods: this.filterComboData(event.filter, this.state.PaymentMethodsAll)
            });
          }
          break;
        }
        case 'UniqueId': {
          if (this.isMount) {
            this.setState({
              UniqueIds: this.filterComboData(event.filter, this.state.UniqueIdsAll)
            });
          }
          break;
        }
        case 'opidvalue': {
          if (this.isMount) {
            this.setState({
              opids: this.filterComboData(event.filter, this.state.opidsAll)
            });
          }
          break;
        }
        case 'ProjectManager': {
          if (this.isMount) {
            this.setState({
              resourceItems: this.filterComboData(event.filter, this.resourceAll)
            });
          }
          break;
        }
        case 'ActiveProjectManager': {
          if (this.isMount) {
            this.setState({
              resourceItems: this.filterComboData(event.filter, this.resourceAll)
            });
          }
          break;
        }
        case 'UniqueCRId': {
          if (this.isMount) {
            this.setState({
              UniqueCRIds: this.filterComboData(event.filter, this.state.UniqueCRIdsAll)
            });
          }
          break;
        }
        default: {
        }
      }
    }, 0);
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  deleteProjectById = (projectId) => {
    if (this.isMount) {
      this.setState(
        {
          projectIdToDelete: projectId
        },
        () => {
          this.toggleConfirmDialog(
            'Are you sure, you want to delete the project?',
            this.deleteProject
          );
        }
      );
    }
  };

  deleteProject = () => {
    deleteProject(this.state.projectIdToDelete, this.state.projectNameToDelete)
      .then(res => {
        const currentProjects = this.state.Projects.rows;
        const index = currentProjects.findIndex(pro => pro.id === this.state.projectIdToDelete);
        if (index > -1) {
          currentProjects.splice(index, 1);
        }
        if (this.isMount) {
          this.setState({
            projectIdToDelete: null,
            projectNameToDelete: null
          });
        }
        this.toggleConfirmDialog('', null);
        this.toggleMessageDialog('Successfully Deleted', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleMessageDialog(error.response.data, 'Error');
      });
  };

  onClickDeleteCMSheet = (project, cmSheets, projectCMSheets, crCMSheets) => {
    if (this.isMount) {
      this.setState({
        cmSheetsArray: cmSheets,
        crCMSheets: crCMSheets,
        projectCMSheets: projectCMSheets
      },
        () => {
          if (cmSheets.length === 1) {
            this.deleteCMSByID(cmSheets[0].id)
          } else {
            this.toggleCMSSelectDialog();
          }
        });
    }
  };

  setCMSIdtoDelete = (id, data) => {
    if (this.isMount) {
      this.setState(
        {
          cmSheetIdToDelete: id
        });
    }
  };

  deleteCMSByID = (id) => {
    if (this.isMount) {
      this.setState(
        {
          cmSheetIdToDelete: id
        },
        () => {
          this.toggleConfirmDialog(
            'Are you sure, you want to delete the CM Sheet?',
            this.deleteCMSheet
          );
        }
      );
    }
  };

  deleteCMSheet = () => {
    deleteCMSheet(this.state.cmSheetIdToDelete)
      .then(res => {
        this.setState({
          loading: true
        });
        this.populateSearchGirdData();
        if (this.isMount) {
          this.setState({
            cmSheetIdToDelete: null
          });
        }
        if (this.state.CMSDialog) {
          this.toggleCMSSelectDialog()
        }
        if (this.state.showConfirmDialog) {
          this.toggleConfirmDialog('', null);
        }
        this.toggleMessageDialog('Successfully deleted', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleMessageDialog(error.response.data, 'Error');
      });
  };

  toggleCMSSelectDialog = () => {
    if (this.isMount) {
      this.setState({
        CMSDialog: !this.state.CMSDialog,
        cmSheetIdToDelete: null,
        selectedCRId: null
      });
    }
  };

  redirectToProjectCreation = () => {
    if (this.isMount) {
      this.setState({
        redirectToProjectCreate: true
      });
    }
  };

  handleStatusPopUpCR = async (event, Cr, action) => {
    await getCRsOfAProject(Cr.id).then(async res => {
      if (res.data.length > 0) {
        if (this.isMount) {
          await this.setState({
            CRsOfAProject: res.data,
            ChangeRequestAction: action,
            statusChangeCr: Cr
          },
          async () => {
              if (res.data.length === 1) {
                await this.setState({
                  selectedCRId: res.data[0].id
                });
                this.editCR();
              } else {
                this.toggleWorkflowSelectDialog();
              }
            });
        }
      } else {
        this.toggleMessageDialog('No CRs found', 'Error');
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  handleEditCRbtnClick = async (even, project, action, isProjectManager, hasEditCRPermission, crListOfCRManager, crWithNewlyAllocatedPMs, crWithExistingPM) => {
    if (action === 'view') {
      await getCRsOfAProject(project.id).then(res => {
        if (res.data.length > 0) {
          if (this.isMount) {
            this.setState({
              CRsOfAProject: res.data,
              ChangeRequestAction: action
            },
              () => {
                if (res.data.length === 1) {
                  this.setState({
                    selectedCRId: res.data[0].id,
                    IsPoolProject: res.data[0].IsPoolProject
                  });
                  this.editCR();
                } else {
                  this.toggleWorkflowSelectDialog();
                }
              });
          }
        } else {
          this.toggleMessageDialog('No CRs found', 'Error');
        }
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    } else {
      await getCRsOfAProject(project.id).then(res => {
        const projectManager = isProjectManager;
        const editCRPermission = hasEditCRPermission;
        const changeRequestsAsCRManager = crListOfCRManager;

        let presaleCRs = null;
        let changeReqWithoutPendingCMSApprovals = [];
        let changeReqWithoutPendingStatusApproval = [];

        if (projectManager === true || (editCRPermission && editCRPermission.length > 0)) {
          let crs = res.data;

          if(crs && crs.length > 0){
            for(let cr of crs){
              if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
                cr.ActivityLogs.reverse(); // get the descending order
                if(cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING'){
                  continue;
                }
                else{
                  changeReqWithoutPendingStatusApproval.push(cr);
                }
              }
            }
          }

          presaleCRs = changeReqWithoutPendingStatusApproval.filter(obj => (obj.Status.Code === 'PRESALES' || obj.Status.Code === 'WAIT_CONFIRM' ||
            obj.Status.Code === 'BILLABLE' || obj.Status.Code === 'ON_HOLD' || obj.Status.Code === 'PROJECT_ON_HOLD' || 
            obj.Status.Code === 'DEAL_ON_HOLD' || obj.Status.Code === 'WORK_AT_RISK') && obj.IsActive === true );

          presaleCRs.forEach(function (changeReq) {
            if (changeReq && changeReq.CMSheets && changeReq.CMSheets.length > 0 && changeReq.CMSheets[0].Status !== 'PENDING_APPROVALS') {
              changeReqWithoutPendingCMSApprovals.push(changeReq);
            } else if (changeReq && changeReq.CMSheets && changeReq.CMSheets.length === 0) {
              changeReqWithoutPendingCMSApprovals.push(changeReq);
            }
          });

        } else {
          let changeRequests = []
          if (project.ChangeRequests && project.ChangeRequests.length > 0) {
            for (const cr of project.ChangeRequests) {
              // if (cr && cr.ResourceRequestStatus && cr.ResourceRequestStatus === 'PM_ALLOCATED') {
              //   if (cr.AllocatedPM && cr.AllocatedPM.length > 0) {
              //     for (const allocatedPM of cr.AllocatedPM) {
              //       if (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
              //         changeRequests.push(cr)
              //       }
              //     }
              //   }
              // } else {
              //   if (window.LOGGED_USER.userId.toUpperCase() === cr.PresalesResource.Email.toUpperCase() && cr.IsActive === true) {
              //     changeRequests.push(cr)
              //   }

              // }
              if (cr.AllocatedPM && cr.AllocatedPM.length > 0) {
                //valid managers
                const crProjectManagers = cr.AllocatedPM;
          
                //find valid pms from end date
                let validManagers = [];
                validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())));
          
                if (validManagers.length === 0 && crProjectManagers.length > 0) {
                  crProjectManagers.sort(function (a, b) {
                    return new Date(b.EndDate) - new Date(a.EndDate)
                  })
                  const lastAssignedManager = crProjectManagers[0];
                  validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate));
          
                }
          
                validManagers.forEach(value => {
                  if (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()) {
                    changeRequests.push(cr)
                  }
                });
              }
            }
          }

          if(changeRequests && changeRequests.length > 0){
            for(let cr of changeRequests){
              if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
                if(cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING'){
                  continue;
                }
                else{
                  changeReqWithoutPendingStatusApproval.push(cr);
                }
              }
            }
          }


          presaleCRs = changeReqWithoutPendingStatusApproval.filter(obj => (obj.Status.Code === 'PRESALES' || obj.Status.Code === 'WAIT_CONFIRM' ||
            obj.Status.Code === 'BILLABLE' || obj.Status.Code === 'ON_HOLD' || obj.Status.Code === 'PROJECT_ON_HOLD' || 
            obj.Status.Code === 'DEAL_ON_HOLD' || obj.Status.Code === 'WORK_AT_RISK') && obj.IsActive === true);

          presaleCRs.forEach(function (changeReq) {
            if (changeReq && changeReq.CMSheets && changeReq.CMSheets.length > 0 && changeReq.CMSheets[0].Status !== 'PENDING_APPROVALS') {
              changeReqWithoutPendingCMSApprovals.push(changeReq);
            } else if (changeReq && changeReq.CMSheets && changeReq.CMSheets.length === 0) {
              changeReqWithoutPendingCMSApprovals.push(changeReq);
            }
          });
        }

        if (res.data.length > 0) {
          if (this.isMount) {
            this.setState({
              CRsOfAProject: changeReqWithoutPendingCMSApprovals,
              ChangeRequestAction: action
            },
              () => {
                if (changeReqWithoutPendingCMSApprovals.length === 1) {
                  this.setState({
                    selectedCRId: changeReqWithoutPendingCMSApprovals[0].id,
                    IsPoolProject: changeReqWithoutPendingCMSApprovals[0].IsPoolProject
                  });
                  this.editCR();
                } else if (changeReqWithoutPendingCMSApprovals.length > 1) {
                  this.toggleWorkflowSelectDialog();
                }
              });
          }
        } else {
          this.toggleMessageDialog('No CRs found', 'Error');
        }
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }
  };


  handleCreateCMSforCR = (changeReqList, project, action) => {
    if (this.isMount) {
      this.setState({
        CRsOfAProject: changeReqList,
        ChangeRequestAction: action,
        ProjIdOfSelectedCR: project.id,
          isACR: true
      },
        async() => {
          if (changeReqList.length === 1) {
            if (this.isMount) {
              this.setState({
                selectedCRId: changeReqList[0].id,
                //redirectToCreateCMS: true,
                selectedCMSReference: 'crCMS'
              });
            }
            //linking to pool project
            this.setState({
              CMSCR: changeReqList[0]
            });
            let poolProjectListAll = [];
            const data = {
              regionId : changeReqList[0].BillingRegion,
              practiceId : changeReqList[0].BillingDivisionId,
              offeringId : changeReqList[0].OfferingId
            };
            poolProjectListAll = await getFactoryProjByRegionAndPractice(data);

            if(poolProjectListAll.data && poolProjectListAll.data.length > 0){
              //pool project pop up
              this.poolProjectsAll = poolProjectListAll.data;
              await this.setState({
                poolProjectsAll: poolProjectListAll.data
              });
              this.toggleLinkProjToPoolDialog();
            }else{
              //create normal cr - direct to create cms page
              this.setState({
                redirectToCreateCMS: true,
              });
            }
          } else {
            this.toggleWorkflowSelectDialog();
          }
        });
    }
  };

  handleCreateCMSforCRPool = (changeReqList, project, action) => {
    if (this.isMount) {
      this.setState({
        CRsOfAProject: changeReqList,
        ChangeRequestAction: action,
        ProjIdOfSelectedCR: project.id,
        IsPoolProject:true
      },
        () => {
          if (changeReqList.length === 1) {
            if (this.isMount) {
              this.setState({
                selectedCRId: changeReqList[0].id,
                redirectToCreateCMSPool: true,
                selectedCMSReference: 'crCMS'
              });
            }
          } else {
            this.toggleWorkflowSelectDialog();
          }
        });
    }
  };

  onCreateCMS = async (project, action) => {
    this.setState({
      CMSProject: project,
      isAProj: true
    });
    let poolProjectListAll = [];
    if(project.IsPoolProject !== true){
      const data = {
        regionId : project.BillingRegion,
        practiceId : project.BillingDivision,
        offeringId : project.OfferingId
      };
      poolProjectListAll = await getFactoryProjByRegionAndPractice(data);
    }

    if(poolProjectListAll.data && poolProjectListAll.data.length > 0){
      //pool project pop up
      this.poolProjectsAll = poolProjectListAll.data;
      await this.setState({
        poolProjectsAll: poolProjectListAll.data
      });
      this.toggleLinkProjToPoolDialog();
    }else{
      //create normal project - direct to create cms page
      this.setState({
        createCMS : true
      });
    }

  };

  toggleLinkProjToPoolDialog = async () => {
    await this.setState({
      linkToPoolDialog: !this.state.linkToPoolDialog
    });
  };

  onCreateNormalProject = () => {
    this.setState({
      createCMS : true
    });
  };

  onCreateFactoryproject = async() => {
    //link project to Factory project
    this.state.CMSProject.PoolProjectId = this.state.selectedPoolProject.id;
    await updateLinkToFactory(this.state.CMSProject, this.state.CMSProject.id)
      .then(res => {
        this.setState({
          createCMS : true
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  onCreateFactoryprojectCR = async() => {
    //link project to Factory project
    this.state.CMSCR.PoolProjectId = this.state.selectedPoolProject.id;
    await updateLinkToFactoryCR(this.state.CMSCR, this.state.CMSCR.id)
      .then(res => {
        this.setState({
          redirectToCreateCMS: true,
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  onCreateNormalProjectCR = () => {
    this.setState({
      redirectToCreateCMS: true,
    });
  };

  handleComboChangePoolProject = async event => {
    await this.setState({
      selectedPoolProject: event.target.value
    });
    this.setState({ key: Math.random() });
    //this.forceUpdate();
  };

  filterChangeComboPool = async(event) => {
    await this.setState({
      poolProjectsAll: this.filterDataDivision(event.filter)
    });
  };

  filterDataDivision(filter) {
      const data = this.poolProjectsAll.slice();

    return filterBy(data, filter);
  }

  onCRSelectResourceRequest = () => {
    this.setState({
      redirectToResourceReq: true
    });
  }

  toggleWorkflowSelectDialog = () => {
    if (this.isMount) {
      this.setState({
        showCRSelectDialog: !this.state.showCRSelectDialog,
        selectedCRId: null,
        IsPoolProject: false,
      });
    }
  };

  toggleWorkflowSelectStatusDialog = () => {
    if (this.isMount) {
      this.setState({
        selectedCRId: null,
        IsPoolProject: false,
        loading:false,
        showWorkflowSelectDialog: !this.state.showWorkflowSelectDialog,
      });
    }
  };

  toggleViewCMSSelectDialog = () => {
    if (this.isMount) {
      this.setState({
        viewCMSDialog: !this.state.viewCMSDialog,
        selectedCMSId: null
      });
    }
  };

  editCR = async() => {
    if (this.state.ChangeRequestAction === 'CreateCMSforCR') {
      if (this.isMount) {
        this.setState({
          selectedCMSReference: 'crCMS'
        });
        //linking to pool project
        let poolProjectListAll = [];
        const data = {
          regionId : this.state.CMSCR.BillingRegion,
          practiceId : this.state.CMSCR.BillingDivisionId,
          offeringId : this.state.CMSCR.OfferingId
        };
        poolProjectListAll = await getFactoryProjByRegionAndPractice(data);
        if(poolProjectListAll.data && poolProjectListAll.data.length > 0){
          //pool project pop up
          this.poolProjectsAll = poolProjectListAll.data;
          await this.setState({
            poolProjectsAll: poolProjectListAll.data
          });
          this.toggleLinkProjToPoolDialog();
        }else{
          //create normal cr - direct to create cms page
          this.setState({
            redirectToCreateCMS: true
          });
        }
      }
      return;
    }

    if (this.state.ChangeRequestAction === 'CreateCMSforCRPool') {
      if (this.isMount) {
        this.setState({
          redirectToCreateCMSPool: true,
          selectedCMSReference: 'crCMSPool'
        });
      }
      return;
    }

    if (this.state.ChangeRequestAction === 'delete') {
      deleteChangeReq(this.state.selectedCRId).then(res => {
        if (this.isMount) {
          this.setState({
            selectedCRId: null
          });
        }

        this.toggleMessageDialog('Successfully deleted', 'Success');
        this.toggleWorkflowSelectDialog();
        this.setState({
          loading: true
        });

        this.populateSearchGirdData();
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

      return;
    }

    if (this.state.ChangeRequestAction === 'HandleCrPopUp') {

      // Close the CR selection popup
      if (this.isMount && this.state.showCRSelectDialog === true) {
        this.setState({
          showCRSelectDialog: !this.state.showCRSelectDialog
        });
      }

      // do the permission validation to open the dialog
      this.statusChangePermission(this.state.statusChangeCr, 'ChangeRequest');
      this.toggleChangeStatusDialog();

      return;
    }

    if (this.isMount) {
      this.setState({
        redirectToEditCR: true
      });
    }

  };

  viewEditCMS = async () => {
    if (this.state.cmsAction === 'edit' && this.state.IsPoolProject === false) {
      if (this.isMount) {
        this.setState({
          redirectToEditCMS: true
        });
      }
    } else if (this.state.cmsAction === 'edit' && this.state.IsPoolProject === true) {
      if (this.isMount) {
        this.setState({
          redirectToEditCMSPool: true
        });
      }
    }
    else if (this.state.cmsAction === 'view' && this.state.IsPoolProject === false) {
      if (this.isMount) {
        this.setState({
          redirectToEditViewCMS: true
        });
      }
    } else if (this.state.cmsAction === 'view' && this.state.IsPoolProject === true) {
      if (this.isMount) {
        this.setState({
          redirectToEditViewCMS: true
        });
      }
    }
    else if (this.state.cmsAction === 'revise') {
      if (this.isMount) {
        this.setState({
          redirectToReviseCMS: true
        });
      }
    } else if (this.state.cmsAction === 'approve') {
      if (this.isMount) {
        this.setState({
          redirectToApproveCMS: true
        });
      }
    } else if (this.state.cmsAction === 'withdraw') {
        this.toggleCMSWithdrawDialog(
          'You are about to withdraw this CMS from the approval workflow. You will have to submit the CMS for approval again. Do you wish to continue?',
          this.withdrawCMS
        );    
    } else if (this.state.cmsAction === 'requestReviseCMS') {
      this.setState({
        viewCMSDialog: false
      });
      const selectedProjectOrCR = this.state.selectedProjectOrCR;
      const projData = {
        ProjectId: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.ProjectId : selectedProjectOrCR.id,
        CrId: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.id : null,
      }
      await getAllPendingTimeEntriesForReviseCms(projData).then(res => {
        if(res.data && res.data.length > 0){
          this.setState({
            showErrorImg: true
          });
          const msg = "There are pending time approvals in this project/CR. Please approve/reject them before submitting a request for CMS revision."
          this.toggleErrorMessageDialog(msg, 'Error');
        } else {
          this.toggleRequestReviseCMSDialog(
            'Do you want to request for a CMS revision? Revising the CMS will invalidate all existing Resource Requests and you will have to resubmit new Resource Requests. Do you wish to continue?',
            this.submitRequestReviseCMS
          );      
        }
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      }); 
    }
  };

  viewEditCMSPool = () => {

    if (this.state.cmsAction === 'edit') {
      if (this.isMount) {
        this.setState({
          redirectToEditCMSPool: true
        });
      }
    } else if (this.state.cmsAction === 'view') {
      if (this.isMount) {
        this.setState({
          redirectToEditViewCMS: true
        });
      }
    }
    else if (this.state.cmsAction === 'revise') {
      if (this.isMount) {
        this.setState({
          redirectToReviseCMS: true
        });
      }
    } else if (this.state.cmsAction === 'approve') {
      if (this.isMount) {
        this.setState({
          redirectToApproveCMS: true
        });
      }
    } else if (this.state.cmsAction === 'withdraw') {
        this.toggleViewCMSSelectDialog();
        this.toggleCMSWithdrawDialog(
          'You are about to withdraw this CMS from the approval workflow. You will have to submit the CMS for approval again. Do you wish to continue?',
          this.withdrawCMS
      );  
    }
  };

  handleOnViewCMS = async(project, action, projectCMS_, crCMS) => {
    if (this.isMount) {
      this.setState({
        projectIdOfSelectedCMS: project.id,
        projectCMS: projectCMS_,
        changeRequestCMS: crCMS,
        cmsAction: action
      },
        async () => {
          if (projectCMS_.length === 1 && crCMS.length === 0) {
            await this.setSelectedCMSId(projectCMS_[0].data.id, projectCMS_[0].data, 'projectCMS');
            this.viewEditCMS();
          } else if (projectCMS_.length === 0 && crCMS.length === 1) {
            await this.setSelectedCMSId(crCMS[0].data.id, crCMS[0].data, 'crCMS');
            this.viewEditCMS();
          } else {
            this.toggleViewCMSSelectDialog();
          }
        });
    }
  };

  handleOnViewCMSPool = (project, action, projectCMS_, crCMS) => {
    if (this.isMount) {
      this.setState({
        projectIdOfSelectedCMS: project.id,
        projectCMS: projectCMS_,
        changeRequestCMS: crCMS,
        cmsAction: action,
        IsPoolProject:true
      },
        () => {
          if (projectCMS_.length === 1 && crCMS.length === 0) {
            this.setSelectedCMSId(projectCMS_[0].data.id, projectCMS_[0].data, 'projectCMS');
            this.viewEditCMSPool();
          } else if (projectCMS_.length === 0 && crCMS.length === 1) {
            this.setSelectedCMSId(crCMS[0].data.id, crCMS[0].data, 'crCMS');
            this.viewEditCMSPool();
          } else {
            this.toggleViewCMSSelectDialog();
          }
        });
    }
  };


  dialogStatus = (status) => {
    if (status) {
      switch (status) {
        case 'IN_PROGRESS': {
          return 'In Progress';
          break;
        }
        case 'PENDING_APPROVALS': {
          return 'Pending Approvals';
          break;
        }
        case 'APPROVED': {
          return 'Approved';
          break;
        }
        case 'REJECTED': {
          return 'Rejected';
          break;
        }
        case 'EXPIRED': {
          return 'Expired';
          break;
        }
        case 'WITHDRAWN': {
          return 'Withdrawn';
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  toggleCRSelectDialog = () => {
    this.setState({
      showCRSelectDialogOne: !this.state.showCRSelectDialogOne,
      selectedCRId: null
    });
  };

  handleDeleteCR = (even, project, action) => {
    getCRsOfAProject(project.id).then(res => {
      const presaleCRs = res.data.filter(obj => obj.Status.Code === 'PRESALES' && obj.CMSheets.length === 0);
      if (presaleCRs.length > 0) {
        if (this.isMount) {
          this.setState({
            CRsOfAProject: presaleCRs,
            ChangeRequestAction: action
          },
            () => {
              if (presaleCRs.length === 1) {
                this.setState({
                  selectedCRId: presaleCRs[0].id
                });
                this.toggleConfirmDialog(
                  'Are you sure, you want to delete the CR?',
                  this.deleteSingleCR
                );
              } else {
                this.toggleWorkflowSelectDialog();
              }
            });
        }
      } else {
        this.toggleMessageDialog('No CRs found', 'Error');
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  deleteSingleCR = () => {
    deleteChangeReq(this.state.selectedCRId).then(res => {
      if (this.isMount) {
        this.setState({
          selectedCRId: null
        });
      }

      this.toggleConfirmDialog('', null);
      this.toggleMessageDialog('Successfully deleted', 'Success');
      this.setState({
        loading: true
      });
      this.populateSearchGirdData();
    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  setSelectedCRId = (crId, IsPoolProject, cr) => {
    if (this.isMount) {
      this.setState({
        selectedCRId: crId,
        IsPoolProject: IsPoolProject,
        CMSCR: cr
      });
    }
  };

  setSelectedCRIdResourceRequest = (crId, cr) => {
    const tempCRArray = [];
    tempCRArray.push(cr)
    if (this.isMount) {
      this.setState({
        selectedCRId: crId,
        tempCR: tempCRArray
      });
    }
  };

  setSelectedCMSId = (cmsId, cmSheet, reference) => {
    if (this.isMount) {
      this.setState({
        selectedCMSId: cmsId,
        selectedCMSReference: reference,
        selectedCMSheet: cmSheet.CMSheets[0],
        selectedCRId: cmSheet.id,
        selectedProjectOrCR: cmSheet
      });
    }
  };

  setSelectedWorkflowId = async workflowId => {
    await this.setState({
      selectedWorkFlowId: workflowId
    });
  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleConfirmDialog = async (message, action) => {
    if (this.isMount) {
      await this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
      if (this.state.showConfirmDialog == false) {
        await this.setState({
          selectedChangeToStatus: null,
        });
      }
    }
  };

  toggleCMSWithdrawDialog = (message, action) => {
    this.setState({
        showCMSWithdrawDialog: !this.state.showCMSWithdrawDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action,
        isErrorMessageVisible: false
    });
  };

  toggleErrorMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showErrorMessageDialog: !this.state.showErrorMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleRequestReviseCMSDialog = (message, action) => {
    this.setState({
        showRequestReviseCMSDialog: !this.state.showRequestReviseCMSDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action,
        requestReviseCMSReason: null,
        isErrorMessageVisible: false
    });
  };

  handleReviseCMSReasonInputChange = event => {
    const value = event.target.value;
    this.setState({
      requestReviseCMSReason: value
    });
  };

  handleWithdrawReasonInputChange = event => {
    const value = event.target.value;
    this.setState({
      withdrawReason: value
    });
  };

  render() {
    if (this.state.navigateToDashboard === true) {
      return <Redirect to="/" />;
    }
    if (this.state.redirectToProjectCreate === true) {
      return <Redirect to="/projects/create/create" />;
    }
    if (this.state.redirectToEditCR === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            pathname: '/changeRequest/edit',
            changeReqId: this.state.selectedCRId,
            action: this.state.ChangeRequestAction
          }}
        />
      );
    }
    if (this.state.toSearchPage === true) {
      return <Redirect to="/projects/search/search" />;
    }

    if (this.state.redirectToEditCR === true && this.state.IsPoolProject === true) {
      return (
        <Redirect
          to={{
            pathname: '/poolProjectRevision/poolRevision',
            changeReqId: this.state.selectedCRId,
            action: this.state.ChangeRequestAction
          }}
        />
      );
    }

    // Create only
    if (this.state.redirectToCreateCMS === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.ProjIdOfSelectedCR,
            reference: this.state.selectedCMSReference,
            pathname: '/projects/cmsheet/cmsheet',
            action: 'create'
          }}
        />
      );
    }

    // Create only pool cms
    if (this.state.redirectToCreateCMSPool === true && this.state.IsPoolProject === true) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.ProjIdOfSelectedCR,
            reference: this.state.selectedCMSReference,
            pathname: '/PoolProject/FactoryProjectCMS',
            action: 'create'
          }}
        />
      );
    }
    // View only
    if (this.state.redirectToEditViewCMS === true && this.state.IsPoolProject === true) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/PoolProject/FactoryProjectCMS',
            action: 'view'
          }}
        />
      );
    }
    if (this.state.redirectToEditViewCMS === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/projects/cmsheet/cmsheet',
            action: 'view'
          }}
        />
      );
    }

    // Edit only
    if (this.state.redirectToEditCMS === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/projects/cmsheet/cmsheet',
            action: 'edit'
          }}
        />
      );
    }

    if (this.state.redirectToEditCMSPool === true && this.state.IsPoolProject === true) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/PoolProject/FactoryProjectCMS',
            action: 'edit'
          }}
        />
      );
    }

    if (this.state.redirectToResourceReq === true) {
      return (
        <Redirect
          to={{
            changeReqId: null,
            ProjectId: this.state.selectedCRId,
            Type: this.state.requestType,
            ChangeRequest: this.state.tempCR,
            selectedProject: this.state.selectedProject,
            pathname: '/ResourceManagement/ResourceRequestActivation',
          }}
        />
      );
    }

    if (this.state.redirectToReviseCMS === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/projects/cmsheet/cmsheet',
            action: 'revise'
          }}
        />
      );
    }

    if (this.state.redirectToReviseCMS === true && this.state.IsPoolProject === true) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/PoolProject/FactoryProjectCMS',
            action: 'revise'
          }}
        />
      );
    }

    if (this.state.redirectToApproveCMS === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/projects/cmsheet/cmsheet',
            action: 'approve'
          }}
        />
      );
    }

    if (this.state.redirectToApproveCMS === true && this.state.IsPoolProject === true) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/PoolProject/FactoryProjectCMS',
            action: 'approve'
          }}
        />
      );
    }

    if (this.state.createCMS === true) {
      return (
        <Redirect
          to={{
            pathname: '/projects/cmsheet/cmsheet',
            projectId: this.state.CMSProject.id,
            reference: 'projectCMS',
            action: 'create'
          }}
        />
      );
    }

    //show/hide file upload button overlay div
    var fileUploadDivStyle = {
      display: ((!this.state.disableFileUpBtnDiv) || (this.state.selectedChangeToStatus == null) || (this.state.invalidatePermission)) ? 'block' : 'none'
    };

    return (
      <div ref={this.myRef}>
        <div className="main-card">
          <div className="row">
            <div className="col-md-8">
              <div className="main-heading">Project Details</div>
            </div>

            <div className="col-md-4">
              <PermissibleRender
                userPermissions={window.USER_PERMISSION}
                requiredPermissions={['CRT_PRJ']}
              >
                <Button
                  primary={true}
                  type="button"
                  onClick={this.redirectToProjectCreation}
                  className="pull-right"
                >
                  Create New Project
                  </Button>
              </PermissibleRender>
            </div>
          </div>

          <form onSubmit={this.submitSearch}>
            <div className="row">
              <div className="col-md-12 btn-align-right" />
            </div>

            <div className="row">
              <div className="col-md-2">
                <div className="d-block">
                  <label>Project Name:</label>
                </div>
                <div className="d-block">
                  <div className="" id="projnameToolTip">
                    <Input
                      value={this.state.SearchRequest.ProjectName}
                      onChange={this.handleOnChangeInput}
                      name="ProjectName"
                      autoComplete="off"
                    />
                  </div>
                  {this.state.SearchRequest.ProjectName ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.projnameToolTipOpen}
                      target="projnameToolTip"
                      toggle={this.toggleProjNameToolTip}
                    >
                      {this.state.SearchRequest.ProjectName}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label></label>
                </div>
                <div className="d-block">
                  <div className="row ml-auto">
                    <div className="k-form-field">
                      <input
                        type="checkbox"
                        id="ch1"
                        className="k-checkbox"
                        value={this.state.SearchRequest.BillableStatus}
                        onChange={this.handleOnChangeInput}
                        checked={this.state.SearchRequest.BillableStatus}
                        name="BillableStatus"
                      />
                      <label className="k-checkbox-label" htmlFor="ch1">
                        Billable
                        </label>
                    </div>

                    <div className="k-form-field ml-2">
                      <input
                        type="checkbox"
                        id="ch2"
                        className="k-checkbox"
                        value={this.state.SearchRequest.NonBillableStatus}
                        onChange={this.handleOnChangeInput}
                        checked={this.state.SearchRequest.NonBillableStatus}
                        name="NonBillableStatus"
                      />
                      <label className="k-checkbox-label" htmlFor="ch2">
                        Non-Billable
                        </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label>Status:</label>
                </div>
                <div className="d-block">
                  <div className="">
                    <MultiSelect
                      data={this.state.Statuses}
                      textField="StatusName"
                      dataItemKey="id"
                      value={this.state.ProjectStatuses}
                      onChange={this.handleOnChangeDropDown}
                      name="ProjectStatuses"
                      placeholder={this.comboBoxPlaceHolder}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label>OP ID:</label>
                </div>
                <div className="d-block">
                  <div className="" id="opidtooltip">
                    <ComboBox
                        data={this.state.opids}
                        textField="OPID"
                        dataItemKey="id"
                        value={this.state.opidvalue}
                        onChange={this.handleOnChangeDropDown}
                        name="opidvalue"
                        placeholder={this.comboBoxPlaceHolder}
                        filterable={true}
                        onFilterChange={this.filterChangeCombo}
                    />
                  </div>
                  {this.state.opidvalue ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.opidToolTipOpen}
                      target="opidtooltip"
                      toggle={this.toggleOpidToolTip}
                    >
                      {this.state.opidvalue.OPID}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-2">
                <div className="d-block">
                  <label>Start Date On/After:</label>
                </div>
                <div className="d-block">
                  <div className="title-remove">
                    <DatePicker
                      width="100%"
                      value={this.state.StartDate}
                      onChange={this.handleOnChangeDates}
                      name="StartDate"
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label>Start Date Before:</label>
                </div>
                <div className="d-block">
                  <div className="title-remove">
                    <DatePicker
                      width="100%"
                      value={this.state.StartDateBefore}
                      onChange={this.handleOnChangeDates}
                      name="StartDateBefore"
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>



              <div className="col-md-2">
                <div className="d-block">
                  <label>Customer:</label>
                </div>
                <div className="d-block">
                  <div className="" id="customerToolTip">
                    <ComboBox
                      data={this.state.Customers}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.Customer}
                      onChange={this.handleOnChangeDropDown}
                      name="Customer"
                      placeholder={this.comboBoxPlaceHolder}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                    />
                  </div>
                  {this.state.Customer ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.cusToolTipOpen}
                      target="customerToolTip"
                      toggle={this.toggleCusTooltip}
                    >
                      {this.state.Customer.Name}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label>Program:</label>
                </div>
                <div className="d-block">
                  <div className="" id="prognameToolTip">
                    <ComboBox
                      data={this.state.Programs}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.Program}
                      onChange={this.handleOnChangeDropDown}
                      name="Program"
                      placeholder={this.comboBoxPlaceHolder}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                    />
                  </div>
                  {this.state.Program ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.programToolTipOpen}
                      target="prognameToolTip"
                      toggle={this.toggleProgToolTip}
                    >
                      {this.state.Program.Name}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label>Project ID:</label>
                </div>
                <div className="d-block">
                  <div className="" id="projectidtooltip">
                    <ComboBox
                      data={this.state.UniqueIds}
                      textField="UniqueId"
                      dataItemKey="id"
                      value={this.state.UniqueId}
                      onChange={this.handleOnChangeDropDown}
                      name="UniqueId"
                      style={{ width: '100%' }}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                      placeholder={this.comboBoxPlaceHolder}
                    />
                  </div>
                  {this.state.UniqueId ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.projidToolTipOpen}
                      target="projectidtooltip"
                      toggle={this.toggleProjIdToolTip}
                    >
                      {this.state.UniqueId.UniqueId}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="row">
                <div className="col-md-5">
                  <label>OP ID:</label>
                </div>
                <div className="col-md-7">
                  <Input
                    value={this.state.SearchRequest.OPID}
                    onChange={this.handleOnChangeInput}
                    name="OPID"
                    maxLength="20"
                  />
                </div>
              </div>
            </div> */}




            <div className="row">
              <div className="col-md-2">
                <div className="d-block">
                  <label>Department:</label>
                </div>
                <div className="d-block">
                  <div className="" id="practicetooltip">
                    <MultiSelect
                      data={this.state.Divisions}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.Division}
                      onChange={this.handleOnChangeDropDown}
                      name="Division"
                      placeholder={this.comboBoxPlaceHolder}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                    />
                  </div>
                  {this.state.Division ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.practiceToolTipOpen}
                      target="practicetooltip"
                      toggle={this.togglePracticeToolTip}
                    >
                      {this.state.Division.Name}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label>Engagement Type:</label>
                </div>
                <div className="d-block">
                  <div className="" id="engagementToolTip">
                    <MultiSelect
                      data={this.state.ProjectTypes}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.ProjectType}
                      onChange={this.handleOnChangeDropDown}
                      name="ProjectType"
                      placeholder={this.comboBoxPlaceHolder}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                    />
                  </div>
                  {this.state.ProjectType ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.engagementToolTipOpen}
                      target="engagementToolTip"
                      toggle={this.toggleEngagementToolTip}
                    >
                      {this.state.ProjectType.Name}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label>Billing Method:</label>
                </div>
                <div className="d-block">
                  <div className="" id="billMethodToolTip">
                    <MultiSelect
                      data={this.state.PaymentMethods}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.PaymentMethod}
                      onChange={this.handleOnChangeDropDown}
                      name="PaymentMethod"
                      placeholder={this.comboBoxPlaceHolder}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label>Billing Region:</label>
                </div>
                <div className="d-block">
                  <div className="" id="billingregiontooltip">
                    <MultiSelect
                      data={this.state.Regions}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.Region}
                      onChange={this.handleOnChangeDropDown}
                      name="Region"
                      placeholder={this.comboBoxPlaceHolder}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                    />
                  </div>
                  {this.state.Region ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.billRegionToolTipOpen}
                      target="billingregiontooltip"
                      toggle={this.toggleBillRegionToolTip}
                    >
                      {this.state.Region.Name}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col-md-2">
                <div className="d-block">
                  <label>Project Manager:</label>
                </div>
                <div className="d-block">
                  <div className="" id="billingregiontooltip">
                    <ComboBox
                      data={this.state.resourceItems}
                      textField="Name"
                      dataItemKey="id"
                      name="ProjectManager"
                      value={this.state.resourceValue}
                      onChange={this.handleResourceChange}
                      placeholder={this.comboBoxPlaceHolder}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label>CMS Status:</label>
                </div>
                <div className="d-block">
                  <div className="" id="engagementToolTip">
                    <MultiSelect
                      data={this.state.CMSStatus}
                      textField="name"
                      dataItemKey="value"
                      value={this.state.SelectedCMSStatus}
                      onChange={this.handleOnChangeCMSStatus}
                      name="SelectedCMSStatus"
                      placeholder={this.comboBoxPlaceHolder}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label>CR ID:</label>
                </div>
                <div className="d-block">
                  <div className="" id="billMethodToolTip">
                    <ComboBox
                      data={this.state.UniqueCRIds}
                      textField="UniqueId"
                      dataItemKey="id"
                      value={this.state.UniqueCRId}
                      onChange={this.handleOnChangeDropDown}
                      name="UniqueCRId"
                      style={{ width: '100%' }}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                      placeholder={this.comboBoxPlaceHolder}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-1"></div>

              <div className="col-md-2">
                <div className="d-block">
                  <label>CMS Type:</label>
                </div>
                <div className="d-block">
                  <div className="" id="billingregiontooltip">
                    <MultiSelect
                      data={this.state.cmsTypes}
                      textField="name"
                      dataItemKey="value"
                      value={this.state.selectedCmsType}
                      placeholder={this.comboBoxPlaceHolder}
                      onChange={this.handleCmsTypeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-2">
                <div className="d-block">
                  <label>Active Project Manager:</label>
                </div>
                <div className="d-block">
                  <div className="" id="billingregiontooltip">
                    <ComboBox
                      data={this.state.resourceItems}
                      textField="Name"
                      dataItemKey="id"
                      name="ActiveProjectManager"
                      value={this.state.activeProjectManager}
                      onChange={this.handleActivePMChange}
                      placeholder={this.comboBoxPlaceHolder}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                    />
                  </div>
                </div>
              </div>
              </div>
              <div className="row">
              <div className="col-md-3">
                <div className="d-block">
                  <div className="k-form-field">
                    <input
                      type="checkbox"
                      id="myprojects"
                      className="k-checkbox"
                      value={this.state.SearchRequest.MyProjects}
                      onChange={this.handleOnChangeInput}
                      checked={this.state.SearchRequest.MyProjects}
                      name="MyProjects"
                    />
                    <label className="k-checkbox-label" htmlFor="myprojects">
                      My Projects
                      </label>
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="d-block">
                  <div className="k-form-field">
                    <input
                        type="checkbox"
                        id="factoryprojects"
                        className="k-checkbox"
                        value={this.state.SearchRequest.FactoryProjects}
                        onChange={this.handleOnChangeInput}
                        checked={this.state.SearchRequest.FactoryProjects}
                        name="FactoryProjects"
                    />
                    <label className="k-checkbox-label" htmlFor="factoryprojects">
                      Factory Project Only
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 btn-align-right">
                <Button primary={true} type="submit" onClick={this.submitSearch}>
                  Search
                  </Button>
                <Button type="button" onClick={this.cancelSearch}>
                  Clear
                  </Button>
              </div>
            </div>
          </form>

          <div className="main-seperator" />




          <div className="main-heading">Search Results</div>

          <div className="row">
            <div className="col-md-12">
              <Grid
                data={this.state.Projects.rows}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.Projects.count}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  }, () => this.submitSearch(null));
                }}
                className="search-result-grid"
              >
                <Column field="UniqueId" title="Project ID" width="200px" />
                <Column field="ProjectName" title="Project Name" width="130px" />
                <Column field="Customer.Name" title="Customer" width="200px" />
                <Column field="Region.Name" title="Region" width="180px" />
                <Column field="updatedAt" title="Last Modified Date(Project-CMS)" width="180px" cell={props => <UpdatedDateCell {...props} />} />
                <Column
                  field=""
                  title="Project"
                  width="180px"
                  cell={props => (
                    <ProjectCell {...props} OnActivateResourceRequest={this.OnActivate} onDeleteProject={this.deleteProjectById} handleChangeStatusPopUp={this.handleChangeStatusPopUp} />
                  )}
                />
                <Column
                  field=""
                  title="CM Sheet"
                  width="300px"
                  cell={props => (
                    <CMSheetCell {...props} handleWithdrawCMS={this.handleWithdrawCMS} onDeleteCMSheet={this.onClickDeleteCMSheet} allResources={this.state.allResources}
                      onCreateCMSforCR={this.handleCreateCMSforCR} onCreateCMS={this.onCreateCMS} onCreateCMSforCRPool = {this.handleCreateCMSforCRPool} onViewCMS={this.handleOnViewCMS} onViewCMSPool={this.handleOnViewCMSPool} />
                  )}
                />
                <Column
                  field=""
                  title="CR Details"
                  width="200px"
                  cell={props => <CRDetailsCell OnActivateResourceRequest={this.OnActivate} onEditCR={this.handleEditCRbtnClick} onDeleteCR={this.handleDeleteCR} handleStatusPopUpCR={this.handleStatusPopUpCR}
                    //onCreateCMSforCR={this.handleCreateCMSforCR}
                    {...props} />}
                />

                <Column
                  field=""
                  title="Resource Request"
                  width="180px"
                  cell={props => <ResourceRequestCell getCRSOfPro={this.getCRSOfPro}
                    {...props} />}
                />
                 <Column field="" 
                title="Task Management" 
                width="180px"
                cell={props => <TaskManagementCell
                  {...props} />}
                />

              </Grid>
            </div>
          </div>
        </div>

        {/* CHANGE STATUS POPUP */}
        {this.state.changeStatusDialog && <Dialog title={"Change Status - " + (this.state.projectOrCr && this.state.projectOrCr.ProjectName? this.state.projectOrCr.ProjectName + " - " + this.state.projectOrCr.UniqueId  : (this.state.projectOrCr ? this.state.projectOrCr.CRName + " - " + this.state.projectOrCr.UniqueId  : ""))} onClose={this.toggleChangeStatusDialog} width={700}>
          <div className="row">           
            <div className="col-md-4">
              <div className="d-block">
                <label>Status:</label>
              </div>
              <div className="d-block">
                <div className="" >
                  <ComboBox
                    name="selectedChangeToStatus"
                    textField="PermissionDescription"
                    dataItemKey="PermissionName"
                    value={this.state.selectedChangeToStatus}
                    data={this.state.statusValueLst}
                    placeholder={this.CROrProjectCurrentStatus}
                    disabled={this.state.invalidatePermission || this.state.isPendingStatus || this.state.isApprovalStatusPending}
                    onChange={this.handleOnChangeStatus}
                    filterable={true}
                    onFilterChange={this.filterStatusOnChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8 my-auto">
              <div className="d-block">
                <label></label>
              </div>

              <div className="d-block">
                {this.state.showCloseSubCategory && (
                  <div className="d-block">
                    <input
                      type="radio"
                      name="changestatus"
                      value="discontinue"
                      className="k-radio"
                      id="discontinue"
                      disabled="true"
                      onChange={this.handleStatusChangeRbtn}
                    />
                    <label className="k-radio-label mr-2" htmlFor="discontinue">
                      Discontinue
                    </label>

                    <input
                      type="radio"
                      name="changestatus"
                      value="wthtbid"
                      className="k-radio"
                      id="wthtbid"
                      disabled="true"
                      onChange={this.handleStatusChangeRbtn}
                    />
                    <label className="k-radio-label mr-2" htmlFor="wthtbid">
                      Without Bidding
                    </label>

                    <input
                      type="radio"
                      name="changestatus"
                      value="oppolost"
                      className="k-radio"
                      id="oppolost"
                      disabled="true"
                      onChange={this.handleStatusChangeRbtn}
                    />
                    <label className="k-radio-label" htmlFor="oppolost">
                      Opportunity Lost
                    </label>

                    <div>
                      {this.state.isErrorMessageVisible === true ? (
                        <span className={this.validateProperty(this.state.projectname)}>
                          Please select a sub-category
                        </span>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>

          <div className="row col-md-auto">
            {this.state.isApprovalStatusPending === true ? (
              <span className={this.validateProperty(this.state.projectname)}>
                {this.state.reqStatus + ' workflow is pending with ' + this.state.approversName}
              </span>
            ) : null}
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="d-block">
                <label>Start Date:</label>
              </div>
              <div className="d-block">
                <div className="" >
                  {((((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) && (this.state.endDateAugmentation !== null)) || (((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order")) && (this.state.endDateBlanketWork !== null))) ?
                    (
                      <DatePicker
                        width="100"
                        name="startdateStatus"
                        onChange={this.handleStartdateChange}
                        value={reStructureDate(this.state.startdateStatus)}
                        min={new Date()}
                        disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null || this.state.projectActivated || (this.state.currentStatus[0].Code == 'PROJECT_ON_HOLD' && this.state.selectedChangeToStatus.PermissionDescription === 'Active')}
                        format="MM/dd/yyyy"
                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                        required={(((this.state.projtypeValue && this.state.billingdiviValue) && this.state.projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order"))) ? true : false}
                      />
                    ) : (
                      <DatePicker
                        width="100"
                        name="startdateStatus"
                        onChange={this.handleStartdateChange}
                        value={this.state.startdateStatus}
                        min={new Date()}
                        disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null || this.state.projectActivated || (this.state.currentStatus[0].Code == 'PROJECT_ON_HOLD' && this.state.selectedChangeToStatus.PermissionDescription === 'Active')}
                        format="MM/dd/yyyy"
                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                        required={(((this.state.projtypeValue && this.state.billingdiviValue) && this.state.projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order"))) ? true : false}
                      />
                    )}
                </div>

                <div>
                  {(this.state.showStartDateValidationError) ? 
                    ( <span className={this.validateProperty(this.state.startdateStatus)}>
                        Please enter a start date
                      </span>
                    ) : null}
                </div>

                <div>
                  {(this.state.showStartDateErrorMessage) ? 
                    ( <span className={this.validateProperty()}>
                        Start date cannot be a past date
                      </span>
                    ) : null}
                </div>

              </div>
              
              <div className="d-block">
              <div className="d-block mandatory">
                <label>Billing Entity:</label>
              </div>
              <div className="d-block">
                <div className="" >
                <ComboBox
                  data={this.state.billingEntityType}
                  textField="Name"
                  dataItemKey="id"
                  placeholder="Please Select"
                  value={this.state.billingEntityValue}
                  onChange={this.handleBillingEntityChange}
                  disabled={this.state.disablePONumber || this.state.selectedChangeToStatus == null}
                  filterable={true}
                  onFilterChange={this.filterBillingEntities}
                />
                </div>
                <div>
                  {this.state.showBillingEntityErrorMessage === true ? (
                      <span className={this.validateProperty(this.state.billingEntityValue)}>
                        Please select a Billing Entity
                      </span>
                    ) : null}
                </div>
              </div>
            </div>
              
            </div>

            <div className="col-md-8">
              <div className="d-block">
                <label>Document Upload:</label>
              </div>
              <div className="d-block">
                <div className="" >

                  <div className="d-flex flex-col justify-content-between">
                    <ComboBox
                      name="documentType"
                      textField="DocType"
                      dataItemKey="id"
                      value={this.state.documentType}
                      data={this.state.documentTypesForDropdown}
                      placeholder={'Please Select'}
                      disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null}
                      onChange={this.handleOnChangeDocType}
                      filterable={true}
                      onFilterChange={this.filterDocTypeOnChange}
                      itemRender={this.handleHighlightMandatoryDocs}

                    />

                    {/*file upload button*/}
                    <div className="d-flex flex-col">
                      <div className="rounded-link ml-3 align-self-center">
                        <span className="k-icon k-i-upload"/>
                      </div>
                      <input 
                        type="file" 
                        name="file" 
                        title="Upload File"
                        className="custom-statusFile-input"
                        disabled={this.state.invalidatePermission || this.state.documentType == null}
                        onChange={this.handleFileUpload}
                        value={this.state.uploadedFileId}
                        multiple={true}
                      />
                    </div>

                    <div id="overlay" style={fileUploadDivStyle}></div>
                  </div>
                  
                  <div>
                    {this.state.DocUploadErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.projectname)}>
                        Please upload mandatory document(s)
                      </span>
                    ) : null}
                  </div>

                  <div>
                    {this.state.fileUploadMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.projectname)}>
                        Only allow (doc, docx, pdf, xls, xlsx, ppt, pptx, jpg, jpeg, png, msg, htm) files
                      </span>
                    ) : null}
                  </div>

                  <pre/>

                  {/*uploaded document section*/}
                  <div className="row">
                    <div className="col-md-12">
                      <Grid 
                      data={this.state.fileUploadDetails}
                      resizable={true}
                      >
                        <Column field="DocType" title="Document Type" width="120px" />
                        <Column
                          field=""
                          title="Document Name"
                          cell={props => (<StatusFileUploadCell ApiUrl={apiUrl} {...props} />)}
                        />
                        <Column 
                          field="" 
                          title="Action"
                          cell={props => (<DeleteCell {...props} OnDelete={this.OnDelete}/>)} 
                          width="60px"
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {this.state.isPONumberRequired && (
              <div className="col-md-4">
                <div className="d-block mandatory">
                  <label>PO Number:</label>
                </div>
                <div className="d-block">
                  <div className="" id="poNumberToolTip">
                    <Input
                      autoComplete="off"
                      type="text"
                      name="poNumber"
                      value={this.state.poNumber}
                      onChange={this.handlePONumberChange}
                      disabled={this.state.disablePONumber || this.state.selectedChangeToStatus == null}
                    />
                    {this.state.showPONumberErrorMessage === true ? (
                      <span className={this.validateProperty(this.state.poNumber)}>
                        Please provide a PO number
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
            <div className={this.state.isPONumberRequired ? "col-md-8" : "col-md-12"}>
              <div className="d-block">
                <label>Comment:</label>
              </div>
              <div className="d-block">
                <div className="" >
                  <ReactQuill
                    name="statusChangeComment"
                    onChange={this.onChangeStsChgComment}
                    value={this.state.stsChangeComment}
                    maxLength="100"
                    readOnly={this.state.invalidatePermission || this.state.selectedChangeToStatus === null}
                  />
                </div>
                <div>
                  {(this.state.selectedChangeToStatus != null && (ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 14 || ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 13)) ? 
                    ( <span className={this.validateCommentProperty(this.state.stsChangeComment)}>
                        Please enter a comment
                      </span>
                    ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Grid
                style={{ maxHeight: '125px' }}
                data={orderBy(this.state.statusChangeGridDetails, this.state.sort)}
                resizable
                scrollable
              >
                <Column field="Status" title="Status"/>                
                <Column field="Approver" title="Approver" width="140px" />
                <Column field="" title="Approval Status"
                        cell={props => (
                          <ApprovalStatusCell {...props} />
                        )}
                />
                <Column field="DateTime" title="Date and Time"/>
                <Column field="" title="Comment" cell={props => (<StatusChangeCommentCell {...props}/>)} />
                <Column field="" title="Document Uploaded" cell={props => (<ActivityLogFilesCell ApiUrl={apiUrl} name="DocType" {...props} />)}/>
              </Grid>
            </div>
          </div>
          <DialogActionsBar>
            <button className="k-button" onClick={this.toggleChangeStatusDialog}>Cancel</button>
            <button 
              id="statusPopupSaveBtn" 
              className="k-button modal-primary" 
              disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null || 
                ((ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 14 || ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 13) && (this.state.stsChangeComment == null || this.state.stsChangeComment == "" || this.state.stsChangeComment == "<p><br></p>"))} 
              onClick={this.onSaveStatusChange}
              >
              Save
            </button>
          </DialogActionsBar>
        </Dialog>}

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="300px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                OK
                  </button>
            </DialogActionsBar>
          </Dialog>
        )}
        

        {this.state.showCRSelectDialog === true && (
          <Dialog
            title={'Select the relevant CR to ' + (this.state.ChangeRequestAction === 'CreateCMSforCR' ? 'create' : (this.state.ChangeRequestAction === 'HandleCrPopUp' ? 'change status' : this.state.ChangeRequestAction ))}
            onClose={this.toggleWorkflowSelectDialog} width="400px">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <form className="k-form modified">
                      <div className="k-form-field">
                        {this.state.CRsOfAProject.map((option, i) => {
                          return (
                            <div className="ml-3">
                              <input
                                type="radio"
                                id={'' + option.id}
                                name={'' + option.id}
                                value={option.id}
                                checked={this.state.selectedCRId === option.id}
                                className="k-radio"
                                key={option.id}
                                onChange={ this.setSelectedCRId.bind(this, option.id, option.IsPoolProject, option) }
                              />
                              <label
                                key={option.id}
                                htmlFor={'' + option.id}
                                className="k-radio-label"
                              >

                              { option.IsPoolProject === true? option.FinancialYear : option.CRName}
                              <td className="badge badge-warning ml-2 my-auto px-2 py-1"><span>{option.Status.StatusName}</span> </td>

                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleWorkflowSelectDialog}>
                No
                  </button>
              <button
                className="k-button modal-primary"
                onClick={this.editCR}
                disabled={!this.state.selectedCRId}
              >
                Yes
                  </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.CMSDialog === true && (
          <CMSDeleteDialog
            toggleCMSSelectDialog={this.toggleCMSSelectDialog}
            cmSheetsArray={this.state.cmSheetsArray}
            changerequestCMS={this.state.crCMSheets}
            projectCMS={this.state.projectCMSheets}
            cmSheetIdToDelete={this.state.cmSheetIdToDelete}
            setCMSIdtoDelete={this.setCMSIdtoDelete}
            deleteCMSheet={this.deleteCMSheet}
          />

        )}

        {this.state.viewCMSDialog === true && (
          <Dialog title={'Select the relevant CMS to ' +  (this.state.cmsAction === 'requestReviseCMS' ? 'Request Revision' : this.state.cmsAction)} onClose={this.toggleViewCMSSelectDialog}
            width="400px">
            <div className="row">
              <div className="col-md-12">
                <p className="mb-0">Project CMS</p>
                <div className="row">
                  <div className="col-md-12 dialog-radio">
                    <form className="k-form modified">
                      <div className="k-form-field">
                        {this.state.projectCMS.map((option, i) => {
                          return (
                            <div>
                              <input
                                type="radio"
                                id={'' + option.data.id}
                                name={'' + option.data.id}
                                value={option.data.id}
                                checked={this.state.selectedCMSId === option.data.id}
                                className="k-radio"
                                key={option.id}
                                onChange={this.setSelectedCMSId.bind(this, option.data.id, option.data, 'projectCMS')}
                              />
                              <label
                                key={option.data.id}
                                htmlFor={'' + option.data.id}
                                className="k-radio-label"
                              >
                                {option.name}
                                <td id="CMSTooltip" className="badge badge-warning ml-2 my-auto px-2 py-1">{this.dialogStatus(option.data.CMSheets[0].Status)} </td>
                                <Tooltip
                                  target="CMSTooltip"
                                  isOpen={this.state.cmsToolTipOpen}
                                  toggle={this.toggleCMSToolTip}
                                  placement="right"
                                  className="cmstooltipopen">
                                  Project: {option.name} | Version: {option.data.CMSheets[0].Version} | Status: {this.dialogStatus(option.data.CMSheets[0].Status)}
                                </Tooltip>
                              </label>

                            </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-md-12">
                {this.state.projectCMS.length > 0 ? '' : (<label> NO Project CMS found</label>)}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <p className="mb-0">Change Request CMS</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 dialog-radio">
                    <form className="k-form modified">
                      <div className="k-form-field">
                        {this.state.changeRequestCMS.map((option, i) => {
                          return (
                            <div>
                              <input
                                type="radio"
                                id={'' + option.data.id}
                                name={'' + option.data.id}
                                value={option.data.dataid}
                                checked={this.state.selectedCMSId === option.data.id}
                                className="k-radio"
                                key={option.id}
                                onChange={this.setSelectedCMSId.bind(this, option.data.id, option.data, 'crCMS')}
                              />
                              <label
                                key={option.data.dataid}
                                htmlFor={'' + option.data.id}
                                className="k-radio-label"
                              >

                                {option.data.IsPoolProject === true ? option.data.FinancialYear : option.name}

                                <td id="CMSTooltipCR" className="badge badge-warning ml-2 my-auto px-2 py-1">{this.dialogStatus(option.data.CMSheets[0].Status)}</td>
                                <Tooltip
                                  target="CMSTooltipCR"
                                  isOpen={this.state.cmsToolTipCROpen}
                                  toggle={this.toggleCMSCRToolTip}
                                  placement="right"
                                  className="cmstooltipopen">
                                  Project: {option.name} | Version: {option.data.CMSheets[0].Version} | Status: {this.dialogStatus(option.data.CMSheets[0].Status)}
                                </Tooltip>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {this.state.changeRequestCMS.length > 0 ? '' : (<label>No Change Request CMS Found</label>)}
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleViewCMSSelectDialog}>
                No
                  </button>
              <button
                className="k-button modal-primary"
                onClick={this.viewEditCMS}
                disabled={!this.state.selectedCMSId}
              >
                Yes
                  </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="300px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showCMSWithdrawDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleCMSWithdrawDialog} width="400px">
              <p style={{ margin: '10px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
              <p><label className="mandatory">Reason:</label></p>
              <textarea className="k-textarea" rows="5" cols="50" placeholder="Type Comment"
                        onChange={this.handleWithdrawReasonInputChange}
                        value={this.state.withdrawReason}
              ></textarea>
              {this.state.isErrorMessageVisible === true ? (
                <span className={this.validateProperty(this.state.withdrawReason)}>
                  Please provide a reason.
                </span>
              ) : null}
              <DialogActionsBar>
                  <button className="k-button" onClick={this.toggleCMSWithdrawDialog}>
                      No
                  </button>
                  <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                      Yes
                  </button>
              </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showRequestReviseCMSDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleRequestReviseCMSDialog} width="400px">
              <p style={{ margin: '10px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
              <p><label className="mandatory">Reason for Revision Request:</label></p>
              <textarea className="k-textarea" rows="5" cols="50" placeholder="Type Comment"
                        onChange={this.handleReviseCMSReasonInputChange}
                        value={this.state.requestReviseCMSReason}
              ></textarea>
              {this.state.isErrorMessageVisible === true ? (
                <span className={this.validateProperty(this.state.requestReviseCMSReason)}>
                  Please provide a reason.
                </span>
              ) : null}
              <DialogActionsBar>
                  <button className="k-button" onClick={this.toggleRequestReviseCMSDialog}>
                      No
                  </button>
                  <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                      Yes
                  </button>
              </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} onClose={this.reDirectToSearch} width="400px">
            {this.state.showSuccessImg === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button modal-primary"
                      onClick={this.toggleMessageDialog}
                      onClick={
                        (this.state.dialogMessage === 'Status changed successfully' || this.state.dialogMessage === 'Status Change submitted for Approval') ? this.reDirectToSearch : this.toggleMessageDialog
                      }
              >
                OK
                  </button>
            </DialogActionsBar>
          </Dialog>
        )}


        {this.state.showCRSelectDialogOne === true && (

          <Dialog title="Select the relevant CR to resource request" onClose={this.toggleCRSelectDialog} width="400px">
            <div className="row">
              <div className="col-md-12 ml-4">
                <div className="row">
                </div>
                <div className="row">
                  <div className="k-form-field">
                    {this.state.changeRequest.map((option, i) => {
                      return (
                        <div>
                          <input
                            type="radio"
                            id={option.id}
                            name={'' + option.id}
                            value={option.id}
                            checked={this.state.selectedCRId === option.id}
                            className="k-radio"
                            key={'op' + option.id}
                            onChange={this.setSelectedCRIdResourceRequest.bind(this, option.id, option)}
                          />
                          <label
                            key={'opq' + option.id}
                            htmlFor={option.id}
                            className="k-radio-label case-insensitive"
                          >
                            {option.CRName}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleCRSelectDialog}>
                No
                  </button>
              <button
                className="k-button modal-primary"
                onClick={this.onCRSelectResourceRequest}
              >
                Yes
                  </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showErrorMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleErrorMessageDialog} width="400px">
            {this.state.showErrorImg === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleErrorMessageDialog}>
                OK
                  </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {/* Factory Project Selection */}
        {this.state.linkToPoolDialog === true && (
          <Dialog title="Factory Project Selection" onClose={this.toggleLinkProjToPoolDialog} width="500px">
            <p style={{ margin: '25px', textAlign: 'center' }}>This project is compatible to be added to a factory
              type customer project. Do you want to create a factory type customer project? </p>
            <div className="d-block">
              <label className="mandatory">Select a factory:</label>
            </div>
            <div className="d-block">
              <div className="">
                <ComboBox
                  placeholder="Please select"
                  name="selectedPoolProject"
                  data={this.state.poolProjectsAll}
                  textField="ProjectName"
                  dataItemKey="id"
                  value={this.state.selectedPoolProject}
                  onChange={this.handleComboChangePoolProject}
                  required={true}
                  filterable={true}
                  onFilterChange={this.filterChangeComboPool}
                />
              </div>
            </div>
            {this.state.isACR === true && (
              <DialogActionsBar>
                <button className="k-button" onClick={this.onCreateFactoryprojectCR}>
                  Create as a factory type project
                </button>
                <button className="k-button modal-primary" onClick={this.onCreateNormalProjectCR}>
                  Create as a normal project
                </button>
              </DialogActionsBar>
            )}
            {this.state.isAProj === true && (
              <DialogActionsBar>
                <button className="k-button" onClick={this.onCreateFactoryproject}>
                  Create as a factory type project
                </button>
                <button className="k-button modal-primary" onClick={this.onCreateNormalProject}>
                  Create as a normal project
                </button>
              </DialogActionsBar>
            )}
          </Dialog>
        )}


        {this.state.showWorkflowSelectDialog === true && (
            <Dialog title="Select Workflow" onClose={this.toggleWorkflowSelectStatusDialog} width="300px">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <p className="subtitle-workflow" style={{ textAlign: 'center' }}>Please select the applicable workflow to send for approval.</p>
                  </div>
                  <div className="row">
                    <form className="k-form modified">
                      <div className="k-form-field">
                        {this.state.matchingWorkflowList.map((option, i) => {
                          return (
                              <div className="field-mod">
                                <input
                                    type="radio"
                                    id={'' + option.id}
                                    name={'' + option.id}
                                    value={option.id}
                                    checked={this.state.selectedWorkFlowId === option.id}
                                    className="k-radio"
                                    key={option.id}
                                    onChange={this.setSelectedWorkflowId.bind(this, option.id)}
                                />
                                <label
                                    key={option.id}
                                    htmlFor={'' + option.id}
                                    className="k-radio-label"
                                    title={"Approvers: " + option.approvers}
                                >
                                  {option.name}
                                </label>
                              </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                </div>
              </div>

                <DialogActionsBar>
                  <button className="k-button" onClick={this.toggleWorkflowSelectStatusDialog}>
                    No
                  </button>
                  <button
                      className="k-button modal-primary"
                      onClick={this.setWorkflowToStatus.bind(this, this.state.cmsId,this.state.statusChangeObj)}
                      disabled={!this.state.selectedWorkFlowId}
                  >
                    Yes
                  </button>
                </DialogActionsBar>

            </Dialog>
        )}

          {this.state.showReviseCMSWorkflowSelectDialog === true && (
            <Dialog title="Select Workflow" onClose={this.toggleReviseCMSWorkflowSelectDialog} width="300px">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <p className="subtitle-workflow" style={{ textAlign: 'center' }}>Please select a workflow.</p>
                  </div>
                  <div className="row">
                    <form className="k-form modified">
                      <div className="k-form-field">
                        {this.state.matchingWorkflowList.map((option, i) => {
                          return (
                              <div className="field-mod">
                                <input
                                    type="radio"
                                    id={'' + option.id}
                                    name={'' + option.id}
                                    value={option.id}
                                    checked={this.state.selectedWorkFlowId === option.id}
                                    className="k-radio"
                                    key={option.id}
                                    onChange={this.setSelectedWorkflowId.bind(this, option.id)}
                                />
                                <label
                                    key={option.id}
                                    htmlFor={'' + option.id}
                                    className="k-radio-label"
                                >
                                  {option.name}
                                </label>
                              </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <DialogActionsBar>
                <button className="k-button" onClick={this.toggleReviseCMSWorkflowSelectDialog}>
                  No
                </button>
                <button
                    className="k-button modal-primary"
                    onClick={this.setWorkflowReviseCMS.bind(this)}
                    disabled={!this.state.selectedWorkFlowId}
                >
                  Yes
                </button>
              </DialogActionsBar>

            </Dialog>
          )}

        <Loader loading={this.state.loading} />
      </div>
    );
  }
};


export default ProjectSearch;
